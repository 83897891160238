import React, { useState, useEffect } from "react";
import "./index.css";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import OwnerGridViewComponent from "../../../../components/Accounts/OwnerAccounts/OwnerGridViewComponent";

const OwnerViewAllCard = ({
  data,
  handleRefresh,
  isopen,
  page,
  refreshAllData,
  refreshType,
  setRefreshtype,
  setId,
  total_balance,
  setPrev_Total_balance_state,
  setFailedRefreshId,
  failedRefreshId,
  successRefreshId,
  setSuccessRefreshId,
  currency,
  splitView,
  postiveBalanceData,
  negativeBalanceData,
  refreshAll,
  typepop,
  setIsNeft,
  setIsImps,
  setIsRtgs,
  txnPayLoad,
  setTxnPayLoad,
  handleButtonClick,
  BenEnableNextButton,
  setOpenOwner,
  onAccountSelect,
  flow,
  handleViewStatement,
  setStatementId,
  setAccData,
  multiSelect,
  isAssignTagsClicked,
  owneraccountnumberstate,
  setowneraccountnumberstate,
  favouritesFilter,
  popType,
}) => {
  const { ownerAccBalanceLoading, ownerAccBalanceError } = useSelector(
    (state) => state.ownerAllVerification
  );

  const [accId, setAccId] = useState("");
  const [prevId, setPrevId] = useState("");
  const [prevBalance, setPrevBalance] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const owneralldata = data;

  const handleClick = (e, id) => {
    if (!isopen) {
      e.stopPropagation();
      handleRefresh(id);
    }
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 540);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 540);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <>
      {splitView ? (
        <div className="w-[1100px] mx-auto flex flex-col px-[40px]">
          <div className="split_view_header">
            <div className="w-[50%]">
              <p>Positive Balance</p>
            </div>
            <div className="w-[50%] pl-[32px]">
              <p>Negative Balance</p>
            </div>
          </div>
          <div className="flex w-full mb-[50px]">
            <div className="w-[50%] flex flex-wrap animation_test">
              {postiveBalanceData.length > 0 ? (
                <div className="flex flex-wrap gap-[10px] pb-[300px]">
                  {postiveBalanceData?.map((account, index) => (
                    <div
                      key={account.id}
                      style={{
                        position: "relative",
                        width: "238px",
                        height: "103px",
                      }}
                    >
                      <OwnerGridViewComponent
                        dispatch={dispatch}
                        navigate={navigate}
                        index={index}
                        account={account}
                        isopen={isopen}
                        page={page}
                        refreshAllData={refreshAllData}
                        refreshType={refreshType}
                        setRefreshtype={setRefreshtype}
                        setId={setId}
                        total_balance={total_balance}
                        setPrev_Total_balance_state={
                          setPrev_Total_balance_state
                        }
                        setFailedRefreshId={setFailedRefreshId}
                        failedRefreshId={failedRefreshId}
                        successRefreshId={successRefreshId}
                        setSuccessRefreshId={setSuccessRefreshId}
                        currency={currency}
                        refreshAll={refreshAll}
                        typepop={typepop}
                        setIsNeft={setIsNeft}
                        setIsImps={setIsImps}
                        setIsRtgs={setIsRtgs}
                        txnPayLoad={txnPayLoad}
                        setTxnPayLoad={setTxnPayLoad}
                        handleButtonClick={handleButtonClick}
                        BenEnableNextButton={BenEnableNextButton}
                        setOpenOwner={setOpenOwner}
                        flow={flow}
                        handleViewStatement={handleViewStatement}
                        handleClick={handleClick}
                        ownerAccBalanceLoading={ownerAccBalanceLoading}
                        ownerAccBalanceError={ownerAccBalanceError}
                        accId={accId}
                        setAccId={setAccId}
                        setStatementId={setStatementId}
                        setAccData={setAccData}
                        prevId={prevId}
                        setPrevId={setPrevId}
                        prevBalance={prevBalance}
                        setPrevBalance={setPrevBalance}
                        multiSelect={multiSelect}
                        owneraccountnumberstate={owneraccountnumberstate}
                        setowneraccountnumberstate={setowneraccountnumberstate}
                        favouritesFilter={favouritesFilter}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <p className="noaccountfound">
                  No accounts were found with a positive balance.
                </p>
              )}
            </div>
            <div className="owner_split_div relative top-[-40px] z-[10]"></div>
            <div className="w-[50%] pl-[10px] animation_test2">
              {negativeBalanceData.length > 0 ? (
                <div className="flex flex-wrap gap-[10px] pb-[210px]">
                  {negativeBalanceData?.map((account, index) => (
                    <div
                      key={account.id}
                      style={{
                        position: "relative",
                        width: "238px",
                        height: "103px",
                      }}
                    >
                      <OwnerGridViewComponent
                        dispatch={dispatch}
                        navigate={navigate}
                        index={index}
                        account={account}
                        isopen={isopen}
                        page={page}
                        refreshAllData={refreshAllData}
                        refreshType={refreshType}
                        setRefreshtype={setRefreshtype}
                        setId={setId}
                        total_balance={total_balance}
                        setPrev_Total_balance_state={
                          setPrev_Total_balance_state
                        }
                        setFailedRefreshId={setFailedRefreshId}
                        failedRefreshId={failedRefreshId}
                        successRefreshId={successRefreshId}
                        setSuccessRefreshId={setSuccessRefreshId}
                        currency={currency}
                        refreshAll={refreshAll}
                        typepop={typepop}
                        setIsNeft={setIsNeft}
                        setIsImps={setIsImps}
                        setIsRtgs={setIsRtgs}
                        txnPayLoad={txnPayLoad}
                        setTxnPayLoad={setTxnPayLoad}
                        handleButtonClick={handleButtonClick}
                        BenEnableNextButton={BenEnableNextButton}
                        setOpenOwner={setOpenOwner}
                        flow={flow}
                        handleViewStatement={handleViewStatement}
                        handleClick={handleClick}
                        ownerAccBalanceLoading={ownerAccBalanceLoading}
                        ownerAccBalanceError={ownerAccBalanceError}
                        accId={accId}
                        setAccId={setAccId}
                        setStatementId={setStatementId}
                        setAccData={setAccData}
                        prevId={prevId}
                        setPrevId={setPrevId}
                        prevBalance={prevBalance}
                        setPrevBalance={setPrevBalance}
                        multiSelect={multiSelect}
                        owneraccountnumberstate={owneraccountnumberstate}
                        setowneraccountnumberstate={setowneraccountnumberstate}
                        favouritesFilter={favouritesFilter}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <p style={{ paddingLeft: "30px" }} className="noaccountfound">
                  No accounts were found with a negative balance.
                </p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className="ownerCard gap-[20px] 2.6xl:gap-[20px] 3.1xl:gap-[20px] 3.6xl:gap-[30px] 4.6xl:gap-[30px] 5.6xl:gap-[40px]"
          style={{
            width: popType === "ownerStatement" ? "95%" : "93%",
            paddingBottom: isAssignTagsClicked ? "130px" : "250px",
          }}
        >
          {owneralldata?.map((account, index) => (
            <div
              key={account.id}
              style={{
                position: "relative",
                width: isMobile ? "100%" : "238px",
                height: "103px",
              }}
            >
              <OwnerGridViewComponent
                dispatch={dispatch}
                navigate={navigate}
                index={index}
                account={account}
                isopen={isopen}
                page={page}
                refreshAllData={refreshAllData}
                refreshType={refreshType}
                setRefreshtype={setRefreshtype}
                setId={setId}
                total_balance={total_balance}
                setPrev_Total_balance_state={setPrev_Total_balance_state}
                setFailedRefreshId={setFailedRefreshId}
                failedRefreshId={failedRefreshId}
                successRefreshId={successRefreshId}
                setSuccessRefreshId={setSuccessRefreshId}
                currency={currency}
                refreshAll={refreshAll}
                typepop={typepop}
                setIsNeft={setIsNeft}
                setIsImps={setIsImps}
                setIsRtgs={setIsRtgs}
                txnPayLoad={txnPayLoad}
                setTxnPayLoad={setTxnPayLoad}
                handleButtonClick={handleButtonClick}
                BenEnableNextButton={BenEnableNextButton}
                setOpenOwner={setOpenOwner}
                flow={flow}
                handleViewStatement={handleViewStatement}
                handleClick={handleClick}
                ownerAccBalanceLoading={ownerAccBalanceLoading}
                ownerAccBalanceError={ownerAccBalanceError}
                accId={accId}
                setAccId={setAccId}
                setStatementId={setStatementId}
                setAccData={setAccData}
                prevId={prevId}
                setPrevId={setPrevId}
                prevBalance={prevBalance}
                setPrevBalance={setPrevBalance}
                multiSelect={multiSelect}
                isAssignTagsClicked={isAssignTagsClicked}
                owneraccountnumberstate={owneraccountnumberstate}
                setowneraccountnumberstate={setowneraccountnumberstate}
                favouritesFilter={favouritesFilter}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default OwnerViewAllCard;
