import React, { useState, useRef, useEffect } from "react";
import { Button, Box } from "@mui/material";

import cancel from "../../../../assets/Settings/Dashboard/cancel.png";
import NotSelect from "../../../../assets/Approve transactions/not selected.png";

import Selected from "../../../../assets/Approve transactions/selected.png";
import search_icon from "../../../../assets/Approve transactions/search-white.png";
import arrowup from "../../../../assets/OwnerAccount/arrowup.png";
import arrowdown from "../../../../assets/OwnerAccount/arrowdown1.png";
import "./index.css";

const OwnerDetailsSelectTag = (props) => {
  const [tagSearchName, setTagSearchName] = useState("");
  const [icon, setIcon] = useState(arrowup);
  const [selectedTagValue, setSelectedTagValue] = useState([]);
  const [tagSelection, setTagSelection] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [message, setMessage] = useState(false);

  const tagInputRef = useRef("");

  const handleClose = () => {
    props.onClose();
  };

  let tagselection = [];
  let selectedTagvalue = [];

  useEffect(() => {
    let data = [];
    let newData = [];
    if (props?.ownerExistingTag) {
      data = props?.ownerExistingTag?.map((res) => {
        return {
          selected: props?.existingTags.includes(res)
            ? false
            : props?.ownerviewall
            ? true
            : false,
          value: res,
          type: "ownerTags",
        };
      });
    }

    const availableTags = props?.userviewall
      ? sessionStorage?.getItem("UserAvailableTags")
      : sessionStorage?.getItem("AvailableTags");
    if (
      availableTags &&
      availableTags !== undefined &&
      availableTags !== null
    ) {
      const parsedTags = JSON.parse(availableTags);

      parsedTags?.forEach((tag) => {
        if (!props?.ownerExistingTag?.includes(tag)) {
          newData?.push({ selected: true, value: tag, type: "existingTag" });
        }
      });
    }

    props?.setNewTags([...data, ...newData]);
  }, []);

  const handleClick = () => {
    setIcon(icon === arrowup ? arrowdown : arrowup);
  };

  const handleConfirmTag = async () => {
    if (props?.ownerviewall) {
      props?.handlefilterPayload((data) => {
        return {
          ...data,
          ownerTags: props?.newTags
            .filter((tag) => tag.selected === false)
            .map((tag) => tag.value),
        };
      });
      props.onClose();
    } else {
      let flag1 = false;
      let flag2 = false;

      props.newTags.forEach((tag) => {
        if (tag.selected) {
          flag2 = true;
        } else {
          flag1 = true;
        }
      });

      try {
        if (flag1) {
          await props?.handleAddTags();
        }

        if (flag2) {
          await props?.handleRemoveTags();
        }
      } catch (error) {
        console.error("Error updating tags: ", error);
      }
    }
  };

  const handleNewTag = () => {
    const tagExists = props?.newTags.some(
      (tag) => tag.value.toLowerCase() === tagSearchName.toLowerCase()
    );

    if (tagExists) {
    } else {
      props?.setNewTags([
        ...props?.newTags,

        { selected: false, value: tagSearchName, type: "newTag" },
      ]);
    }
    setTagSearchName("");
  };

  const handleSelectandDeselect = (selectedTag) => {
    const updatedTags = props?.newTags?.reduce((acc, res) => {
      if (res.value === selectedTag && res.type === "newTag") {
        if (!res.selected) {
          return acc;
        } else {
          return [...acc, { ...res, selected: true }];
        }
      } else {
        return [...acc, res];
      }
    }, []);

    props?.setNewTags(updatedTags);
  };

  useEffect(() => {
    props?.newTags?.forEach((tag) => {
      if (tag.type === "ownerTags") {
        selectedTagvalue.push(tag.value);
        setIsDisabled(props?.ownerviewall ? true : false);
      }

      if (tag.type === "newTag" || tag.type === "ownerTags") {
        selectedTagvalue.push(tag.value);
        setIsDisabled(props?.ownerviewall ? false : true);
      }

      if (tag.type === "newTag" || tag.type === "existingTag") {
        selectedTagvalue.push(tag.value);
        setIsDisabled(false);
      }
    });
    setIsDisabled(false);
  }, [props.newTags]);

  useEffect(() => {
    props.newTags?.forEach((tag) => {
      if (tag.selected == (props?.ownerviewall ? false : true)) {
        tagselection.push(tag.value);
      }

      if (!props?.ownerviewall) {
        if (
          tagselection.length >= selectedTagvalue.length &&
          (tag.type === "ownerTags" || tag.type === "existingTag")
        ) {
          setIsDisabled(false);
        } else {
          setIsDisabled(true);
        }
      } else {
        if (tagselection.length >= 1 && tag.type === "ownerTags") {
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }
      }
    });
  }, [props.newTags]);

  const handleExistingTag = (selectedTag) => {
    const updatedTags = props?.newTags.map((tag) => {
      if (tag.value === selectedTag && tag.type !== "newTag") {
        return { ...tag, selected: !tag.selected };
      } else {
        return tag;
      }
    });

    props?.setNewTags(updatedTags);
  };

  const filteredExistingTags = props?.newTags?.filter((tag) => {
    return (
      (tag.type === "existingTag" || tag.type === "ownerTags") &&
      tag?.value?.toLowerCase().includes(tagSearchName?.toLowerCase())
    );
  });

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !props?.ownerviewall) {
      const inputValue = e.target.value;
      if (inputValue.trim()) {
        handleNewTag();
      }
    }
  };

  const InputChange = (e) => {
    const valueChange = e.target.value;
    const limitedValue = valueChange.substring(0, 30);
    const ProcessChange = limitedValue.replace(/\s+/g, "").toLowerCase();

    setTagSearchName(ProcessChange);

    const dett = props?.newTags?.filter((tag) => {
      return tag?.value?.toLowerCase().includes(ProcessChange?.toLowerCase());
    });

    if (dett.length === 0 && ProcessChange.length > 0) {
      setMessage(true);
    } else {
      setMessage(false);
    }
  };

  const renderTagMessages = () => {
    if (props?.newTags?.length === 0) {
      return (
        <p>
          {props?.ownerviewall ? (
            "No existing tags found."
          ) : (
            <>
              No existing tags found.
              <br />
              To assign a tag, Kindly enter a new tag.
            </>
          )}
        </p>
      );
    } else if (filteredExistingTags?.length === 0 && tagSearchName.length > 0) {
      return <p>No data found, for this entry.</p>;
    } else if (
      filteredExistingTags?.length === 0 &&
      tagSearchName.length === 0
    ) {
      return (
        <p>
          {props?.ownerviewall ? (
            "No existing tags found."
          ) : (
            <>
              No existing tags found.
              <br />
              To assign a tag, Kindly enter a new tag.
            </>
          )}
        </p>
      );
    }
    return null;
  };

  useEffect(() => {
    if (props?.newTags?.length === 0 && props?.newTags?.type === "newTag") {
      setMessage(false);
    } else {
      setMessage(true);
    }
  }, [props.newTags]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <>
      <div
        className="flex  w-full h-full justify-center items-center m-auto top-1/2 left-1/2 fixed z-[9999] overflow-y-auto"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <div
          className={`h-fit flex flex-col relative bg-Blue_3 ${
            isMobile
              ? "w-full h-full mt-0 rounded-none"
              : "w-[560px] h-[602px] rounded-2xl"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className=" pl-[20px] pt-[20px]">
            <div className="flex flex-col justify-between">
              <h6 className="text-Primary_grey pb-1 font-semibold text-[16px] ">
                {props?.ownerviewall ? "Select tags:" : "Assign tags:"}
              </h6>
              {props?.userviewall ? (
                <p className="text-Primary_white  text-[12px] ">
                  Select tags and click ‘Confirm’ to filter users.
                </p>
              ) : props?.ownerviewall ? (
                <p className="text-Primary_white  text-[12px] ">
                  Select tags and click ‘Confirm’ to filter owners.
                </p>
              ) : (
                ""
              )}
            </div>

            <div
              className=" tag-search mt-[20px] "
              style={{ width: isMobile ? "97%" : "" }}
            >
              <div
                className="mx-2 my-0"
                onClick={() => tagInputRef.current.focus()}
              >
                <img className="tag-down-search" src={search_icon} alt="" />
              </div>
              <div className="tag-partition-one label-left">
                <input
                  ref={tagInputRef}
                  className="input-custom-search"
                  type="text"
                  value={tagSearchName}
                  placeholder={
                    props?.ownerviewall ? "Search tags" : "Search/Enter new tag"
                  }
                  onChange={InputChange}
                  onKeyDown={handleKeyPress}
                  autoFocus
                />
              </div>
              <div
                className="tag-partition-two"
                onClick={() => tagInputRef.current.focus()}
              >
                {tagSearchName.length > 0 ? (
                  <>
                    <img
                      className="w-[14px] h-[14px] cursor-pointer m-auto"
                      alt="cancel"
                      src={cancel}
                      onClick={(e) => {
                        e.stopPropagation();
                        setTagSearchName("");
                      }}
                    />
                  </>
                ) : null}
              </div>
              {!props?.ownerviewall && (
                <>
                  <div className=" border-l-1  border-Blue_3 h-[24px] mr-[0.5px]"></div>
                  <div className=" ">
                    <Button
                      className={`${
                        tagSearchName === ""
                          ? "btn-custom-primary "
                          : "btn-custom-secondary "
                      } `}
                      disabled={tagSearchName === ""}
                      onClick={() => handleNewTag()}
                    >
                      Assign new tag
                    </Button>
                  </div>
                </>
              )}
            </div>

            <div className=" h-[475px] pb-[45px] Owner-detail mt-3   ">
              {props?.newTags?.filter((fil) => fil?.type === "newTag")?.length >
                0 && filteredExistingTags ? (
                <>
                  <div className=" tag-list h-auto flex mt-[15px]  ">
                    <div className="tag-list ">
                      <p>New assigned tags</p>
                    </div>
                    <div className="flex flex-wrap flex-row ">
                      {props?.newTags?.length > 0 &&
                        props?.newTags
                          ?.filter((fil) => fil?.type === "newTag")
                          ?.map((tag) => {
                            return (
                              <>
                                <Box
                                  className="viewall-tag-box-item relative"
                                  key={tag?.value}
                                  style={{
                                    backgroundColor:
                                      tag.selected === true
                                        ? ""
                                        : "var(--Category_selection)",
                                  }}
                                  onClick={() =>
                                    handleSelectandDeselect(tag.value)
                                  }
                                >
                                  <>
                                    <span
                                      id="id-textTag"
                                      style={{
                                        display: "inline-block",
                                        fontSize: "12px",
                                        paddingRight: "5px",
                                        color: "var(--Primary_white)",
                                      }}
                                    >
                                      {tag.value}
                                    </span>

                                    <span className="w-[14px] h-[14px] pt-[1px]">
                                      {tag.selected ? (
                                        <img
                                          className=""
                                          src={NotSelect}
                                          alt=""
                                        />
                                      ) : (
                                        <img
                                          className=""
                                          src={Selected}
                                          alt=""
                                        />
                                      )}
                                    </span>
                                  </>
                                </Box>
                              </>
                            );
                          })}
                    </div>
                  </div>
                </>
              ) : null}

              <div className="tag-list mt-[15px]  ">
                <p>Existing tags</p>
              </div>
              <div className="h-[85%] overflow-y-scroll w-full mt-[5px] pb-2">
                {props?.newTags?.filter(
                  (fil) =>
                    fil?.type === "existingTag" || fil?.type === "ownerTags"
                )?.length > 0 && filteredExistingTags?.length > 0 ? (
                  <div>
                    <div className="flex flex-wrap gap-[13px] mt-[10px] pr-[20px]">
                      {props?.newTags?.length > 0 &&
                        props?.newTags &&
                        filteredExistingTags
                          ?.filter(
                            (fil) =>
                              fil?.type === "existingTag" ||
                              fil?.type === "ownerTags"
                          )
                          ?.map((tag) => {
                            return (
                              <>
                                <Box
                                  key={tag?.value}
                                  className={
                                    "px-4 viewall-tag-box-item relative"
                                  }
                                  style={{
                                    backgroundColor:
                                      tag.selected == false
                                        ? "var(--Category_selection)"
                                        : "",
                                  }}
                                  onClick={() => handleExistingTag(tag.value)}
                                >
                                  <div>
                                    <>
                                      <span
                                        id="id-textTag"
                                        style={{
                                          display: "inline-block",
                                          fontSize: "12px",
                                          paddingRight: "5px",
                                        }}
                                      >
                                        {tag.value}
                                      </span>

                                      <span className="w-[14px] h-[14px] pt-[1px]">
                                        {tag.selected == false ? (
                                          <img
                                            className=""
                                            src={Selected}
                                            alt=""
                                          />
                                        ) : (
                                          <img
                                            className=""
                                            src={NotSelect}
                                            alt=""
                                          />
                                        )}
                                      </span>
                                    </>
                                  </div>
                                </Box>
                              </>
                            );
                          })}
                    </div>
                  </div>
                ) : (
                  <div className="existing-tag mt-3">{renderTagMessages()}</div>
                )}
              </div>
            </div>
          </div>

          {icon == arrowdown ? (
            <div className="tag-bottom-expand   overflow-y-auto mt-4   ">
              {props?.newTags?.map((tag) => {
                return (
                  <>
                    {!tag.selected && (
                      <>
                        <Box
                          className=" px-4 Assign-box-item relative"
                          key={tag?.value}
                        >
                          <div className=" flex items-center ">
                            <>
                              <span
                                className="make-first-letter-small  "
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                {tag.value}
                              </span>
                            </>
                          </div>
                        </Box>
                      </>
                    )}
                  </>
                );
              })}
            </div>
          ) : null}
          <div
            className="tag-bottom mt-5 "
            style={{
              borderRadius: isMobile ? "0" : "0 0 16px 16px",

              width: isMobile ? "100%" : "",
              height: "auto",
            }}
          >
            <div
              className="flex p-[18px]   "
              style={{
                flexDirection: isMobile ? "column" : "row",
                padding: isMobile ? "0" : "",
              }}
            >
              <div className="flex" style={{ padding: isMobile ? "8px" : "" }}>
                <div className=" bg-Blue_2 text-Primary_white flex justify-center items-center rounded-[50%] text-[14px] ml-[10px] w-[40px] h-[40px]">
                  <p className=" text-[20px]">
                    {props?.newTags?.filter((fil) => !fil.selected)?.length}
                  </p>
                </div>
                <span className="  items-center mt-[14px] ml-[10px]  text-[16px] font-normal leading-[14px] text-Primary_white">
                  Tags selected
                </span>

                <div className="show-btn">
                  <div
                    className={`show_overbtn ${
                      props?.newTags?.filter((fil) => !fil.selected).length == 0
                        ? "opacity-60 cursor-default pointer-events-none"
                        : ""
                    }`}
                    onClick={handleClick}
                  >
                    <div>{icon == arrowup ? "Show" : "Hide"}</div>
                    <div>
                      <img
                        src={icon}
                        alt="Status Icon"
                        className=" h-[10px] w-[10px] ml-[5px] mt-[4px]"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="main-btn">
                <div className="cancel_overbtn" onClick={handleClose}>
                  Cancel
                </div>
                <div
                  className={`confirm_overbtn ${
                    isDisabled || props?.existingTags.length > 0
                      ? "cofirmhover"
                      : ""
                  }`}
                  style={{
                    backgroundColor:
                      isDisabled || props?.existingTags.length > 0
                        ? "var(--Button_green)"
                        : "var(--Secondary_grey)",
                    cursor:
                      isDisabled || props?.existingTags.length > 0
                        ? "pointer"
                        : "default",
                  }}
                  onClick={() =>
                    (isDisabled || props?.existingTags.length > 0) &&
                    handleConfirmTag()
                  }
                >
                  <p className="leading-[16px]">Confirm</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OwnerDetailsSelectTag;
