import React, { useState } from "react";
import "./index.css";
import CodeAuth from "../../../assets/GeneralIcons/backup_codes.svg";
import Xpentralogo from "../../../assets/landingPage/XpentraLogo.svg";
import { resetLoginError } from "../../../redux/features/login/login";
import eyeOff from "../../../assets/PasswordIcons/password_hidden_darkgrey.svg";
import eyeOn from "../../../assets/PasswordIcons/password_visible_darkgrey.svg";
import Warning from "../../../assets/ErrorIcons/error_with_white_circle.svg";
import Loader from "../../../assets/LoaderIcons/Loader.gif";
import { useDispatch } from "react-redux";

const EnterBackupCodes = ({
  cred,
  setCred,
  isFocus,
  setIsFocus,
  isLoading,
  data,
  isError,
  handleLogin,
  setIsBackupCodes,
}) => {
  const dispatch = useDispatch();
  const handleTotpChange = (e) => {
    let value = e.target.value;
    if (value?.length <= 7) {
      if (isError === true) {
        dispatch(resetLoginError());
      }
      setCred({ ...cred, totp: value });
    }
  };

  const handleBlur = () => {
    if (cred?.totp?.length === 0) {
      setIsFocus(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && cred?.totp?.length === 7) {
      handleLogin();
    }
  };

  const handleFocus = () => {
    setIsFocus(true);
  };

  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="entertotpcodes-main">
      <div className="entertotpcodes-left">
        <img src={CodeAuth} alt="CodeAuth" />
      </div>
      <div className="entertotpcodes-right bg-Primary_white">
        <div className="inner-entertotpcodes">
          <img src={Xpentralogo} className="entertotpcodes-right-1" />
          <h4 className="entertotpcodes-right-2 text-Font_14 font-semibold font-sans  text-Primary_black">
            Enter backup code
          </h4>
          <p className="entertotpcodes-right-3 text-Font_12 font-normal font-sans text-Primary_black">
            A list of 10 backup codes was shared during TOTP setup process, use
            any one backup code from the list
          </p>
          <div
            className="entertotpcodes-right-4 border-[1px] border-solid"
            style={{
              borderColor:
                isError === true && !isLoading
                  ? "var(--Failed) "
                  : isFocus
                  ? "var(--Pending)"
                  : "var(--Secondary_grey)",
              marginBottom: isError ? "10px" : "20px",
            }}
          >
            <img
              src={showPassword ? eyeOn : eyeOff}
              alt="passwordOnOff"
              className="logintotponoff"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            />
            <label
              className="font-sans  text-Font_16 font-normal bg-Primary_white"
              htmlFor="totpid"
              style={{
                top: isFocus ? "-12px" : "8px",
                fontSize: isFocus ? "12px" : "16px",
                color:
                  isError === true && !isLoading
                    ? "var(--Failed)"
                    : isFocus
                    ? "var(--Pending)"
                    : "var(--Secondary_grey)",
              }}
            >
              Enter backup code*
            </label>

            <input
              // disabled={data?.status === true}
              value={cred?.totp}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={(e) => {
                handleTotpChange(e);
              }}
              onKeyDown={handleKeyDown}
              type={showPassword ? "text" : "password"}
              className="input_Box_logintotp text-Primary_black font-sans text-Font_16 font-normal"
              spellCheck={false}
              id="totpid"
              autoComplete="off"
              style={{ padding: showPassword ? "18px" : "19px" }}
            />
          </div>
          <h4
            className="text-Failed font-semibold font-sans text-Font_14 mb-[20px]"
            style={{ display: isError ? "flex" : "none" }}
          >
            The backup code do not match
          </h4>
          <div className="backupcodeswarning bg-Highlight_orange">
            <img src={Warning} alt="Warning" />
            <p className="font-normal font-sans text-Primary_white text-Font_14">
              Note : Logging in with a backup code disables TOTP for your
              account. Re-setup of TOTP will be required afterward.
            </p>
          </div>
          <p
            className="entertotpcodes-right-5 hover:underline text-Font_12 font-normal font-sans text-[#3A69B2] "
            onClick={() => {
              setIsBackupCodes(false);
              dispatch(resetLoginError());
              setCred({ ...cred, totp: "", type: "totp" });
              setIsFocus(false);
            }}
          >
            Use TOTP code
          </p>
        </div>
        <button
          onClick={handleLogin}
          style={{
            backgroundColor:
              isError === true
                ? "var(--Secondary_grey)"
                : cred?.totp?.length === 7
                ? "var(--Button_green)"
                : "var(--Secondary_grey)",
            pointerEvents:
              isError === true || data?.status === true
                ? "none"
                : cred?.totp?.length === 7
                ? "auto"
                : "none",
          }}
          className={`entertotpcodes-right-6 text-Font_16 ${
            cred?.totp?.length === 7 && isError != true
              ? "font-semibold"
              : "font-normal"
          }  font-sans text-Primary_white`}
        >
          {isLoading === true ? (
            <img src={Loader} alt="Loader" />
          ) : (
            "Proceed with backup code"
          )}
        </button>
      </div>
    </div>
  );
};

export default EnterBackupCodes;
