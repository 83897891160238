import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import {
  AddUserTag,
  getUsers,
  RemoveUserTag,
  setUserStoredPage,
  setUserStoredPagesize,
} from "../../../redux/features/Settings/Management/Users";
import { checkUsertoken } from "../../../redux/features/login/login";
import dataLoading from "../../../assets/LoaderIcons/Loader.gif";
import noaccount from "../../../assets/No transaction found.svg";
import ArrowDown from "../../../assets/ArrowsIcons/arrow_down_white.svg";
import ArrowUp4 from "../../../assets/ArrowsIcons/arrow_up_blue.svg";
import ArrowUp from "../../../assets/ArrowsIcons/arrow_up_white.svg";
import selectWithWhiteFilledCircle from "../../../assets/CheckmarksIcons/not_selected_with_grey_circle.svg";
import selectWithGreenFilledCircle from "../../../assets/CheckmarksIcons/selected_with_green_circle.svg";
import FilterBlue from "../../../assets/FilterSearchIcons/filter_blue.svg";
import Filter from "../../../assets/FilterSearchIcons/filter.svg";
import tagIcon from "../../../assets/GeneralIcons/assign_tag without circle.svg";
import assigntagIcon from "../../../assets/GeneralIcons/assign_tag.svg";
import clearFilter from "../../../assets/CloseIcons/close_with_blue_circle.svg";
import SearchIcon from "../../../assets/FilterSearchIcons/search_white.svg";
import SearchBlue from "../../../assets/FilterSearchIcons/search_blue.svg";
import ArrowDown8 from "../../../assets/ArrowsIcons/arrow_down_blue.svg";
import Arrowdonw5 from "../../../assets/ArrowsIcons/arrow_down_white_filled.svg";
import { useNavigate } from "react-router-dom";
import { getUserDetailsStateAction } from "../../../redux/features/users/userDetails";
import { Box, Tooltip, tooltipClasses } from "@mui/material";
import PaginationComponent from "../../../components/_utils/PaginationComponent";
import RectangleSearchBar from "../../../components/_utils/RectangleSearchBar";
import UserTag from "../../../components/UserTag";
import OwnerDetailsSelectTag from "../../../components/Accounts/OwnerAccounts/OwnerDetailsSelectTag";
import Loader from "../../../components/Loader/Index";

const UsersSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dropdownOptions = [50, 100];
  const [isTagsPopupOpen, setIsTagsPopupOpen] = useState(false);
  const [multiSelect, setMultiSelect] = useState(false);
  const [totalCount, setTotalCount] = useState(false);
  const [usersDetails, setUsersDetails] = useState([]);
  const [usersArray, setUsersArray] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [AllTags, setAllTags] = useState([]);
  const [tagState, setTagState] = useState([]);
  const [newSelectedTags, setNewSelectedTags] = useState([]);
  const [searchUserText, setSearchUserText] = useState("");
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [tagsPopupOpen, setTagsPopupOpen] = useState(false);
  const searchRef = useRef(null);
  const [newTags, setNewTags] = useState([]);
  const filterButtonRef = useRef(null);
  const filterRef = useRef(null);
  const applyBtnRef = useRef(null);
  const [allData, setAllData] = useState([]);
  const [filterOpen, setFilterOpen] = useState(null);
  const [ownerTagState, setOwnerTagState] = useState([]);
  const searchButtonRef = useRef(null);
  const [pageSize, setPageSize] = useState(dropdownOptions[0]);
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[0]);
  const services = useSelector((state) => state.refreshToken.services);
  const { userStoredPage, userStoredPagesize } = useSelector(
    (state) => state.users
  );
  const [selectedAccount, setSelectedAccount] = useState(
    "All selected accounts"
  );
  const [selectedAssignedAccountID, setSelectedAssignedAccountID] =
    useState("");

  const selectedTagsCards = usersDetails?.filter((account) =>
    usersArray.includes(account.id)
  );

  const [filterPayload, setFilterPayload] = useState({
    ownerTags: [],
  });

  let availableTags = mainData?.available_tags || tagState || [];

  useEffect(() => {
    if (Number(userStoredPage) > 1) {
      setCurrentPage(Number(userStoredPage));
    }
    if (Number(userStoredPagesize) > 50) {
      setSelectedValue(userStoredPagesize);
    }
    handleWindowSizeChange();
    sessionStorage.removeItem("userDetailsInfostorage");
  }, []);

  const handleUserDetails = (userId) => {
    let userDetailsInfo = false;
    userDetailsInfo = usersDetails.filter((fil) => {
      if (fil.id == userId) {
        return fil;
      }
    });
    if (userDetailsInfo) {
      dispatch(setUserStoredPage(currentPage));
      dispatch(setUserStoredPagesize(selectedValue));
      navigate(`/settings/Users/User_Details/${userId}`);
      dispatch(checkUsertoken());
      dispatch(getUserDetailsStateAction(userDetailsInfo));
      sessionStorage.setItem(
        "userDetailsInfostorage",
        JSON.stringify(userDetailsInfo)
      );
    }
  };

  useEffect(() => {
    if (selectedAccount !== "All selected accounts") {
      const selectedAccountTags =
        allData?.find((account) => account.id === selectedAssignedAccountID)
          ?.account_tags || [];

      setNewSelectedTags(
        selectedAccountTags.map((tag) => ({
          value: tag,
          selected: true,
          type: "ownerTags",
        }))
      );

      setAllTags(
        availableTags?.map((tag) => ({
          value: tag,
          selected: true,
          type: "ownerTags",
        }))
      );
    } else if (
      selectedTagsCards?.length === 1 &&
      selectedAccount != "All selected accounts"
    ) {
      const accountTags =
        allData?.find((account) => usersArray.includes(account.id))
          ?.account_tags || [];
      setNewSelectedTags(
        accountTags.map((tag) => ({
          value: tag,
          selected: true,
          type: "ownerTags",
        }))
      );
      setAllTags(
        availableTags?.map((tag) => ({
          value: tag,
          selected: true,
          type: "ownerTags",
        }))
      );
    } else if (
      selectedTagsCards?.length > 1 ||
      selectedAccount === "All selected accounts"
    ) {
      let a = allData?.filter((account) => usersArray.includes(account.id));
      const commonTags = a.reduce((common, account) => {
        return common.filter((tag) => account.account_tags?.includes(tag));
      }, a[0]?.account_tags || []);

      const uncommonTags = a.reduce((uncommon, account) => {
        return uncommon.concat(
          account.account_tags?.filter((tag) => !commonTags.includes(tag)) || []
        );
      }, []);

      setNewSelectedTags(
        commonTags.map((tag) => ({
          value: tag,
          selected: true,
          type: "ownerTags",
        }))
      );

      setAllTags(
        [...new Set([...availableTags, ...uncommonTags])].map((tag) => ({
          value: tag,
          selected: true,
          type: "ownerTags",
        }))
      );
    } else {
      setNewSelectedTags([]);
      setAllTags(
        availableTags?.map((tag) => ({
          value: tag,
          selected: true,
          type: "ownerTags",
        }))
      );
    }
  }, [
    usersArray,
    mainData,
    selectedAssignedAccountID,
    selectedAccount,
    allData,
  ]);

  const handleAddTags = async (item) => {
    try {
      dispatch(checkUsertoken());
      setLoader(true);
      const selectedTags = newTags
        .filter((tag) => tag.selected === false)
        .map((tag) => tag.value);

      try {
      } catch (error) {}

      let response = await dispatch(
        AddUserTag({
          user_id:
            selectedAssignedAccountID !== ""
              ? [selectedAssignedAccountID]
              : usersArray,
          tags: item?.type === "newAss" ? item?.data1 : [item],
        })
      );

      if (response?.payload?.status === true) {
        setNewTags((prev) => [...prev, response?.meta?.arg?.tags]);
        let val = allData.map((itm) => {
          if (usersArray.includes(itm.id)) {
            return {
              ...itm,
              account_tags: [
                ...new Set([
                  ...(itm.account_tags || []),
                  ...(response?.meta?.arg?.tags || []),
                ]),
              ],
            };
          }
          return itm;
        });
        setAllData(val);
        fetchData(true);
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const handleRemoveTags = async (item) => {
    try {
      dispatch(checkUsertoken());
      setLoader(true);
      const response = await dispatch(
        RemoveUserTag({
          user_id:
            selectedAssignedAccountID !== ""
              ? [selectedAssignedAccountID]
              : usersArray,
          tags: item,
        })
      );

      if (response?.payload?.status === true) {
        setNewTags(response?.payload?.tags?.value || []);
        let val = allData.map((itm) => {
          if (usersArray.includes(itm.id)) {
            return {
              ...itm,
              account_tags: itm.account_tags.filter(
                (tag) => !response?.meta?.arg?.tags?.includes(tag)
              ),
            };
          }
          return itm;
        });
        fetchData(true);
        setAllData(val);
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error removing tag:", error);
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    setSearchUserText(e.target.value);
  };

  function handleWindowSizeChange() {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1140 && screenWidth < 2560) {
      setPageSize(50);
    } else if (screenWidth >= 2560 && screenWidth < 3840) {
      setPageSize(102);
    } else if (screenWidth >= 3840) {
      setPageSize(192);
    }
  }

  useEffect(() => {
    const handleDropDown = (e) => {
      if (searchRef.current && searchRef.current.contains(e.target)) {
      } else if (
        searchButtonRef.current &&
        searchButtonRef.current.contains(e.target)
      ) {
      } else {
        setShowSearchBar(false);
      }
    };
    document.addEventListener("click", handleDropDown);
  }, [searchRef]);

  const fetchData = async (val) => {
    let queryParams = {
      page_no: currentPage,
      page_size: pageSize,
      search_string: val ? "" : searchUserText,
      account_tags: ownerTagState,
    };
    setLoader(true);
    try {
      dispatch(checkUsertoken());
      const response = await dispatch(getUsers(queryParams));
      const newData = response?.payload?.employees || [];
      if (searchUserText.length === 0 && ownerTagState.length == 0) {
        setTagState(response?.payload?.available_tags);
      } else {
        if (sessionStorage?.getItem("UserAvailableTags")) {
          let val = sessionStorage?.getItem("UserAvailableTags");
          setTagState(JSON.parse(val) || []);
        }
      }
      setMainData(response?.payload || []);

      setTotalCount(response?.payload?.total_count);

      setLoader(false);

      setAllData((prev) => {
        return [
          ...new Map(
            [...prev, ...newData].map((item) => [item["id"], item])
          ).values(),
        ];
      });
      setUsersDetails(newData);
    } catch (error) {
      setLoader(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
    }
    if (event.key === "Enter") {
      applyBtnRef.current.click();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (services && services.includes("Admin")) {
      if (!services.includes("MimicEntity")) {
        navigate("/feature_not_assigned");
      } else {
        navigate("/settings/Users");
      }
    } else {
      if (services && !services.includes("VEmp")) {
        navigate("/feature_not_assigned");
      } else {
        navigate("/settings/Users");
      }
    }

    fetchData();
  }, [searchUserText, pageSize, currentPage, ownerTagState]);

  const handleSelect = (value) => {
    setCurrentPage(1);
    setSelectedValue(value);
    setPageSize(value);
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const handlefilter = (e) => {
      if (filterRef.current && filterRef.current.contains(e.target)) {
      } else if (
        filterButtonRef.current &&
        filterButtonRef.current.contains(e.target)
      ) {
      } else {
        setFilterOpen((prev) => (prev === null ? null : false));
      }
    };
    document.addEventListener("click", handlefilter);
  }, [filterRef]);

  return (
    <>
      {
        <div
          style={{
            zIndex: filterOpen ? "0" : "9999",
            width: "100%",
            bottom: "0",
          }}
          className="fixed w-full bg-Blue_4 h-[50px]"
        >
          <PaginationComponent
            handleSelect={handleSelect}
            selectedValue={selectedValue}
            data={mainData}
            pageSize={pageSize}
            dropdownOptions={dropdownOptions}
            handlePagination={handlePagination}
            currentPage={currentPage}
          />
        </div>
      }
      {isTagsPopupOpen && (
        <div
          onClick={() => setIsTagsPopupOpen(false)}
          className="fixed left-0 w-full top-0 h-full bg-black bg-opacity-80 flex items-center justify-center z-[99999]"
        >
          <UserTag
            onClose={() => setIsTagsPopupOpen(false)}
            newTags={newSelectedTags}
            setNewTags={setNewSelectedTags}
            AllTags={AllTags}
            setAllTags={setAllTags}
            ownerDetailData={[
              ...new Map(allData.map((item) => [item["id"], item])).values(),
            ].filter((account) => usersArray.includes(account.id))}
            type="userViewAll"
            loading={loader}
            setLoading={setLoader}
            handleAddTags={handleAddTags}
            handleRemoveTags={handleRemoveTags}
            selectedAssignedAccountID={selectedAssignedAccountID}
            setSelectedAssignedAccountID={setSelectedAssignedAccountID}
            selectedTagsCards={[
              ...new Map(allData.map((item) => [item["id"], item])).values(),
            ].filter((account) => usersArray.includes(account.id))}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
            users={[
              ...new Map(allData.map((item) => [item["id"], item])).values(),
            ]
              .filter((account) => usersArray.includes(account.id))
              ?.map((ac) => ac.first_name)}
          />
        </div>
      )}
      {tagsPopupOpen && (
        <div
          onClick={(e) => e.stopPropagation()}
          className="fixed top-0 w-full h-full bg-black bg-opacity-80 flex items-center justify-center z-[9999]"
        >
          {
            <OwnerDetailsSelectTag
              onClose={() => setTagsPopupOpen(false)}
              handlefilterPayload={setFilterPayload}
              newTags={newTags}
              existingTags={filterPayload?.ownerTags}
              setNewTags={setNewTags}
              ownerExistingTag={tagState}
              userviewall={true}
              ownerviewall={true}
            />
          }
        </div>
      )}
      <div
        className={`bg-custom-appBack flex flex-col h-screen overflow-y-hidden`}
        style={{
          overflow: filterOpen && "hidden",
        }}
      >
        <div
          className={`user_div_container ${
            multiSelect ? "h-[150px]" : " h-[105px]"
          } ${
            showSearchBar && !multiSelect
              ? "user_div_height"
              : showSearchBar && multiSelect
              ? "userViewallContainer"
              : ""
          }`}
        >
          <div className={"user_div"}>
            {multiSelect ? (
              <div className="w-[591px] mt-[45px] flex gap-[20px]">
                <div
                  onClick={() => {
                    if (usersArray.length > 0) {
                      setUsersArray([]);
                    } else {
                      setSearchUserText("");
                      setCurrentPage(1);
                      setSelectedValue(50);
                      setMultiSelect(false);
                    }
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                  className="w-[125px] h-[60px] hover:bg-Primary_white hover:bg-opacity-20 bg-Blue_7 flex justify-center items-center"
                >
                  <p
                    className={`${
                      usersArray.length > 0 ? "w-[39px]" : "w-[50px]"
                    } h-[22px] text-[16px] text-Primary_white `}
                  >
                    {usersArray.length > 0 ? "Clear" : "Cancel"}
                  </p>
                </div>
                <div className="w-[158px] py-[10px] flex flex-col">
                  <p className="text-[14px] font-semibold text-Primary_white">
                    {usersArray?.length}
                  </p>
                  <p className="text-[12px] text-Primary_grey">
                    {usersArray?.length > 1
                      ? "Users selected"
                      : "User selected"}
                  </p>
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "102px",
                  height: " 105px",
                  transition: "height 0.2s ease-in-out",
                  padding: "45px 20px",
                }}
                className=" user-containers"
              >
                <div
                  style={{
                    color: "var(--Primary_white)",
                  }}
                  className="Nos-of-balancess"
                >
                  {totalCount || "0"}
                </div>
                <div className="views-counts">
                  <p>Total users</p>
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "105px",
                width: multiSelect ? "197px" : "293px",
                paddingTop: "45px",
              }}
            >
              <div
                style={{
                  display: !multiSelect ? "flex" : "none",
                  opacity:
                    filterOpen ||
                    showSearchBar ||
                    (services && !services.includes("Admin"))
                      ? "0.6"
                      : "1",
                  cursor:
                    filterOpen ||
                    showSearchBar ||
                    (services && !services.includes("Admin"))
                      ? "default"
                      : "pointer",
                }}
                onClick={() => {
                  if (services && services.includes("Admin")) {
                    if (!showSearchBar && !filterOpen) {
                      setSearchUserText("");
                      setCurrentPage(1);
                      setSelectedValue(50);
                      setMultiSelect(true);
                    }
                  } else {
                    navigate("/feature_not_assigned");
                  }
                }}
                className={`assigntagdiv ${
                  services &&
                  services.includes("Admin") &&
                  "hover:bg-custom-cardHover"
                }`}
              >
                <img src={assigntagIcon} alt="" />
                <span>Assign tags to users</span>
              </div>
              <Tooltip
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black,
                      },
                      zIndex: "2147483647 !important",
                    },
                  },
                }}
                title="Search"
              >
                <div
                  ref={searchButtonRef}
                  style={{
                    backgroundColor:
                      searchUserText.length > 0 && "var(--Primary_white)",
                    opacity: filterOpen ? "0.6" : "1",
                    cursor: filterOpen ? "default" : "pointer",
                  }}
                  className={`user_search_div ${
                    filterOpen ? "" : " hover:bg-custom-cardHover"
                  }`}
                  onClick={() => {
                    if (!filterOpen) {
                      setShowSearchBar(!showSearchBar);
                    }
                  }}
                >
                  <img
                    className="h-[14px] w-[14px]"
                    src={searchUserText.length > 0 ? SearchBlue : SearchIcon}
                    alt="search-image"
                  />
                  <img
                    style={{
                      transform: showSearchBar && "rotate(180deg)",
                    }}
                    src={searchUserText.length > 0 ? ArrowDown8 : ArrowDown}
                    alt=""
                    className="filter-images"
                  />
                </div>
              </Tooltip>

              <div
                style={{ display: !multiSelect ? "flex" : "none" }}
                ref={filterButtonRef}
                onClick={(e) => {
                  if (!showSearchBar) {
                    setFilterOpen(filterOpen === null ? true : !filterOpen);
                  }
                }}
              >
                <Tooltip
                  componentsProps={{
                    popper: {
                      sx: {
                        [`& .${tooltipClasses.tooltip}`]: {
                          backgroundColor: (theme) =>
                            theme.palette.common.black,
                        },
                        zIndex: "2147483647 !important",
                      },
                    },
                  }}
                  title="Filters"
                >
                  <div
                    style={{
                      backgroundColor:
                        ownerTagState.length > 0 ? "var(--Primary_white)" : "",
                      opacity: showSearchBar ? "0.6" : "1",
                      cursor: showSearchBar ? "default" : "pointer",
                    }}
                    className={`filter-containers ${
                      showSearchBar ? "" : " hover:bg-custom-cardHover"
                    }`}
                  >
                    <Box className="filters-box">
                      <img
                        src={ownerTagState.length > 0 ? FilterBlue : Filter}
                        alt=""
                        className="filter-images"
                      />
                    </Box>
                    <Box className="filter-box">
                      <img
                        src={
                          filterOpen
                            ? ownerTagState.length > 0
                              ? ArrowUp4
                              : ArrowUp
                            : ownerTagState.length > 0
                            ? ArrowDown8
                            : ArrowDown
                        }
                        alt=""
                        className="filter-images"
                      />
                    </Box>
                  </div>
                </Tooltip>
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if (usersArray?.length > 0) {
                    setUsersArray(usersArray);
                    setIsTagsPopupOpen(true);
                  }
                }}
                style={{
                  display: multiSelect ? "flex" : "none",
                  backgroundColor:
                    usersArray?.length > 0
                      ? "var(--Primary_white)"
                      : "var(--Secondary_grey)",
                  cursor: usersArray?.length > 0 ? "pointer" : "default",
                  opacity: usersArray?.length > 0 ? "1" : "0.8",
                }}
                className={`w-[125px] h-[60px] flex justify-center items-center ${
                  usersArray?.length > 0 && "font-semibold hover:bg-opacity-80"
                }`}
              >
                <p
                  className={`w-[96px] h-[22px] text-[16px] ${
                    usersArray?.length > 0
                      ? "text-Blue_3"
                      : "text-Primary_white"
                  }`}
                >
                  Assign Tag/s
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              display: multiSelect ? "flex" : "none",
              borderTop: "1px solid var(--Blue_1)",
              height: "36px",
              marginBottom: showSearchBar && "-10px",
            }}
            className="w-[1024px] mx-auto py-[10px] relative"
          >
            <p
              style={{ maxWidth: "none", width: "auto", textTransform: "none" }}
              className="user-id multiselectanimation"
            >
              Select users to assign tags. Tagged users will gain access to view
              owner accounts that share the same tags.
            </p>
          </div>
          <div
            style={{ height: showSearchBar && "0px" }}
            className={`user-filter-transition-${
              filterOpen == true
                ? "open"
                : filterOpen == false
                ? "close"
                : "default"
            }`}
          >
            <div ref={filterRef}>
              <div className="user-owner_filterdropdown">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: "25px",
                    height: "176px",
                  }}
                >
                  <div style={{ position: "relative", width: "50%" }}>
                    <p
                      style={{
                        color: "var(--Failed)",
                        width: "100px",
                        height: "19px",
                        font: "normal normal 600 14px/19px Open Sans",
                        marginBottom: "20px",
                        paddingTop: "10px",
                      }}
                    >
                      Filter
                    </p>
                    <p
                      style={{
                        color: "var(--Primary_grey)",
                        height: "22px",
                        fontSize: "12px",
                        marginBottom: "5px",
                      }}
                    >
                      Assigned tags
                    </p>
                    <div
                      onClick={() => setTagsPopupOpen(true)}
                      className="user-owner_connected_bank cursor-pointer"
                      style={{
                        height: "37px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          color: filterPayload?.ownerTags?.length
                            ? "var(--Primary_white)"
                            : "var(--Primary_grey)",
                          padding: "8px 15px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          {filterPayload?.ownerTags?.length > 0
                            ? filterPayload?.ownerTags?.length + " selected"
                            : "Select tags"}
                        </span>
                        <img
                          src={Arrowdonw5}
                          alt="down"
                          style={{
                            height: "12px",
                            transform: "rotate(270deg) translate(0px, 5px)",
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="user-buttoncontainer usrfilter">
                    <div
                      style={{
                        cursor:
                          filterPayload?.ownerTags?.length > 0 ||
                          ownerTagState.length > 0
                            ? "pointer"
                            : "default",
                        opacity:
                          filterPayload?.ownerTags?.length > 0 ||
                          ownerTagState.length > 0
                            ? "1"
                            : "0.6",
                      }}
                      onClick={() => {
                        if (
                          filterPayload?.ownerTags?.length > 0 ||
                          ownerTagState.length > 0
                        ) {
                          setOwnerTagState([]);
                          setFilterPayload({
                            ownerTags: [],
                          });
                          setCurrentPage(1);
                        }
                      }}
                      className={`user-clear ${
                        filterPayload?.ownerTags?.length > 0 ||
                        ownerTagState.length > 0
                          ? "hover:bg-Blue_7"
                          : ""
                      }`}
                    >
                      <p className="user-buttoncontainer-text">Clear</p>
                    </div>
                    <div
                      ref={applyBtnRef}
                      onClick={() => {
                        if (
                          filterPayload?.ownerTags?.length > 0 ||
                          ownerTagState.length > 0
                        ) {
                          setOwnerTagState(filterPayload?.ownerTags);

                          setCurrentPage(1);

                          setFilterOpen(false);
                        }
                      }}
                      className={`user-apply ${
                        filterPayload?.ownerTags?.length > 0 ||
                        ownerTagState.length > 0
                          ? "bg-Button_red hover:bg-Button_red_hover"
                          : "bg-Secondary_grey"
                      }`}
                      style={{
                        cursor:
                          filterPayload?.ownerTags?.length > 0 ||
                          ownerTagState.length > 0
                            ? "pointer"
                            : "default",

                        opacity:
                          filterPayload?.ownerTags?.length > 0 ||
                          ownerTagState.length > 0
                            ? "1"
                            : "0.6",
                      }}
                    >
                      <p className="user-buttoncontainer-text">Apply</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mx-auto">
            {showSearchBar && (
              <RectangleSearchBar
                multiSelect={multiSelect}
                handleSearch={setSearchUserText}
                showSearchBar={showSearchBar}
                searchUserText={searchUserText}
                setSearchUserText={setSearchUserText}
                onPaste={handlePaste}
                toggleShowSearchBar={() => {
                  setFilterOpen(false);
                  setShowSearchBar(!showSearchBar);
                }}
                bgColor={"bg-Blue_3"}
                bgHover={"hover:bg-custom-cardHover"}
                setCurrentPage={setCurrentPage}
                searchRef={searchRef}
                placeholder="Search by first name/ last name/ user ID/ full name"
              />
            )}
          </div>
        </div>
        {ownerTagState?.length > 0 && (
          <div className="filter_applied_div w-full">
            <div
              style={{
                borderTop: "1px solid var(--Blue_1)",
              }}
              className="w-[1024px] mx-auto py-[10px] flex"
            >
              <p className="filterby_text">Filtered by :</p>
              <div className="filter_badge_div">
                {ownerTagState?.length > 0 && (
                  <div className="filter_badge">
                    <p className="filter_badge_text">
                      {`${ownerTagState?.length} ${
                        ownerTagState?.length > 1 ? "tags" : "tag"
                      } selected`}
                    </p>
                    <img
                      onClick={() => {
                        setOwnerTagState([]);
                        let obj = {
                          ownerTags: [],
                        };
                        setFilterPayload(obj);
                      }}
                      className="filter_badge_img"
                      src={clearFilter}
                      alt="X"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {loader && !isTagsPopupOpen ? (
          <>
            <div className="loader-container m-auto justify-center items-center flex loader-height">
              <Loader />
            </div>
          </>
        ) : usersDetails.length != 0 ? (
          <>
            <div
              className={
                filterOpen
                  ? "overflow-y-hidden h-screen ownerviewallscroll opacity-80  bg-black mt-[5px]"
                  : "overflow-y-auto h-screen mb-[50px] ownerviewallscroll mt-[5px]"
              }
            >
              <div
                className={`${
                  filterOpen &&
                  "bg-black opacity-10 z-[-99999] pointer-events-none "
                } UserCard`}
              >
                {usersDetails.map((userDtls) => {
                  return (
                    <div
                      key={userDtls?.id}
                      className={`user-details-card ${
                        multiSelect && usersArray?.includes(userDtls.id)
                          ? "bg-Category_selection hover:bg-Category_selection_hover"
                          : "bg-Blue_6 hover:bg-Blue_6_hover"
                      }`}
                      onClick={(e) => {
                        if (!filterOpen) {
                          if (multiSelect) {
                            e.stopPropagation();

                            if (usersArray?.includes(userDtls.id)) {
                              setUsersArray(() => {
                                return usersArray?.filter((acc) => {
                                  return acc !== userDtls.id;
                                });
                              });
                            } else {
                              setUsersArray([...usersArray, userDtls.id]);
                            }
                          } else {
                            handleUserDetails(userDtls.id);
                          }
                        }
                      }}
                    >
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title={userDtls.first_name + " " + userDtls.last_name}
                      >
                        <div className="w-[215px] flex justify-between">
                          <div className="user-name truncate">
                            {userDtls.first_name + " " + userDtls.last_name}
                          </div>
                          <div
                            style={{ display: multiSelect ? "flex" : "none" }}
                            className="h-[16px] w-[16px] my-[3px]"
                          >
                            <img
                              src={
                                usersArray?.includes(userDtls.id)
                                  ? selectWithGreenFilledCircle
                                  : selectWithWhiteFilledCircle
                              }
                              alt="white-filled-check"
                            />
                          </div>
                        </div>
                      </Tooltip>
                      <div className="user-id">{userDtls.user_id}</div>
                      <div className="user-label flex justify-between">
                        User ID
                      </div>
                      <div className="userborderdiv"></div>
                      <div className="user-label">
                        <img src={tagIcon} alt="" />
                        <span>Assigned tags</span>
                      </div>
                      <Tooltip
                        componentsProps={{
                          popper: {
                            placement: "bottom",
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                              zIndex: "2147483647 !important",
                            },
                          },
                        }}
                        title={String(userDtls?.account_tags)}
                      >
                        <div className="user-id">
                          {userDtls?.account_tags.length > 0 ? (
                            userDtls?.account_tags?.map((a, i) => {
                              if (i == userDtls?.account_tags.length - 1) {
                                return a;
                              } else {
                              }
                              return a + ", ";
                            })
                          ) : (
                            <span>No tags assigned</span>
                          )}
                        </div>
                      </Tooltip>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className={
                filterOpen
                  ? `h-full w-full bg-black opacity-80 flex flex-col items-center justify-center m-auto`
                  : "flex flex-col items-center justify-center m-auto pb-[50px] overflow-y-scroll w-full"
              }
            >
              <img
                src={noaccount}
                alt="No accounts found"
                className={filterOpen ? "no-acc-image-opacity" : "no-acc-image"}
              />
              <p
                className={
                  filterOpen
                    ? "no-owneraccount-text-opacity"
                    : "no-owneraccount-text"
                }
              >
                No data found.
              </p>
              <div
                style={{
                  opacity: filterOpen ? "0.2" : "1",
                  pointerEvents: filterOpen ? "none" : "auto",
                  display:
                    ownerTagState?.length > 0 || searchUserText.length > 0
                      ? "flex"
                      : "none",
                }}
                className="oas-selectaccount min-h-[36px]"
                onClick={() => {
                  setSearchUserText("");
                  setOwnerTagState([]);
                  setFilterPayload({
                    ownerTags: [],
                  });
                  setCurrentPage(1);
                }}
              >
                <p className="oas-selectaccounttext">Clear parameters</p>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UsersSettings;
