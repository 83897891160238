import React from "react";
import "./index.css";
import Loader from "../../../assets/LoaderIcons/Loader.gif";
import {
  disableTotp,
  removeInitialError,
} from "../../../redux/features/totp/totp";
import { useDispatch } from "react-redux";
import { checkUsertoken } from "../../../redux/features/login/login";

const DisabledPop = ({
  setOpenDisabledPop,
  userId,
  setCurrentDisplay,
  disableTotpLoading,
}) => {
  const dispatch = useDispatch();

  return (
    <div className="disabled_pop_totp">
      <div className="disabled_pop_totp_box">
        <h4 className="disabled_pop_totp_box_1">Disable TOTP</h4>
        <p className="disabled_pop_totp_box_2">
          Are you sure you want to disable TOTP?
        </p>
        <p className="disabled_pop_totp_box_3">
          Note - The two-factor authentication using TOTP will become inactive.
        </p>
        <div className="disabled_pop_totp_box_butt">
          {disableTotpLoading ? (
            <img src={Loader} alt="Loader" />
          ) : (
            <div className="totp_can_con_main">
              <button
                className="disabled_pop_totp_box_butt_can"
                onClick={() => {
                  setOpenDisabledPop(false);
                }}
              >
                Cancel
              </button>
              <button
                className="disabled_pop_totp_box_butt_con"
                onClick={() => {
                  dispatch(checkUsertoken());
                  dispatch(removeInitialError());
                  dispatch(
                    disableTotp({
                      id: userId,
                      setCurrentDisplay: setCurrentDisplay,
                      setOpenDisabledPop: setOpenDisabledPop,
                    })
                  );
                }}
              >
                Disable
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DisabledPop;
