import React from "react";
import "./index.css";
import EnterBackupCodes from "../../../components/LoginTotp/EnterBackupCodes";
import EnterTotpCode from "../../../components/LoginTotp/EnterTotpCode";

const LoginTotp = ({
  isFocus,
  setIsFocus,
  isLoading,
  data,
  isError,
  handleLogin,
  handleKeyPress,
  cred,
  setCred,
  isBackupCodes,
  setIsBackupCodes,
}) => {
  return (
    <div className="login-totp-main">
      {isBackupCodes ? (
        <EnterBackupCodes
          isFocus={isFocus}
          setIsFocus={setIsFocus}
          isLoading={isLoading}
          data={data}
          isError={isError}
          handleLogin={handleLogin}
          handleKeyPress={handleKeyPress}
          cred={cred}
          setCred={setCred}
          setIsBackupCodes={setIsBackupCodes}
        />
      ) : (
        <EnterTotpCode
          isFocus={isFocus}
          setIsFocus={setIsFocus}
          isLoading={isLoading}
          data={data}
          isError={isError}
          handleLogin={handleLogin}
          handleKeyPress={handleKeyPress}
          cred={cred}
          setCred={setCred}
          setIsBackupCodes={setIsBackupCodes}
        />
      )}
    </div>
  );
};

export default LoginTotp;
