import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import LoginAdmin from "../../../assets/loginImage/loginAdmin.png";

const AdminContact = () => {
  const navigate = useNavigate();

  return (
    <div className="row">
      <div className="col-xl-12">
        <div style={{ width: "100%", maxWidth: "1024px", margin: "10% auto" }}>
          <div className="row">
            <div className="col-xl-6 col-md-6 col-sm-12 col-xs-12">
              <img
                src={LoginAdmin}
                className="img-fluid"
                alt="bg_image"
                style={{
                  margin: "5% auto",
                  display: "block",
                  height: "auto",
                }}
              />
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12 col-xs-12">
              <div
                className="text-Primary_white"
                style={{ textAlign: "center", padding: "0px 20px" }}
              >
                <p className="font-semibold mb-8 text-2xl">
                  Contact your admin
                </p>
                <span className="text-base">
                  Password can only be reset by your admin.
                  <br /> Contact the admin and request them to reset your
                  password.
                </span>
                <p className="pt-5">
                  <span className="block text-Primary_white">
                    For the admin's assistance -
                  </span>{" "}
                  to reset the password the admin will have to:
                </p>
                <div className="my-8 text-center">
                  <span className="text-Primary_grey no-underline">
                    Open Q2Pay → Settings → Users
                    <br /> → Select user → Reset password
                  </span>
                </div>
                <p className="text-center">Password reset successfully?</p>
                <div className="flex justify-center w-full px-8 pt-4 mt-5">
                  <Button
                    onClick={() => {
                      sessionStorage.clear();
                      navigate("/login", { replace: true });
                    }}
                    sx={{
                      borderRadius: "20px",
                      color: "#f9f9f9",
                      textTransform: "capitalize",
                      backgroundColor: "var(--Button_green)",
                      border: "1px solid transparent",
                      fontFamily: "'Open Sans', sans-serif",
                      fontSize: "16px",
                      "&:hover": {
                        backgroundColor: "var(--Button_green)",
                        border: "1px solid transparent",
                      },
                      width: "100%",
                      maxWidth: "200px",
                    }}
                  >
                    Login
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminContact;
