import React, { useState, useEffect } from "react";
import { Box, Tooltip, tooltipClasses } from "@mui/material";
import BeneCardLogo from "../../../../containers/Accounts/BeneficiaryAccount/BeneCardLogo";
import Refreshicon from "../../../../assets/RefreshIcons/refresh_with_blue_box.svg";
import BalanceErrorIcon from "../../../../assets/ErrorIcons/error_with_orange_circle.svg";
import Info from "../../../../assets/ViewIcons/view_statement_with_blue_box.svg";
import { formatAccountNumber } from "../../../../components/_utils/FormatAccountNumber/index";
import BalanceLoader from "../../../../components/_utils/BalanceLoader";
import { useSelector } from "react-redux";
import selectWithWhiteFilledCircle from "../../../../assets/CheckmarksIcons/select_all_with_white_filled_circle.svg";
import selectWithGreenFilledCircle from "../../../../assets/CheckmarksIcons/selected_with_green_circle.svg";
import { setStoredPage } from "../../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import AnimationUpAndDown from "../../../../components/BeneTransaction/OwnerPopup/AnimationUpAndDown/index";
import formatTotalBalance from "../../../../utils/formatTotalBalance";
import { toast } from "react-toastify";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import {
  beneViewAllAcc,
  resetViewAllAcc,
} from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { checkUsertoken } from "../../../../redux/features/login/login";
import Notselected from "../../../../assets/CheckmarksIcons/not_selected_with_grey_circle.svg";
import assignTagImg from "../../../../assets/General Icons/assign_tag_img_withoutCircle.svg";
// import yellowStar from "../../../../assets/OwnerAccount/yellowStar.svg";

const OwnerGridViewComponent = ({
  index,
  navigate,
  dispatch,
  account,
  isopen,
  page,
  refreshAllData,
  refreshType,
  setRefreshtype,
  setId,
  total_balance,
  setPrev_Total_balance_state,
  setFailedRefreshId,
  failedRefreshId,
  successRefreshId,
  setSuccessRefreshId,
  currency,
  refreshAll,
  typepop,
  setIsNeft,
  setIsImps,
  setIsRtgs,
  txnPayLoad,
  setTxnPayLoad,
  handleButtonClick,
  BenEnableNextButton,
  setOpenOwner,
  flow,
  handleViewStatement,
  handleClick,
  ownerAccBalanceLoading,
  ownerAccBalanceError,
  accId,
  setAccId,
  setStatementId,
  setAccData,
  prevId,
  setPrevId,
  prevBalance,
  setPrevBalance,
  multiSelect,
  isAssignTagsClicked,
  owneraccountnumberstate,
  setowneraccountnumberstate,
  favouritesFilter,
}) => {
  const services = useSelector((state) => state.refreshToken.services);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 540);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 540);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const showToast = () => {
    toast.info("Feature coming soon for mobile!", toast_position);
  };

  return (
    <>
      <div
        key={account.id}
        style={{
          position: "relative",
          width: isMobile ? "100%" : "238px",
          height: "103px",
        }}
      >
        <Box
          key={index}
          className={`hover:bg-Blue_7 relative  ${
            isAssignTagsClicked
              ? "owner-tag-card"
              : `ownerSelectownerCard ${
                  failedRefreshId.includes(account.id)
                    ? "refresh-error"
                    : "refresh-not-error"
                }`
          }`}
          style={{
            height: isAssignTagsClicked && "105px",
            backgroundColor:
              multiSelect && owneraccountnumberstate?.includes(account.id)
                ? "#4A6D4B"
                : isAssignTagsClicked &&
                  owneraccountnumberstate?.includes(account.id)
                ? "#4A6D4B"
                : "#3A5686",
            opacity:
              typepop === "popup" &&
              account?.connected_banking === "N" &&
              flow !== "internalTxn"
                ? "0.4"
                : "1",
            cursor:
              typepop === "popup" &&
              account?.connected_banking === "N" &&
              flow !== "internalTxn"
                ? "default"
                : "pointer",
            pointerEvents:
              typepop === "popup" &&
              account?.connected_banking === "N" &&
              flow !== "internalTxn"
                ? "none"
                : "auto",
          }}
          onClick={async (e) => {
            if (
              account?.pan_no === txnPayLoad?.pan &&
              account?.bank_acc_no === txnPayLoad?.bankAccountNo &&
              flow === "internalTxnSource"
            ) {
              toast.error("Same Account can't be selected", toast_position);
              return;
            }

            if (isAssignTagsClicked) {
              e.stopPropagation();

              if (owneraccountnumberstate?.includes(account.id)) {
                setowneraccountnumberstate(() => {
                  return owneraccountnumberstate?.filter((acc) => {
                    return acc !== account.id;
                  });
                });
              } else {
                setowneraccountnumberstate([
                  ...owneraccountnumberstate,
                  account.id,
                ]);
              }
            } else if (typepop === "popup") {
              if (multiSelect) {
                e.stopPropagation();
                if (owneraccountnumberstate?.includes(account.id)) {
                  setowneraccountnumberstate(() => {
                    return owneraccountnumberstate?.filter((acc) => {
                      return acc !== account.id;
                    });
                  });
                } else {
                  if (owneraccountnumberstate?.length < 10) {
                    setowneraccountnumberstate([
                      ...owneraccountnumberstate,
                      account.id,
                    ]);
                  }
                }
              } else if (flow === "createTxn" || flow === "internalTxnSource") {
                setTxnPayLoad({
                  ...txnPayLoad,
                  ownerId: account?.id,
                  ownerBankIFSC: account?.ifsc_code,
                  ownerBankBalance: account?.owner_account_balance,
                  ownerBankLogo: account?.bank_name,
                  ownerLastUpdated:
                    account?.owner_account_balance_last_fetched_at_in_words,
                  ownerBankAccountNo: account?.bank_acc_no,
                  ownerBankAccountName: account?.bank_account_holder_full_name,
                  settleType: "neft",
                });
                setIsNeft(true);
                setIsImps(false);
                setIsRtgs(false);
                BenEnableNextButton(false);
              } else if (flow === "txnViewAll") {
                if (e && e.stopPropagation) {
                  e.stopPropagation();
                }
                handleButtonClick(account);
              }

              if (!multiSelect) {
                setOpenOwner(false);
              }
            } else {
              let selection = window.getSelection();
              if (selection.toString().length === 0) {
                if (!isopen) {
                  await dispatch(setStoredPage(page));
                  navigate(
                    `/accounts/owner/owner_account_details/${account.id}`
                  );
                }
              }
            }
          }}
        >
          <div
            className="owner_viewall-details"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "",
            }}
          >
            <div className="views-all-user">
              <div className="balance-container w-full flex justify-between">
                {account?.owner_account_balance ? (
                  <div
                    style={{
                      color:
                        account.owner_account_balance < 0
                          ? "#FF5860"
                          : "#4DDD37",
                    }}
                    className="viewall-bank-acc-div"
                  >
                    ₹{" "}
                    {
                      formatTotalBalance(
                        account.owner_account_balance,
                        currency
                      ).amount
                    }
                  </div>
                ) : (
                  <div className="balance-container w-full flex justify-between">
                    <div className="not-available">
                      {flow === "internalTxn"
                        ? "For Internal purpose only"
                        : "Not Available"}
                    </div>
                  </div>
                )}

                {multiSelect || isAssignTagsClicked ? (
                  <>
                    <div className="h-[15px] w-[15px] mr-[10px]">
                      <img
                        src={
                          owneraccountnumberstate?.includes(account.id)
                            ? selectWithGreenFilledCircle
                            : Notselected
                        }
                        alt="white-filled-check"
                      />
                    </div>
                  </>
                ) : (
                  account.connected_banking == "Y" && (
                    <div className="card_balance_last_div">
                      <span
                        style={{
                          display:
                            failedRefreshId.includes(account.id) ||
                            successRefreshId.includes(account.id)
                              ? "block"
                              : "none",
                          position: "relative",
                          marginTop: "-8px",
                        }}
                      >
                        {failedRefreshId.includes(account.id) ? (
                          <div
                            style={{
                              marginTop: "8px",
                              marginLeft: "15px",
                              position: "relative",
                              width: "20px",
                              height: "20px",
                            }}
                          >
                            <img
                              style={{
                                width: "19px",
                                height: "19px",
                                objectFit: "cover",
                              }}
                              src={BalanceErrorIcon}
                              alt="Error"
                            />
                          </div>
                        ) : (
                          <AnimationUpAndDown
                            loader={ownerAccBalanceLoading}
                            currentId={account?.id}
                            previousId={
                              refreshType == false
                                ? false
                                : refreshType == "single"
                                ? prevId
                                : true
                            }
                            currentBalance={account?.owner_account_balance}
                            previousBalance={prevBalance}
                            refreshType={refreshType}
                            allPrevData={refreshAllData[index]?.prevbalance}
                          />
                        )}
                      </span>
                      <div className="card_refresh">
                        {ownerAccBalanceLoading && accId == account.id ? (
                          <BalanceLoader loader={ownerAccBalanceLoading} />
                        ) : !ownerAccBalanceLoading &&
                          !ownerAccBalanceError &&
                          accId == account.id ? (
                          <BalanceLoader
                            loader={ownerAccBalanceLoading}
                            setAccId={setAccId}
                          />
                        ) : (
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                  zIndex: "2147483647 !important",
                                },
                              },
                            }}
                            title="Refresh"
                          >
                            <div
                              style={{
                                cursor:
                                  services?.includes("VAccountBalance") ||
                                  services?.includes("Admin")
                                    ? "pointer"
                                    : "not-allowed",
                                opacity:
                                  services?.includes("VAccountBalance") ||
                                  services?.includes("Admin")
                                    ? "1"
                                    : "0.6",
                              }}
                              onClick={(e) => {
                                if (!isopen && !refreshAll) {
                                  if (
                                    services.includes("VAccountBalance") ||
                                    services?.includes("Admin")
                                  ) {
                                    setFailedRefreshId([]);
                                    setSuccessRefreshId([]);
                                    setAccId(account.id);
                                    setRefreshtype("single");
                                    handleClick(e, account.id);
                                    setPrevId(account.id);
                                    setId(true);
                                    setPrevBalance(
                                      account.owner_account_balance
                                    );
                                    setPrev_Total_balance_state(total_balance);
                                  } else {
                                    e.stopPropagation();
                                    navigate("/feature_not_assigned", {
                                      state: {
                                        unavailableService:
                                          "VAccountBalance,Admin",
                                      },
                                    });
                                  }
                                } else {
                                  e.stopPropagation();
                                }
                              }}
                            >
                              <img
                                className="card_refresh-img"
                                alt="refresh"
                                src={Refreshicon}
                              />
                            </div>
                          </Tooltip>
                        )}
                      </div>
                      <div
                        style={{
                          cursor:
                            services?.includes("VAccountBalance") ||
                            services?.includes("Admin")
                              ? "pointer"
                              : "not-allowed",
                          opacity:
                            services?.includes("VAccountBalance") ||
                            services?.includes("Admin")
                              ? "1"
                              : "0.6",
                        }}
                        onClick={(e) => {
                          if (isMobile) {
                            e.stopPropagation();
                            showToast();
                            return;
                          } else {
                            if (
                              services?.includes("VAccountBalance") ||
                              services?.includes("Admin")
                            ) {
                              if (
                                !isopen &&
                                account.connected_banking === "Y"
                              ) {
                                e.stopPropagation();
                                setAccData(account);
                                setStatementId(account.id);
                                handleViewStatement();
                              }
                            } else {
                              e.stopPropagation();
                              navigate("/feature_not_assigned", {
                                state: {
                                  unavailableService: "VAccountBalance,Admin",
                                },
                              });
                            }
                          }
                        }}
                        className="card_info-div"
                      >
                        {account.connected_banking === "Y" && (
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                    position: "absolute",
                                    top: "-8px",
                                    left: "-10px",
                                    zIndex: "214379",
                                  },
                                  position: "absolute",
                                  top: "-8px",
                                  zIndex: "2147483647 !important",
                                  left: "-10px",
                                },
                              },
                            }}
                            title="View statement"
                          >
                            <img src={Info} alt="Info" />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="viewall-date-div">
                {account?.owner_account_balance ? (
                  formatTotalBalance(account.owner_account_balance, currency)
                    .prefix == "" ? (
                    <p>Balance</p>
                  ) : (
                    <p>
                      Balance - in{" "}
                      {
                        formatTotalBalance(
                          account.owner_account_balance,
                          currency
                        ).prefix
                      }
                    </p>
                  )
                ) : (
                  <>
                    <p>Balance</p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="viewall-balance-div flex justify-between">
            <div>
              <p>{formatAccountNumber(account.bank_acc_no)}</p>
              <p className="account_text">Account number</p>
            </div>
            {account?.tags?.includes("favourite") && (
              <div className="favourite-icon-gridView">
                {/* <img src={yellowStar} alt="favourite" /> */}
              </div>
            )}
            <div className="view-all-card-container  mb-0 pt-1">
              <BeneCardLogo data={account} owner={true} />
            </div>
          </div>
          {!isAssignTagsClicked && (
            <div className="card_onhover">
              <div className="viewall-balance-div ">
                <p className="mr-4 marquee overflow-hidden whitespace-nowrap overflow-ellipsi">
                  <p>{account.name}</p>
                </p>
                <p className="account_text">Account name</p>
              </div>
              <div className="viewall-balance-div ">
                {successRefreshId.includes(account.id) ? (
                  <>
                    <p
                      style={{
                        color:
                          account.increaseOrDecrease === "increased"
                            ? "#4DE659"
                            : account.increaseOrDecrease === "decreased"
                            ? "#FF5860"
                            : "#F9F9F9",
                      }}
                    >
                      {formatTotalBalance(account.difference, currency).amount}
                    </p>
                    <p className="account_text">
                      Balance{" "}
                      {account.increaseOrDecrease == ""
                        ? "increased"
                        : account.increaseOrDecrease}{" "}
                      by{" "}
                      {formatTotalBalance(account.difference, currency)
                        .prefix == ""
                        ? ""
                        : "- in " +
                          formatTotalBalance(account.difference, currency)
                            .prefix}
                    </p>
                  </>
                ) : (
                  <>
                    <p>{account.ifsc_code}</p>
                    <p className="account_text">IFSC code</p>
                  </>
                )}
              </div>
              <div className="viewall-balance-div ">
                <p style={{ textTransform: "lowercase !important" }}>
                  {failedRefreshId.includes(account.id) ? (
                    <p
                      style={{
                        textWrap: "wrap",
                        color: "#FF7F33",
                      }}
                      className="w-[200px]"
                    >
                      Currently unable to fetch balance details. Please try
                      again later.
                    </p>
                  ) : account.owner_account_balance_last_fetched_at_in_words ? (
                    account.owner_account_balance_last_fetched_at_in_words.toLowerCase()
                  ) : (
                    "Not applicable"
                  )}
                </p>
                <p className="account_text">Balance updated</p>
              </div>
              <div className="assign-tags-div">
                <div className="assign-tags-title">
                  <img src={assignTagImg} alt="assign-tag" />
                  <p>Assigned tags</p>
                </div>

                <Tooltip
                  componentsProps={{
                    popper: {
                      sx: {
                        [`& .${tooltipClasses.tooltip}`]: {
                          backgroundColor: (theme) =>
                            theme.palette.common.black,
                        },
                        zIndex: "2147483647 !important",
                      },
                    },
                  }}
                  title={account?.tags?.join(", ")}
                >
                  <div className="assign-tags-list">
                    {
                      account?.tags.length > 0
                        ? account?.tags?.join(", ")
                        : "No tags assigned"

                      // <div className="tags">Tag</div>
                    }
                  </div>
                </Tooltip>
              </div>
            </div>
          )}
        </Box>
      </div>
    </>
  );
};

export default OwnerGridViewComponent;
