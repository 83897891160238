import React, { useEffect, useState } from "react";
import "./index.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { checkUsertoken } from "../../../redux/features/login/login";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import CloseBtn from "../../../assets/CloseIcons/close.svg";
import SecurityCenter from "../../../assets/Graphics/security_center.svg";
import TOTP from "../../../assets/GeneralIcons/TOTP.svg";
import VerificationArrow from "../../../assets/ArrowsIcons/gst_verification_arrow.svg";
import {
  initializeTotp,
  removeInitialError,
} from "../../../redux/features/totp/totp";
import RefreshToast from "../../../components/_utils/RefreshToast";

const SettingsSecurityCenter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const services = useSelector((state) => state.refreshToken.services);
  const userId = JSON.parse(sessionStorage.getItem("response"))?.employees?.id;

  const buttonConfigSecurity = [
    {
      title: "SECURITY CENTER",
      items: [
        {
          label: "Set TOTP (Recommended)",
          icon: TOTP,
          route: "/settings/security_center/Set_Up_Totp",
          requiredService: "PLogin",
        },
      ],
    },
  ];

  const isMobile = useMediaQuery({ maxWidth: 800 });

  const handleNavigate = (type, requiredService, route) => {
    if (isMobile) {
      toast.info("coming soon!", toast_position);
      return;
    }
    if (!services?.includes(requiredService)) {
      dispatch(checkUsertoken());
      navigate("/feature_not_assigned");
    } else {
      dispatch(checkUsertoken());
      RefreshToast("initializeTotp");
      dispatch(removeInitialError());
      dispatch(initializeTotp({ id: userId, route, navigate }));
    }
  };

  const renderSecurityButtons = (buttons) =>
    buttons.map((button, buttonIndex) => {
      const { label, icon, route, requiredService } = button;
      const isLastButton = buttonIndex === buttons.length - 1;
      const hasService = services?.includes(requiredService);

      return (
        <Button
          key={label}
          className="cardSecurityCenter relative"
          style={{
            opacity: hasService || services?.includes("PLogin") ? "1" : "0.5",
            borderBottom: isLastButton
              ? "none"
              : "1px solid var(--Primary_grey)",
            cursor:
              hasService || services?.includes("PLogin")
                ? "pointer"
                : "not-allowed",
          }}
          onClick={() => {
            handleNavigate(label, requiredService, route);
          }}
        >
          <div className="contentDataSecurity">
            <img alt={label} src={icon} />
            <h6 className="text-Primary_white">{label}</h6>
          </div>
          <img
            alt="arrowright"
            src={VerificationArrow}
            className="w-16 md:w-32 lg:w-48"
          />
        </Button>
      );
    });

  return (
    <div className="min-h-screen max-w-[64rem] text-Primary_white flex gap-4 mx-auto pb-5">
      <div
        className="lg:hidden fixed w-full top-0 bg-Blue_6 h-[3.5rem] z-50 flex items-center cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <img src={CloseBtn} alt="Close" className="w-4 h-4 m-5" />
        <h1 className="text-base flex-1 text-Primary_white">Security center</h1>
      </div>

      <div className="flex-1 grid grid-cols-1 lg:grid-cols-1 sm:grid-cols-1 max-w-screen-lg mx-auto mt-[5rem] lg:mt-[9.125] sm:mt-[5.313rem] md:mt-[8.75rem] h-44">
        {buttonConfigSecurity.map((data, index) => (
          <div key={index} className="pb-9">
            <h2 className="text-custom-pink text-base font-semibold mb-5 pl-2.5">
              {data.title}
            </h2>
            <div className="flex flex-col gap-2">
              {renderSecurityButtons(data.items)}
            </div>
          </div>
        ))}
      </div>

      <div className="hidden sm:block sm:w-[15rem] lg:w-[25rem] lg:h-[25rem] md:h-[21.875rem] w-1/3 max-w-screen-lg mx-auto mt-[12.188rem] lg:mt-[12.375rem] sm:mt-[5.313rem] md:mt-[8.75rem] mr-5">
        <img src={SecurityCenter} alt="Settings" className="w-full" />
      </div>
    </div>
  );
};

export default SettingsSecurityCenter;
