import React, { useEffect, useRef, useState } from "react";
import close_with_blue_square from "../../assets/CloseIcons/close_with_blue_square.svg";
import search_white from "../../assets/FilterSearchIcons/search_white.svg";
import close_with_white_circle from "../../assets/CloseIcons/close_with_white_circle.svg";
import arrow_down_white_filled from "../../assets/ArrowsIcons/arrow_down_white_filled.svg";
import not_selected_with_grey_circle from "../../assets/CheckmarksIcons/not_selected_with_grey_circle.svg";
import select_all_with_white_outlined_circle from "../../assets/CheckmarksIcons/select_all_with_white_outlined_circle.svg";
import close_with_blue_circle from "../../assets/CloseIcons/close_with_blue_circle.svg";
import { Box, Tooltip, tooltipClasses } from "@mui/material";
import Selected from "../../assets/CheckmarksIcons/selected_with_green_circle.svg";
import { checkUsertoken } from "../../redux/features/login/login";
import { ownerViewAllAcc } from "../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  AddUserTag,
  getUsers,
  RemoveUserTag,
} from "../../redux/features/Settings/Management/Users";
import { formatAccountNumber } from "../_utils/FormatAccountNumber";
import rejected_white from "../../assets/CloseIcons/rejected_white.svg";
import notags from "../../assets/GeneralIcons/No assigned tags found.svg";
import notagsuser from "../../assets/GeneralIcons/No unassigned tags found user.svg";
import dataloading from "../../assets/LoaderIcons/Loader.gif";
import dataloadingBlue from "../../assets/LoaderIcons/loader 1.gif";

import "./index.css";
import CheckIcon from "../../assets/CheckmarksIcons/check_mark_white.svg";
import clearFilter from "../../assets/CloseIcons/close_with_white_circle.svg";
import arrow_left_white from "../../assets/ArrowsIcons/arrow_left_white.svg";
import arrowup from "../../assets/ArrowsIcons/arrow_up_white.svg";
import arrowdown from "../../assets/ArrowsIcons/arrow_down_white.svg";
import arrowup1 from "../../assets/ArrowsIcons/arrow_up_white.svg";
import arrowdown1 from "../../assets/ArrowsIcons/arrow_down_white.svg";
import { drop, filter, set } from "lodash";
import { json } from "react-router-dom";

function UserTag({
  onClose,
  handleAddTags,

  handleRemoveTags,
  newTags,
  setNewTags,

  AllTags,
  setAllTags,

  setLoading,
  users,
  id,
  ownerDetailData,
  type,
  loading,
  selectedAssignedAccountID,
  setSelectedAssignedAccountID,
  selectedTagsCards,
  selectedAccount,
  setSelectedAccount,
}) {
  const dropdownRef = useRef(null);
  const tagInputRef = useRef("");
  const [tagSearchName, setTagSearchName] = useState("");
  const [message, setMessage] = useState(false);
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);
  const refreshData = useSelector((state) => state.refreshToken.data);
  const [isNewTagsOpaque, setIsNewTagsOpaque] = useState(0);
  const [isAllTagsOpaque, setIsAllTagsOpaque] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const services = useSelector((state) => state.refreshToken.services);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [activeStep, setactiveStep] = useState(0);
  const [selectAll, setselectAll] = useState(false);
  const [selectAll1, setselectAll1] = useState(false);
  const [icon, setIcon] = useState(arrowup);
  const [icon1, setIcon1] = useState(arrowup1);
  const [filterdata, setfilterdata] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [filteredExistingTags, setfilteredExistingTags] = useState([]);
  const [filteredExistingTags1, setfilteredExistingTags1] = useState([]);
  // console.log("False count:", falseCount);
  const { removeOwnerAccTagsLoading } = useSelector(
    (state) => state.ownerAllVerification
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const handleClick = () => {
    setIcon(icon === arrowup ? arrowdown : arrowup);
    // setIsOpen(!isOpen);
  };

  const handleClick1 = () => {
    setIcon1(icon1 === arrowup1 ? arrowdown1 : arrowup1);
  };

  useEffect(() => {
    const handleDropDown = (e) => {
      if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
      } else {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("click", handleDropDown);
    document.addEventListener("mousedown", handleDropDown);
  }, [dropdownRef]);

  const handleAccountSelect = (account, e) => {
    if (e.target.textContent !== selectedAccount) {
      setSelectedAccount(
        e.target.textContent === "All selected accounts"
          ? "All selected accounts"
          : account
      );
      setselectAll1(false);
      setIcon(arrowup);
      setIcon1(arrowup1);
      setselectAll(false);

      let selectedOwnerId;
      if (users) {
        selectedOwnerId = ownerDetailData?.find(
          (owner) => owner?.id === account
        )?.id;
      } else {
        selectedOwnerId = ownerDetailData?.find(
          (owner) => owner?.bank_acc_no === account
        )?.id;
      }
      setSelectedAssignedAccountID(
        e.target.textContent === "All selected accounts" ||
          e.target.textContent === "All selected users"
          ? ""
          : selectedOwnerId
      );
    }
    setIsDropdownOpen(false);
    // onSelectAccount(account);
  };

  const toggleDetails = () => {
    setactiveStep(0);
  };

  const toggleDetails1 = () => {
    setactiveStep(1);
  };

  const newTagsOpacity = isNewTagsOpaque ? "opacity-30" : "opacity-100";
  const allTagsOpacity = isAllTagsOpaque ? "opacity-30" : "opacity-100";

  const result = newTags?.filter((tag) => {
    return tag?.value?.toLowerCase() === tagSearchName?.toLowerCase();
  });

  const result1 = AllTags?.filter((tag) => {
    return tag?.value?.toLowerCase() === tagSearchName?.toLowerCase();
  });

  const InputChange = (e) => {
    const valueChange = e.target.value;
    const limitedValue = valueChange.substring(0, 30);
    // const processChange = limitedValue
    //   .replace(/^\s+/, "")
    //   .replace(/\s{2,}/g, " ")
    //   .toLowerCase();

    const processChange = limitedValue.replace(/\s+/g, "").toLowerCase();
    setTagSearchName(processChange);

    const filteredNewTags = newTags?.filter((tag) =>
      tag?.value?.toLowerCase().includes(processChange)
    );

    const filteredAllTags = AllTags?.filter((tag) =>
      tag?.value?.toLowerCase().includes(processChange)
    );

    if (
      filteredNewTags?.length === 0 &&
      filteredAllTags?.length === 0 &&
      processChange.length > 0
    ) {
      setMessage(true);
    } else {
      setMessage(false);
    }
  };

  const handleClose = () => {
    if (type == "userViewAll") {
      setSelectedAccount("All selected accounts");
      setSelectedAssignedAccountID("");
    }

    setNewTags((prev) => {
      return [...prev].map((result) => {
        return { selected: true, value: result?.value, type: result?.type };
      });
    });
    onClose();
  };

  // useEffect(() => {
  //   setfilteredExistingTags(
  //     newTags?.filter((tag) => {
  //       return (
  //         (tag?.type === "existingTag" || tag?.type === "ownerTags") &&
  //         tag?.value?.toLowerCase().includes(tagSearchName?.toLowerCase())
  //       );
  //     })
  //   );
  // }, [newTags, tagSearchName]);

  const [tagsCountLoader, setTagsCountLoader] = useState(false);
  const [tagsCountLoader1, setTagsCountLoader1] = useState(false);

  useEffect(() => {
    setTagsCountLoader(true);
    setfilteredExistingTags(
      newTags?.filter((tag) => {
        return (
          (tag?.type === "existingTag" || tag?.type === "ownerTags") &&
          tag?.value?.toLowerCase().includes(tagSearchName?.toLowerCase())
        );
      })
    );
    setTimeout(() => {
      setTagsCountLoader(false);
    }, 600);
  }, [newTags, tagSearchName]);

  useEffect(() => {
    setTagsCountLoader1(true);
    setfilteredExistingTags1(
      AllTags?.filter((tag) => {
        return (
          (tag?.type === "Available" || tag?.type === "ownerTags") &&
          tag?.value?.toLowerCase().includes(tagSearchName?.toLowerCase()) &&
          !filteredExistingTags.some(
            (assignedTag) => assignedTag.value === tag.value
          )
        );
      })
    );
    setTimeout(() => {
      setTagsCountLoader1(false);
    }, 600);
  }, [AllTags, tagSearchName]);

  const clearField = () => {
    setTagSearchName("");

    setselectAll(false);

    setselectAll1(false);
  };

  const clearTags = () => {
    const updatedTags = newTags?.map((tag) => ({
      ...tag,
      selected: type,
    }));

    setNewTags(updatedTags);
    setselectAll(false);
    setIcon(arrowup);
  };

  const clearTags1 = () => {
    const updatedTags = AllTags?.map((tag) => ({
      ...tag,
      selected: type,
    }));

    setAllTags(updatedTags);
    setselectAll1(false);
    setIcon1(arrowup1);
  };

  const handleExistingTag = (selectedTag) => {
    const updatedTags = newTags?.map((tag) => {
      if (tag.value === selectedTag && tag.type !== "newTag") {
        return { ...tag, selected: !tag.selected };
      } else {
        return tag;
      }
    });

    setNewTags(updatedTags);
    const allSelected = updatedTags.every((tag) => !tag.selected);

    setselectAll(allSelected);
  };

  const selectedValue = () => {
    if (tagSearchName.trim().length > 0) {
      const details = JSON.parse(JSON.stringify(newTags))?.filter((tag) =>
        filteredExistingTags?.some(
          (filteredTag) => filteredTag.value === tag.value
        )
      );

      const updatedTags = JSON.parse(JSON.stringify(newTags))?.map((tag) => {
        if (
          filteredExistingTags?.some(
            (filteredTag) => filteredTag.value === tag.value
          )
        ) {
          return { ...tag, selected: type };
        } else {
          return { ...tag };
        }
      });
      setNewTags(updatedTags);
    } else {
      const updatedTags = newTags?.map((tag) => ({
        ...tag,
        selected:
          tagSearchName.trim().length > 0
            ? filteredExistingTags?.value
              ? false
              : true
            : type,
      }));

      setNewTags(updatedTags);
    }

    setselectAll((prev) => !prev);
  };

  const selectedValue1 = () => {
    if (tagSearchName.trim().length > 0) {
      const details = JSON.parse(JSON.stringify(newTags))?.filter((tag) =>
        filteredExistingTags1?.some(
          (filteredTag) => filteredTag.value === tag.value
        )
      );

      const updatedTags = JSON.parse(JSON.stringify(AllTags))?.map((tag) => {
        if (
          filteredExistingTags1?.some(
            (filteredTag) => filteredTag.value === tag.value
          )
        ) {
          return { ...tag, selected: type };
        } else {
          return { ...tag };
        }
      });
      setAllTags(updatedTags);
    } else {
      const updatedTags = AllTags?.map((tag) => ({
        ...tag,
        selected:
          tagSearchName.trim().length > 0
            ? filteredExistingTags1?.value
              ? false
              : true
            : type,
      }));

      setAllTags(updatedTags);
    }

    setselectAll1((prev) => !prev);
  };

  const handleExistingTag1 = (selectedTag) => {
    const updatedTags = AllTags.map((tag, id) => {
      if (tag?.value === selectedTag) {
        return { ...tag, selected: !tag.selected };
      } else {
        return tag;
      }
    });
    setAllTags(updatedTags);
    const allSelected = updatedTags.every((tag) => !tag.selected);

    setselectAll1(allSelected);
    setIsAllTagsOpaque(!isAllTagsOpaque);
    setIsNewTagsOpaque(!isNewTagsOpaque);
  };

  const handleNewTag = (item) => {
    const tagExists = newTags?.some(
      (tag) => tag?.value?.toLowerCase() === tagSearchName?.toLowerCase()
    );

    if (tagExists) {
    } else {
      handleAddTags(item);
      setTagSearchName("");
    }
  };

  const handleSelectandDeselect = (selectedTag) => {
    const updatedTags = newTags?.reduce((acc, res) => {
      if (res.value === selectedTag) {
        return [...acc, { ...res, selected: true }];
      } else {
        return [...acc, res];
      }
    }, []);

    const updatedTags1 = newTags?.map((acc) => {
      return {
        data: !acc?.selected,
      };
    });

    const trueCount = updatedTags1?.reduce(
      (count, item) => count + (item.data ? 1 : 0),
      0
    );

    if (trueCount === 1) {
      setIcon(arrowup);
    }

    setNewTags(updatedTags);
    setselectAll(false);
  };

  const handleSelectandDeselect1 = (selectedTag) => {
    const updatedTags = AllTags?.reduce((acc, res) => {
      if (res.value === selectedTag) {
        return [...acc, { ...res, selected: true }];
      } else {
        return [...acc, res];
      }
    }, []);

    const updatedTags1 = AllTags?.map((acc) => {
      return {
        data: !acc?.selected,
      };
    });

    const trueCount = updatedTags1?.reduce(
      (count, item) => count + (item.data ? 1 : 0),
      0
    );

    if (trueCount === 1) {
      setIcon1(arrowup1);
    }

    setAllTags(updatedTags);
    setselectAll1(false);
  };

  const HandleUserAvailTags = async (item) => {
    // setLoading(true);
    try {
      dispatch(checkUsertoken());

      let response = await dispatch(
        AddUserTag({
          user_id: refreshData?.employees?.id,
          account_tags: item,
        })
      );

      if (response?.payload?.status === true) {
        HandleAllTags();

        setAllTags((prev) => [...prev, response?.meta?.arg?.account_tags]);
      } else {
        setLoading(false);
        toast.error(response?.payload?.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (users) {
      handleuserAll();
    } else {
      HandleAllTags();
    }
  }, []);

  const handleuserAll = async () => {
    setLoading(true);

    try {
      dispatch(checkUsertoken());
      let response = await dispatch(getUsers({ page_no: 1, page_size: 50 }));
      if (response?.payload?.status === true) {
        // setAllTags(response?.payload?.summary?.available_tags);
        // getOwnerDetaildata();

        const data = response?.payload?.available_tags?.map((res) => {
          return { selected: true, value: res, type: "Available" };
        });
        if (type !== "userViewAll" || type !== "ownerViewAll") {
          setAllTags(data);
        }
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response?.payload?.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const HandleAllTags = async () => {
    // setLoading(true);

    try {
      dispatch(checkUsertoken());
      let response = await dispatch(
        ownerViewAllAcc({ page_no: 1, page_size: 50 })
      );

      if (response?.payload?.status === true) {
        // setAllTags(response?.payload?.summary?.available_tags);
        // getOwnerDetaildata();

        const data = response?.payload?.summary?.available_tags?.map((res) => {
          return { selected: true, value: res, type: "Available" };
        });

        if (type !== "ownerViewAll") {
          setAllTags(data);
        }
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response?.payload?.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleUnassign = async () => {
    const deselectedTags = newTags.filter((tag) => tag.selected);

    const remainingTags = newTags.filter((tag) => !tag.selected);
    const data = remainingTags.map((tag) => {
      return tag.value;
    });

    await handleRemoveTags(data);
    if (!users) {
      await HandleAllTags();
    }

    if (selectAll) {
      setselectAll(false);
    }
    // setLoading(true);
    setIcon(arrowup);
  };

  const handleAssign = () => {
    const selectedTags = AllTags?.filter((tag) => tag.selected);
    const remainingTags = AllTags?.filter((tag) => !tag.selected);

    const data1 = remainingTags?.map((tag) => {
      return tag.value;
    });

    handleAddTags({ data1, type: "newAss" });

    setAllTags(selectedTags);

    if (selectAll1) {
      setselectAll1(false);
    }
    setIcon1(arrowup1);
  };

  const isAnyTagDeselected = newTags?.some((tag) => tag.selected === false);
  const isAnyTagDeselectedUnassign = AllTags?.some(
    (tag) => tag.selected === false
  );

  const handleKeyPress = (e) => {
    if (users && !services?.includes("CTag")) {
      return;
    } else if (!users && !services?.includes("COwnerTag")) {
      return;
    } else {
      if (e.key === "Enter") {
        const inputValue = e.target.value;
        if (inputValue.trim()) {
          handleNewTag(inputValue);
        }
      }
    }
  };

  return (
    <>
      <div
        className="flex  w-full h-full justify-center items-center m-auto top-1/2 left-1/2 fixed z-[9999] overflow-y-auto overflow-x-hidden"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <div
          className={` flex flex-col relative bg-[#1D3A6D]  ${
            isMobile
              ? " w-[100%] h-[100%] overflow-y-auto "
              : "w-[1024px] h-auto rounded-[20px] "
          }  `}
          style={{ scrollbarWidth: isMobile ? "none" : "" }}
          onClick={(e) => e.stopPropagation()}
        >
          {isMobile && (
            <>
              <div className=" h-[56px] fixed z-[9999999] w-[100%] bg-[#3A5686]">
                <div className=" flex">
                  <div className=" p-[20px]" onClick={handleClose}>
                    <img
                      src={arrow_left_white}
                      className=" w-[16px] h-[20.36px] cursor-pointer"
                    />
                  </div>
                  <div className=" flex flex-col items-center justify-center ">
                    <p className="font-sans  flex items-center text-[14px] text-[#f9f9f9]">
                      Assign/Unassign Tags
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className={` ${isMobile ? "h-[100%] mt-[56px]" : ""}`}>
            {isMobile ? (
              <>
                <div className=" w-full relative h-[45px] mt-[20px]   bg-[#667CA3] mr-[20px] ">
                  <div
                    ref={dropdownRef}
                    className=" w-full  flex items-center bg-[#667CA3] rounded-[10px] top-0 left-0 "
                    style={{
                      zIndex: "99999",
                      height: isDropdownOpen ? "" : "45px",
                      position: isDropdownOpen ? "absolute" : "relative",
                      borderRadius:
                        (type === "ownerViewAll" || type == "userViewAll") &&
                        isDropdownOpen
                          ? "10px"
                          : "10px",
                    }}
                  >
                    {type === "ownerDetails" || ownerDetailData?.length == 1 ? (
                      <>
                        <div className=" w-full opacity-50 flex items-center px-[10px]">
                          <p className="font-sans font-[400px] text-[14px] ml-[10px] w-full text-[#f9f9f9] cursor-default ">
                            {users
                              ? users
                              : formatAccountNumber(
                                  Array.isArray(ownerDetailData)
                                    ? ownerDetailData[0]?.bank_acc_no
                                    : ownerDetailData?.bank_acc_no
                                )}
                          </p>
                          <img
                            src={arrow_down_white_filled}
                            className=" w-[16px] h-[16px] mr-[10px]"
                          />
                        </div>
                      </>
                    ) : (type === "ownerViewAll" || type == "userViewAll") &&
                      ownerDetailData?.length > 1 ? (
                      <div
                        ref={dropdownRef}
                        style={{
                          position: "relative",
                          width: "100%",
                        }}
                      >
                        <div
                          className="dropdown-div-responsive"
                          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                          style={{
                            border:
                              (type === "ownerViewAll" ||
                                type === "userViewAll") &&
                              isDropdownOpen
                                ? "1px solid #FF5860"
                                : "",
                            width:
                              selectedAssignedAccountID !== "" &&
                              !isDropdownOpen
                                ? "96%"
                                : "100%",
                          }}
                        >
                          <Tooltip
                            componentsProps={{
                              popper: {
                                placement: "bottom",
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                  zIndex: "2147483647 !important",
                                },
                              },
                            }}
                            title={selectedAccount}
                          >
                            <p className="font-sans font-[400px] text-[14px] w-full truncate text-[#f9f9f9]">
                              {isDropdownOpen
                                ? "Select type"
                                : selectedAccount !== "All selected accounts"
                                ? selectedAccount
                                : users
                                ? "All selected users"
                                : "All selected accounts"}
                            </p>
                          </Tooltip>
                          {!isDropdownOpen &&
                          selectedAccount !== "" &&
                          selectedAssignedAccountID !== "" ? (
                            <div
                              className="dropdown-img-div flex-row"
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={rejected_white}
                                alt="close"
                                className=" ml-[18px]  w-[16px] h-[16px] cursor-pointer "
                                onClick={(e) => {
                                  e.stopPropagation();

                                  setIsDropdownOpen(false);
                                  setSelectedAssignedAccountID("");
                                  setSelectedAccount("All selected accounts");
                                }}
                              />
                              <div className=" border-r-[1px] h-[16px] mx-[10px] border-[#102C5D]"></div>
                              <img
                                src={arrow_down_white_filled}
                                className="  w-[16px] h-[16px]  "
                              />
                            </div>
                          ) : (
                            <img
                              style={{
                                transform: isDropdownOpen
                                  ? "rotate(180deg)"
                                  : "",
                              }}
                              src={arrow_down_white_filled}
                              className="  w-[16px] h-[16px] mr-[10px] cursor-pointer "
                            />
                          )}
                        </div>
                        {isDropdownOpen && (
                          <div className="dropdown-content-responsive">
                            <div
                              className="dropdown-item"
                              onClick={(e) =>
                                handleAccountSelect("All selected accounts", e)
                              }
                              style={{
                                backgroundColor:
                                  selectedAccount === "All selected accounts"
                                    ? "#3A5686"
                                    : "",
                                borderRadius:
                                  selectedAccount === "All selected accounts"
                                    ? "5px"
                                    : "",
                              }}
                            >
                              {users ? (
                                <p>All selected users</p>
                              ) : (
                                <p>All selected accounts</p>
                              )}

                              {selectedAccount === "All selected accounts" && (
                                <img src={CheckIcon} alt="check mark" />
                              )}
                            </div>
                            {users ? (
                              <div>
                                {ownerDetailData.map((account, index) => (
                                  <div
                                    key={index}
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      handleAccountSelect(
                                        account.first_name +
                                          " " +
                                          account.last_name,
                                        e
                                      );
                                    }}
                                    style={{
                                      backgroundColor:
                                        selectedAccount ===
                                        account.first_name +
                                          " " +
                                          account.last_name
                                          ? "#3A5686"
                                          : "",
                                      borderRadius:
                                        selectedAccount ===
                                        account.first_name +
                                          " " +
                                          account.last_name
                                          ? "5px"
                                          : "",
                                    }}
                                  >
                                    <p className="truncate">
                                      <Tooltip
                                        componentsProps={{
                                          popper: {
                                            placement: "bottom",
                                            sx: {
                                              [`& .${tooltipClasses.tooltip}`]:
                                                {
                                                  backgroundColor: (theme) =>
                                                    theme.palette.common.black,
                                                },
                                              zIndex: "2147483647 !important",
                                            },
                                          },
                                        }}
                                        title={
                                          account.first_name +
                                          " " +
                                          account.last_name
                                        }
                                      >
                                        {account.first_name +
                                          " " +
                                          account.last_name}
                                      </Tooltip>
                                    </p>
                                    {selectedAccount ===
                                      account.first_name +
                                        " " +
                                        account.last_name && (
                                      <img src={CheckIcon} alt="check mark" />
                                    )}
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div>
                                {ownerDetailData.map((account, index) => (
                                  <div
                                    key={index}
                                    className="dropdown-item"
                                    onClick={(e) =>
                                      handleAccountSelect(
                                        account.bank_acc_no,
                                        e
                                      )
                                    }
                                    style={{
                                      backgroundColor:
                                        selectedAccount === account.bank_acc_no
                                          ? "#3A5686"
                                          : "",
                                      borderRadius:
                                        selectedAccount === account.bank_acc_no
                                          ? "5px"
                                          : "",
                                    }}
                                  >
                                    <p>{account.bank_acc_no}</p>
                                    {selectedAccount ===
                                      account.bank_acc_no && (
                                      <img src={CheckIcon} alt="check mark" />
                                    )}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <>
                        <p className="font-sans font-[400px] text-[12px] w-[174px] text-[#f9f9f9]">
                          All selected users
                        </p>
                        <img
                          src={arrow_down_white_filled}
                          className=" ml-[13px] w-[9px] h-[9px] cursor-pointer "
                        />
                      </>
                    )}
                  </div>
                </div>

                <div className="bg-[#667CA3] h-[49px] flex items-center w-full  pl-[20px] py-[18px]  mt-[20px]">
                  <img src={search_white} className="w-[14px] h-[14.38px] " />
                  <input
                    ref={tagInputRef}
                    placeholder={
                      users && !services?.includes("CTag")
                        ? "Search tag"
                        : "Search / Enter new tag"
                    }
                    className="  text-[#dadada] h-[19px] ml-[10px] bg-[#667CA3] font-sans  text-[16px] font-medium outline-none  w-full "
                    onClick={() => tagInputRef.current.focus()}
                    onChange={InputChange}
                    onKeyDown={handleKeyPress}
                    value={tagSearchName}
                  />
                  <div
                    className={` flex items-center mr-[22px] justify-center cursor-pointer  ${
                      // result?.length === 0 &&
                      // result1?.length === 0 &&
                      tagSearchName !== ""
                        ? " cursor-pointer  "
                        : " cursor-default "
                    }  h-[3.75rem]`}
                    onClick={clearField}
                  >
                    <div
                      className={` ${
                        tagSearchName !== "" ? "  opacity-100  " : " opacity-30"
                      } `}
                    >
                      <img
                        src={close_with_white_circle}
                        className="w-[18px] h-[15.38px]   "
                      />
                    </div>
                    {/* <div
                      className={`ml-[4px] font-sans} ${
                        tagSearchName !== ""
                          ? " text-[#f9f9f9] opacity-100  text-[14px]  font-medium "
                          : " text-[#f9f9f9]  text-[14px] opacity-30"
                      }    `}
                    >
                      Clear
                    </div> */}
                  </div>
                </div>

                <div className="">
                  <div
                    onClick={() => {
                      if (
                        !(
                          result?.length === 0 &&
                          result1?.length === 0 &&
                          tagSearchName !== ""
                        )
                      ) {
                        return;
                      }
                      handleNewTag(tagSearchName);
                    }}
                    className={` h-[40px] w-full flex items-center justify-center ${
                      result?.length === 0 &&
                      result1?.length === 0 &&
                      tagSearchName !== ""
                        ? "bg-Button_green cursor-pointer hover:opacity-60 "
                        : "bg-Secondary_grey cursor-default"
                    } `}
                  >
                    <div>
                      <p
                        className={` font-sans font-normal ${
                          isMobile ? "text-Font_14" : "text-Font_10 "
                        }  text-Primary_white`}
                      >
                        {" "}
                        Assign new tag
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex h-[39px] mt-[20px]">
                  <button
                    className={`w-1/2 flex items-center justify-center ${
                      activeStep === 0
                        ? "bg-Primary_white text-Blue_5 font-semibold"
                        : "bg-Blue_5 text-Primary_grey font-normal"
                    }`}
                    onClick={toggleDetails}
                  >
                    Assigned -
                    {tagsCountLoader ? (
                      <span className={`inline-flex  items-center ml-2`}>
                        <img
                          src={activeStep === 0 ? dataloadingBlue : dataloading}
                          className={`w-[18px] h-[18px]  flex items-center `}
                        />
                      </span>
                    ) : (
                      ` ${filteredExistingTags?.length}`
                    )}
                    {/* {filteredExistingTags?.length == 0
                      ? ""
                      : ` - ${filteredExistingTags?.length}`} */}
                  </button>
                  <button
                    className={`w-1/2 flex items-center justify-center ${
                      activeStep === 1
                        ? "bg-Primary_white text-Blue_5 font-semibold"
                        : "bg-Blue_5 text-Primary_grey font-normal"
                    }`}
                    onClick={toggleDetails1}
                  >
                    Unassigned -
                    {tagsCountLoader1 ? (
                      <span className={`inline-flex  items-center ml-2`}>
                        <img
                          src={activeStep === 1 ? dataloadingBlue : dataloading}
                          className={`w-[18px] h-[18px]  flex items-center `}
                        />
                      </span>
                    ) : (
                      ` ${filteredExistingTags1?.length}`
                    )}
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className=" flex w-full pt-[20px] px-[20px] ">
                  <div className=" w-full">
                    <p className=" font-sans font-semibold text-Font_16 text-Primary_grey">
                      Assign/Unassign tags:
                    </p>

                    <p className="font-sans font-[400px] text-[12px] mt-[2px] text-[#f9f9f9]">
                      {users
                        ? "Tagged users will gain access to view only owner accounts that share the same tags."
                        : "Tagged owner accounts will be visible to the users who share   the same tags."}
                    </p>
                  </div>
                  <div onClick={handleClose}>
                    <img
                      src={close_with_blue_square}
                      className=" w-[30px] h-[30px] cursor-pointer rounded-[10px] hover:bg-[#667CA3] hover:opacity-60 hover:w-[30px] hover:h-[30px]"
                    />
                  </div>
                </div>
                <div className=" flex w-full px-[20px] pb-[20px]  mt-[20px]">
                  <div className=" w-[216px] relative h-[36px]  bg-[#667CA3] mr-[20px] rounded-[10px]">
                    <div
                      ref={dropdownRef}
                      className=" w-full  flex items-center bg-[#667CA3] rounded-[10px] top-0 left-0 "
                      style={{
                        zIndex: "9999999",
                        height: isDropdownOpen ? "134px" : "36px",
                        position: isDropdownOpen ? "absolute" : "relative",
                        borderRadius:
                          (type === "ownerViewAll" || type == "userViewAll") &&
                          isDropdownOpen
                            ? "10px"
                            : "10px",

                        border:
                          (type === "ownerViewAll" || type === "userViewAll") &&
                          isDropdownOpen
                            ? "1px solid #FF5860"
                            : "",
                      }}
                    >
                      {type === "ownerDetails" ||
                      ownerDetailData?.length == 1 ? (
                        <>
                          <div className=" opacity-50 flex items-center px-[10px]">
                            <p className="font-sans font-[400px] text-[12px] w-[174px] text-[#f9f9f9] cursor-default ">
                              {users
                                ? users
                                : formatAccountNumber(
                                    Array.isArray(ownerDetailData)
                                      ? ownerDetailData[0]?.bank_acc_no
                                      : ownerDetailData?.bank_acc_no
                                  )}
                            </p>
                            <img
                              src={rejected_white}
                              className=" w-[9px] h-[9px]"
                            />
                            <div className=" border-r-[1px] h-[16px] mx-[10px] border-[#102C5D]"></div>
                            <img
                              src={arrow_down_white_filled}
                              className="  w-[9px] h-[9px]  "
                            />
                          </div>
                        </>
                      ) : (type === "ownerViewAll" || type == "userViewAll") &&
                        ownerDetailData?.length > 1 ? (
                        <div
                          ref={dropdownRef}
                          style={{
                            position: "relative",
                          }}
                        >
                          <div
                            className="dropdown-div"
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                            style={{
                              width:
                                selectedAssignedAccountID !== "" &&
                                !isDropdownOpen
                                  ? "82%"
                                  : "100%",
                            }}
                          >
                            {users ? (
                              <>
                                <Tooltip
                                  componentsProps={{
                                    popper: {
                                      placement: "bottom",
                                      sx: {
                                        [`& .${tooltipClasses.tooltip}`]: {
                                          backgroundColor: (theme) =>
                                            theme.palette.common.black,
                                        },
                                        zIndex: "2147483647 !important",
                                      },
                                    },
                                  }}
                                  title={
                                    ownerDetailData?.find(
                                      (owner) => owner?.id === selectedAccount
                                    )?.first_name
                                      ? ownerDetailData?.find(
                                          (owner) =>
                                            owner?.id === selectedAccount
                                        )?.first_name +
                                        " " +
                                        ownerDetailData?.find(
                                          (owner) =>
                                            owner?.id === selectedAccount
                                        )?.last_name
                                      : "All selected users"
                                  }
                                >
                                  <p className="font-sans font-[400px] text-[12px] w-[174px] px-[10px] truncate text-[#f9f9f9]">
                                    {isDropdownOpen
                                      ? "Select type"
                                      : selectedAccount !==
                                        "All selected accounts"
                                      ? ownerDetailData?.find(
                                          (owner) =>
                                            owner?.id === selectedAccount
                                        )?.first_name +
                                        " " +
                                        ownerDetailData?.find(
                                          (owner) =>
                                            owner?.id === selectedAccount
                                        )?.last_name
                                      : users
                                      ? "All selected users"
                                      : "All selected accounts"}
                                  </p>
                                </Tooltip>
                              </>
                            ) : (
                              <>
                                <Tooltip
                                  componentsProps={{
                                    popper: {
                                      placement: "bottom",
                                      sx: {
                                        [`& .${tooltipClasses.tooltip}`]: {
                                          backgroundColor: (theme) =>
                                            theme.palette.common.black,
                                        },
                                        zIndex: "2147483647 !important",
                                      },
                                    },
                                  }}
                                  title={selectedAccount}
                                >
                                  <p className="font-sans font-[400px] text-[12px] w-[174px] truncate text-[#f9f9f9]">
                                    {isDropdownOpen
                                      ? "Select type"
                                      : selectedAccount !==
                                        "All selected accounts"
                                      ? selectedAccount
                                      : users
                                      ? "All selected users"
                                      : "All selected accounts"}
                                  </p>
                                </Tooltip>
                              </>
                            )}
                            {!isDropdownOpen &&
                            selectedAccount !== "" &&
                            selectedAssignedAccountID !== "" ? (
                              <div
                                className="dropdown-img-div flex-row"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={rejected_white}
                                  alt="close"
                                  className=" ml-[18px]  w-[9px] h-[9px] cursor-pointer "
                                  onClick={(e) => {
                                    e.stopPropagation();

                                    setselectAll1(false);
                                    setselectAll(false);
                                    setIsDropdownOpen(false);
                                    setSelectedAssignedAccountID("");
                                    setSelectedAccount("All selected accounts");
                                  }}
                                />
                                <div className=" border-r-[1px] h-[16px] mx-[10px] border-[#102C5D]"></div>
                                <img
                                  src={arrow_down_white_filled}
                                  className="  w-[9px] h-[9px]  "
                                />
                              </div>
                            ) : (
                              <img
                                style={{
                                  transform: isDropdownOpen
                                    ? "rotate(180deg)"
                                    : "",
                                }}
                                src={arrow_down_white_filled}
                                className=" ml-[13px] w-[9px] h-[9px] cursor-pointer "
                              />
                            )}
                          </div>
                          {isDropdownOpen && (
                            <div className="dropdown-content">
                              <div
                                className={`dropdown-item1 ${
                                  selectedAccount === "All selected accounts" &&
                                  "bg-[#3A5686] hover:bg-[#2E456B]"
                                } hover:bg-[#8596B5] rounded-[5px]`}
                                onClick={(e) =>
                                  handleAccountSelect(
                                    "All selected accounts",
                                    e
                                  )
                                }
                                style={{
                                  // backgroundColor:
                                  //   selectedAccount === "All selected accounts"
                                  //     ? "#3A5686"
                                  //     : "",
                                  borderRadius:
                                    selectedAccount === "All selected accounts"
                                      ? "5px"
                                      : "",
                                }}
                              >
                                {users ? (
                                  <Tooltip
                                    componentsProps={{
                                      popper: {
                                        placement: "bottom",
                                        sx: {
                                          [`& .${tooltipClasses.tooltip}`]: {
                                            backgroundColor: (theme) =>
                                              theme.palette.common.black,
                                          },
                                          zIndex: "2147483647 !important",
                                        },
                                      },
                                    }}
                                    title={"All selected users"}
                                  >
                                    <p>All selected users</p>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    componentsProps={{
                                      popper: {
                                        placement: "bottom",
                                        sx: {
                                          [`& .${tooltipClasses.tooltip}`]: {
                                            backgroundColor: (theme) =>
                                              theme.palette.common.black,
                                          },
                                          zIndex: "2147483647 !important",
                                        },
                                      },
                                    }}
                                    title={"All selected accounts"}
                                  >
                                    <p>All selected accounts</p>
                                  </Tooltip>
                                )}

                                {selectedAccount ===
                                  "All selected accounts" && (
                                  <img src={CheckIcon} alt="check mark" />
                                )}
                              </div>
                              {users ? (
                                <div>
                                  {ownerDetailData.map((account, index) => (
                                    <div
                                      key={index}
                                      className={`dropdown-item ${
                                        selectedAccount === account.id &&
                                        "bg-[#3A5686] hover:bg-[#2E456B]"
                                      } hover:bg-[#8596B5]`}
                                      onClick={(e) => {
                                        handleAccountSelect(account.id, e);
                                      }}
                                      style={{
                                        // backgroundColor:
                                        //   selectedAccount === account.id
                                        //     ? "#3A5686"
                                        //     : "",
                                        borderRadius:
                                          selectedAccount === account.id
                                            ? "5px"
                                            : "",
                                      }}
                                    >
                                      <p className="truncate">
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              placement: "bottom",
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                                zIndex: "2147483647 !important",
                                              },
                                            },
                                          }}
                                          title={
                                            account.first_name +
                                            " " +
                                            account.last_name
                                          }
                                        >
                                          {account.first_name +
                                            " " +
                                            account.last_name}
                                        </Tooltip>
                                      </p>
                                      {selectedAccount === account.id && (
                                        <img src={CheckIcon} alt="check mark" />
                                      )}
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <div>
                                  {ownerDetailData.map((account, index) => (
                                    <div
                                      key={index}
                                      className={`dropdown-item ${
                                        selectedAccount ===
                                          account.bank_acc_no &&
                                        "bg-[#3A5686] hover:bg-[#2E456B]"
                                      } hover:bg-[#8596B5]`}
                                      // className="dropdown-item"
                                      onClick={(e) =>
                                        handleAccountSelect(
                                          account.bank_acc_no,
                                          e
                                        )
                                      }
                                      style={{
                                        // backgroundColor:
                                        //   selectedAccount ===
                                        //   account.bank_acc_no
                                        //     ? "#3A5686"
                                        //     : "",
                                        borderRadius:
                                          selectedAccount ===
                                          account.bank_acc_no
                                            ? "5px"
                                            : "",
                                      }}
                                    >
                                      <p>{account.bank_acc_no}</p>
                                      {selectedAccount ===
                                        account.bank_acc_no && (
                                        <img src={CheckIcon} alt="check mark" />
                                      )}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      ) : (
                        <>
                          <p className="font-sans font-[400px] text-[12px] w-[174px] text-[#f9f9f9]">
                            All selected users
                          </p>
                          <img
                            src={arrow_down_white_filled}
                            className=" ml-[13px] w-[9px] h-[9px] cursor-pointer "
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className=" w-[741px] h-[36px] flex items-center rounded-[10px] pl-[10px] py-[5.29px] bg-[#314D7E]">
                    <div
                      className={`${
                        users && !services?.includes("CTag")
                          ? "w-[718px]"
                          : !users && !services?.includes("COwnerTag")
                          ? "w-[718px]"
                          : "w-[622px]"
                      } flex items-center`}
                    >
                      <img
                        src={search_white}
                        className="w-[14px] h-[14.38px] "
                      />
                      <input
                        ref={tagInputRef}
                        placeholder={
                          users && !services?.includes("CTag")
                            ? "Search tag"
                            : !users && !services?.includes("COwnerTag")
                            ? "Search tag"
                            : "Search / Enter new tag"
                        }
                        className=" bg-[#314D7E] text-[#dadada] text-[12px] font-[400px] outline-none ml-[10px] w-full "
                        onClick={() => tagInputRef.current.focus()}
                        onChange={InputChange}
                        onKeyDown={handleKeyPress}
                        value={tagSearchName}
                      />
                      <img
                        src={close_with_white_circle}
                        className="w-[14px] h-[14.38px]  cursor-pointer  "
                        onClick={clearField}
                      />
                    </div>
                    <div
                      style={{
                        display:
                          users && !services?.includes("CTag")
                            ? "none"
                            : !users && !services?.includes("COwnerTag")
                            ? "none"
                            : "flex",
                      }}
                      className=" border-r-[1px] border-[#1D3A6D] h-[25.41px] mx-[8px]"
                    ></div>

                    <div
                      style={{
                        display:
                          users && !services?.includes("CTag")
                            ? "none"
                            : !users && !services?.includes("COwnerTag")
                            ? "none"
                            : "flex",
                      }}
                      className={`w-[86px] h-[25.41px]  flex items-center pl-[9px] py-[5.29px] rounded-[5px] ${
                        result?.length === 0 &&
                        result1?.length === 0 &&
                        tagSearchName !== ""
                          ? "bg-Button_green cursor-pointer hover:opacity-60 "
                          : "bg-Secondary_grey cursor-default"
                      } `}
                      onClick={() => {
                        if (
                          !(
                            result?.length === 0 &&
                            result1?.length === 0 &&
                            tagSearchName !== ""
                          )
                        ) {
                          return;
                        }
                        handleNewTag(tagSearchName);
                      }}
                    >
                      <p className="font-sans font-normal text-Font_10  text-Primary_white">
                        {" "}
                        Assign new tag
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className={` ${isMobile ? "h-full  pb-[20px]" : ""}`}>
              <div
                className={` flex  ${
                  isMobile ? "w-[100%] h-[100%]" : " w-[1024px]"
                }  `}
              >
                <div
                  className={` bg-[#3A5686]  ${
                    isMobile
                      ? " w-[100%] pb-[40px]"
                      : "w-[512px] rounded-bl-[20px] "
                  }  relative  border-r-[1px] border-[#102C5D]`}
                  style={{
                    display: activeStep !== 0 && isMobile && "none",
                  }}
                >
                  <div
                    onClick={(e) => e.stopPropagation()}
                    style={{
                      display:
                        users && !services?.includes("DTag")
                          ? "block"
                          : !users && !services?.includes("DOwnerTag")
                          ? "block"
                          : AllTags?.some((tag) => {
                              return tag.selected == false;
                            })
                          ? "block"
                          : "none",
                    }}
                    className="absolute w-full h-full bg-black bg-opacity-30  z-[99999]"
                  ></div>
                  <div
                    className={`" px-[20px] pt-[10px] pb-[15px] ${
                      isMobile ? "" : " min-h-[66px]"
                    } `}
                  >
                    {!isMobile && (
                      <>
                        <div className=" flex justify-between items-center ">
                          <p className="font-sans font-semibold flex items-center text-Font_14 text-Primary_grey">
                            Assigned tags -
                            {tagsCountLoader ? (
                              <span className="inline-flex  items-center ml-2">
                                <img
                                  src={dataloading}
                                  className="w-[18px] h-[18px] mt-1 flex items-center "
                                />
                              </span>
                            ) : (
                              ` ${filteredExistingTags?.length}`
                            )}
                          </p>
                          <div
                            className={` flex items-center ${
                              icon === arrowdown
                                ? " opacity-25 pointer-events-none"
                                : "opacity-100"
                            } ${
                              filteredExistingTags?.length !== 0
                                ? ""
                                : "pointer-events-none opacity-30"
                            }`}
                          >
                            <p className=" font-sans font-semibold text-Font_12 text-Primary_grey">
                              Select all
                            </p>
                            <div
                              className="w-[26px] ml-[10px] h-[26px] cursor-pointer rounded-full flex items-center justify-center bg-Blue_5"
                              onClick={() => {
                                selectedValue((type = selectAll));
                              }}
                            >
                              {selectAll &&
                              filteredExistingTags?.length !== 0 ? (
                                <img
                                  className=" w-[16px] h-[16px]"
                                  src={Selected}
                                  alt=""
                                />
                              ) : (
                                <img
                                  className=" w-[16px] h-[16px]"
                                  src={select_all_with_white_outlined_circle}
                                  alt=""
                                />
                              )}
                              {/* <img
                                className="   w-[16px] h-[16px]"
                                src={select_all_with_white_outlined_circle}
                              /> */}
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <div
                      style={{
                        display: isMobile ? "block" : "none",
                      }}
                    >
                      <div className=" flex  items-center justify-between">
                        <p className=" font-sans font-semibold text-Font_12 text-Primary_grey">
                          Select all
                        </p>
                        <div
                          className={`w-[26px] ml-[10px] h-[26px] cursor-pointer  ${
                            icon === arrowdown
                              ? " opacity-25 pointer-events-none"
                              : "opacity-100"
                          } rounded-full flex items-center ${
                            filteredExistingTags?.length !== 0
                              ? ""
                              : "pointer-events-none opacity-30"
                          } justify-center bg-Blue_5`}
                          onClick={() => {
                            selectedValue((type = selectAll));
                          }}
                        >
                          {selectAll && filteredExistingTags?.length !== 0 ? (
                            <img
                              className=" w-[16px] h-[16px]"
                              src={Selected}
                              alt=""
                            />
                          ) : (
                            <img
                              className=" w-[16px] h-[16px]"
                              src={select_all_with_white_outlined_circle}
                              alt=""
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <>
                      {filteredExistingTags?.length > 0 ? (
                        <p
                          className={`font-sans ${
                            isMobile ? "" : "  w-[22.75rem]"
                          } font-normal  text-Font_12 text-Primary_grey`}
                        >
                          {users
                            ? 'Select tag(s) and click "Unassign" to unlink them from the user(s).'
                            : 'Select tag(s) and click "Unassign" to unlink them from the owner account(s).'}
                        </p>
                      ) : filteredExistingTags?.length === 0 &&
                        !AllTags.some((e) =>
                          e.value
                            .toLowerCase()
                            .includes(tagSearchName.toLowerCase())
                        ) ? (
                        <p className="font-sans font-[400px] text-[12px] text-[#dadada]">
                          Click on ‘Assign new tag’ to assign the tag entered
                          above.
                        </p>
                      ) : newTags?.length === 0 ? (
                        <p className="font-sans font-[400px] text-[12px] text-[#dadada]">
                          To assign a tag, either select from the list of
                          unassigned tags and assign them or type a new tag name
                          in the search bar and click ‘Assign new tag.
                        </p>
                      ) : (
                        <p
                          className="font-sans font-[400px] text-[12px] text-[#dadada]"
                          style={{
                            visibility: "hidden",
                          }}
                        >
                          Click on ‘Assign new tag’ to assign the tag entered
                          above.
                        </p>
                      )}
                    </>

                    <>
                      {
                        !newTags?.filter((fil) => {
                          return (
                            JSON.stringify(fil)?.includes(tagSearchName) &&
                            newTags?.length === 0 && (
                              <>
                                <p className="font-sans font-[400px]  text-[14px] text-[#dadada]">
                                  To assign a tag, either select from the list
                                  of unassigned tags and assign them or type a
                                  new tag name in the search bar and click
                                  ‘Assign’.
                                </p>
                                <div className="m-[50px] flex flex-col items-center   ">
                                  <img src={notags} className="  h-[100px] " />
                                  <p className="  font-[400px] w-[150px] mt-3 ml-[15px] text-[12px] text-[#dadada] ">
                                    No assigned tags found.
                                  </p>
                                </div>
                              </>
                            )
                          );
                        })
                      }
                    </>
                  </div>
                  <div
                    className={`${
                      isMobile &&
                      filteredExistingTags?.length === 0 &&
                      tagSearchName.length > 0
                        ? " h-full pb-[20px] "
                        : ""
                    }  
                  ${
                    isMobile ? "h-full pb-[20px] " : "h-[354px] pb-[20px]"
                  }   min-h-[373px]    ${
                      icon == arrowdown ? "" : " ml-[20px]"
                    } overflow-y-auto`}
                  >
                    {isMobile ? (
                      <>
                        <div
                          className={` transition-height duration-300 absolute ease-in-out overflow-hidden z-50 w-full  popup_res  ${
                            icon == arrowdown
                              ? "  max-h-[100%]  opacity-100 overflow-y-auto cursor-pointer bottom-[1.625rem]"
                              : "max-h-0 opacity-0"
                          }  `}
                        >
                          {icon == arrowdown ? (
                            <div className="  flex flex-wrap pl-[10px] cursor-pointer  p-[20px]  overflow-y-auto   ">
                              {newTags?.map((tag) => {
                                return (
                                  <>
                                    {!tag.selected && (
                                      <>
                                        <Box
                                          className=" px-2 Usertag-value-item m-[10px] relative"
                                          key={tag?.value}
                                        >
                                          <div className=" flex items-center ">
                                            <>
                                              <span
                                                className="make-first-letter-small  "
                                                style={{
                                                  fontSize: "var(--Font_12)",
                                                }}
                                              >
                                                {tag.value}
                                              </span>
                                              <div
                                                className=" ml-[4px] cursor-pointer "
                                                onClick={() =>
                                                  handleSelectandDeselect(
                                                    tag.value
                                                  )
                                                }
                                              >
                                                <img
                                                  src={close_with_blue_circle}
                                                  className=" w-[14px] h-[14px]"
                                                />
                                              </div>
                                            </>
                                          </div>
                                        </Box>
                                      </>
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          ) : null}
                        </div>
                      </>
                    ) : (
                      <div
                        className={` transition-height duration-500 absolute ease-in-out overflow-hidden z-50  w-full bg-Blue_5 ${
                          icon == arrowdown
                            ? " h-[23.75rem]  opacity-100 overflow-y-auto cursor-pointer bottom-[4.3rem]"
                            : " h-0 opacity-0 bottom-[4.100rem]"
                        }  `}
                      >
                        {icon == arrowdown ? (
                          <div className="  flex flex-wrap pl-[10px] cursor-pointer  p-[20px]  overflow-y-auto   ">
                            {newTags?.map((tag) => {
                              return (
                                <>
                                  {!tag.selected && (
                                    <>
                                      <Box
                                        className=" px-2 Usertag-value-item m-[10px] relative"
                                        key={tag?.value}
                                      >
                                        <div className=" flex items-center ">
                                          <>
                                            <span
                                              className="make-first-letter-small  "
                                              style={{
                                                fontSize: "var(--Font_12)",
                                              }}
                                            >
                                              {tag.value}
                                            </span>
                                            <div
                                              className=" ml-[4px] cursor-pointer "
                                              onClick={() =>
                                                handleSelectandDeselect(
                                                  tag.value
                                                )
                                              }
                                            >
                                              <img
                                                src={close_with_blue_circle}
                                                className=" w-[14px] h-[14px]"
                                              />
                                            </div>
                                          </>
                                        </div>
                                      </Box>
                                    </>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        ) : null}
                      </div>
                    )}

                    {loading ? (
                      <>
                        <div className="flex justify-center items-center h-full">
                          <img
                            src={dataloading}
                            className={` ${
                              isMobile ? "  ml-[50%]" : " ml-[20px]"
                            } mt-[0px] w-[40px] h-[40px]`}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        {newTags?.length > 0 &&
                        filteredExistingTags?.length > 0 &&
                        !loading ? (
                          <div>
                            <div
                              className="flex flex-wrap "
                              style={{
                                overflowX: "hidden",
                                paddingBottom: isMobile ? "3.75rem" : "",
                                paddingLeft: icon == arrowdown ? "20px" : "0px",
                              }}
                            >
                              {newTags?.length > 0 &&
                                newTags
                                  ?.filter((fil) => {
                                    return JSON.stringify(fil.value)?.includes(
                                      tagSearchName?.toLowerCase()
                                    );
                                  })
                                  ?.map((tag) => {
                                    return (
                                      <>
                                        <Box
                                          key={tag?.value}
                                          className={`px-4 tag-box-item ${
                                            isMobile ? "" : "hover:opacity-60"
                                          } relative  `}
                                          style={{
                                            backgroundColor:
                                              tag.selected == false
                                                ? "#4A6D4B"
                                                : "#667CA3",
                                          }}
                                          onClick={() =>
                                            handleExistingTag(tag.value)
                                          }
                                        >
                                          <div>
                                            <>
                                              <span
                                                id="id-textTag"
                                                style={{
                                                  display: "inline-block",
                                                  fontSize: "var(--Font_12)",
                                                  paddingRight: "5px",
                                                }}
                                                className=" text-Primary_white"
                                              >
                                                {tag.value}
                                              </span>

                                              <span className="w-[17px] h-[17]">
                                                {tag.selected == false ? (
                                                  <img
                                                    className=""
                                                    src={Selected}
                                                    alt=""
                                                  />
                                                ) : (
                                                  <img
                                                    className=""
                                                    src={
                                                      not_selected_with_grey_circle
                                                    }
                                                    alt=""
                                                  />
                                                )}
                                              </span>
                                            </>
                                          </div>
                                        </Box>
                                      </>
                                    );
                                  })}
                            </div>
                          </div>
                        ) : (
                          <div className="existing-tag mt-3 w-full ">
                            {!loading &&
                            filteredExistingTags?.length === 0 &&
                            tagSearchName.length > 0 ? (
                              <div
                                className={` flex flex-col ${
                                  isMobile ? "pt-[60px]" : "pt-[90px]"
                                }  pr-[20px] justify-center items-center w-full`}
                              >
                                <img src={notags} className="h-[150px]" />
                                <p className="font-[400] w-[150px] mt-3 text-[12px] text-[#dadada] text-center">
                                  No Assigned tags found.
                                </p>
                              </div>
                            ) : (
                              !loading && (
                                <div
                                  className={`  flex flex-col ${
                                    isMobile ? "" : "pt-[90px] "
                                  } pr-[20px] justify-center items-center w-full`}
                                >
                                  <img src={notags} className="h-[150px]" />
                                  <p className="font-[400] w-[150px] mt-3 text-[12px] text-[#dadada] text-center">
                                    No Assigned tags found.
                                  </p>
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  {icon == arrowdown && (
                    <>
                      <div
                        className={`  ${
                          !isMobile
                            ? "  w-[93%] bottom-[4.3rem] absolute z-50 border-b-[0.5px] border-[#102C5D] "
                            : ""
                        } mx-[20px]`}
                      ></div>
                    </>
                  )}

                  {!isMobile && (
                    <>
                      <div
                        className={`flex items-center bg-Blue_5 w-full  py-[15px] ${
                          !isMobile
                            ? "absolute bottom-0 rounded-bl-[20px]"
                            : "  "
                        }  `}
                      >
                        <div className=" flex items-center justify-center w-[40px] h-[40px]   pl-[15px] pr-[15px] ml-[20px] py-[9px] bg-Blue_2 mr-[10px] rounded-large">
                          <p
                            className={`font-sans font-semibold text-Font_16  text-Primary_white`}
                          >
                            {" "}
                            {/* {newTags?.filter((fil) => !fil.selected)?.length} */}
                            {!loading &&
                              newTags?.filter((fil) => !fil.selected)?.length}
                          </p>
                        </div>
                        <p
                          className={`font-sans  font-normal text-Font_14  ${
                            isMobile ? "mr-auto " : ""
                          }  text-Primary_white`}
                        >
                          {type == "userViewAll"
                            ? "Total tags selected"
                            : users
                            ? "Total tags selected"
                            : "Total tags selected"}
                        </p>

                        <div
                          className={` flex w-[4.125rem] h-[1.625rem] items-center justify-center bg-Blue_2 rounded-[5.625rem] ml-[3.688rem] ${
                            newTags?.filter((fil) => !fil.selected).length == 0
                              ? "opacity-60 cursor-default pointer-events-none"
                              : " cursor-pointer"
                          }`}
                          onClick={handleClick}
                        >
                          <div className=" font-sans text-Font_12 text-Primary_white font-normal ">
                            {icon == arrowup ? "Show" : "Hide"}
                          </div>
                          <div className=" flex items-center">
                            <img
                              src={icon}
                              alt="Status Icon"
                              className=" h-[10px] w-[10px] ml-[5px] "
                            />
                          </div>
                        </div>

                        <div className=" h-[24px] border-r-[0.5px] border-Blue_2 ml-[20px] "></div>
                        <p
                          className=" font-normal text-Font_12 text-Primary_white underline ml-[20px] cursor-pointer"
                          onClick={clearTags}
                        >
                          Clear
                        </p>

                        <div
                          className={`flex items-center justify-center w-[84px] h-[32px] ${
                            isMobile ? "mr-[15px]" : ""
                          } ${
                            isAnyTagDeselected
                              ? "bg-Button_green cursor-pointer hover:opacity-60 "
                              : "bg-Secondary_grey cursor-default"
                          } rounded-[75px] ml-[20.5px] `}
                          onClick={
                            isAnyTagDeselected ? handleUnassign : undefined
                          }
                        >
                          <p
                            className={`font-sans font-semibold text-[12px] ${
                              isMobile ? "p-[10px]" : ""
                            } text-[#f9f9f9]`}
                          >
                            Unassign
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {isMobile && (
                  <>
                    <div
                      className={`flex items-center py-[15px] ${
                        isMobile
                          ? "fixed bottom-0 z-[99999] border-t-[1px] border-Blue_2 bg-Blue_6 w-full"
                          : ""
                      }  `}
                      style={{
                        display: activeStep !== 0 && isMobile && "none",
                      }}
                    >
                      <div className=" flex items-center justify-center w-[40px] h-[40px]   pl-[15px] pr-[15px] ml-[20px] py-[9px] bg-[#102C5D] mr-[10px] rounded-large">
                        <p
                          className={`font-sans font-semibold text-Font_16 text-Primary_white`}
                        >
                          {" "}
                          {/* {newTags?.filter((fil) => !fil.selected)?.length} */}
                          {!loading &&
                            newTags?.filter((fil) => !fil.selected)?.length}
                        </p>
                      </div>
                      <p
                        className={`font-sans  font-[400px] text-Font_14  ${
                          isMobile ? "mr-auto " : ""
                        }  text-Primary_white`}
                      >
                        {type == "userViewAll"
                          ? "Tags are assigned to all the users"
                          : users
                          ? "Tags are assigned to the selected user"
                          : "Total tags selected"}
                      </p>

                      <div
                        className={` show_btn_res  rounded-[5.625rem] ml-auto ${
                          newTags?.filter((fil) => !fil.selected).length == 0
                            ? "opacity-60 cursor-default pointer-events-none"
                            : " cursor-pointer"
                        }`}
                        onClick={handleClick}
                      >
                        <div className=" font-sans text-Font_12 text-Primary_white font-normal ">
                          {icon == arrowup ? "Show" : "Hide"}
                        </div>
                        <div className=" flex items-center">
                          <img
                            src={icon}
                            alt="Status Icon"
                            className=" h-[10px] w-[10px] ml-[5px] "
                          />
                        </div>
                      </div>

                      <div className=" h-[24px] border-r-[0.5px] border-Blue_2 sm:ml-[20px] ml-[10px] "></div>
                      <p
                        className=" font-normal text-Font_12 text-Primary_white underline ml-[10px] cursor-pointer"
                        onClick={clearTags}
                      >
                        Clear
                      </p>
                      <div
                        className={`flex items-center justify-center w-[84px] h-[32px] ${
                          isMobile ? "mr-[15px]" : ""
                        } ${
                          isAnyTagDeselected
                            ? "bg-[#69B76F] cursor-pointer hover:opacity-60 "
                            : "bg-Secondary_grey cursor-default"
                        } rounded-[75px] sm:ml-[20.5px] ml-[10.5px]  `}
                        onClick={
                          isAnyTagDeselected ? handleUnassign : undefined
                        }
                      >
                        <p
                          className={`font-sans font-semibold text-[12px] ${
                            isMobile ? "p-[10px]" : ""
                          } text-[#f9f9f9]`}
                        >
                          Unassign
                        </p>
                      </div>
                    </div>
                  </>
                )}
                <div
                  className={` ${
                    isMobile ? "w-[100%] pb-[40px]" : "w-[512px] bg-[#244277] "
                  }  relative rounded-br-[20px]`}
                  style={{
                    display: activeStep !== 1 && isMobile && "none",
                  }}
                >
                  <div
                    onClick={(e) => e.stopPropagation()}
                    style={{
                      display:
                        users && !services?.includes("CTag")
                          ? "block"
                          : !users && !services?.includes("COwnerTag")
                          ? "block"
                          : newTags?.some((tag) => {
                              return tag.selected == false;
                            })
                          ? "block"
                          : "none",
                    }}
                    className={`absolute w-full h-full  bg-black bg-opacity-30  z-[99999]`}
                  ></div>
                  <div
                    className={`${
                      AllTags?.length === 0
                        ? "px-[20px] pt-[10px] pb-[5px]"
                        : " px-[20px] pt-[10px] pb-[15px]"
                    } ${isMobile ? "min-h-[67px]" : "min-h-[68px]"}`}
                  >
                    {!isMobile && (
                      <>
                        <div className="flex justify-between items-center">
                          <p className="font-sans font-semibold text-Font_14 items-center flex text-Primary_grey">
                            Unassigned tags -
                            {tagsCountLoader1 ? (
                              <span className="inline-flex  items-center ml-2">
                                <img
                                  src={dataloading}
                                  className="w-[18px] h-[18px] mt-1 flex items-center "
                                />
                              </span>
                            ) : (
                              `  ${filteredExistingTags1?.length}`
                            )}
                            {/* {!loading && filteredExistingTags1?.length} */}
                          </p>

                          <div
                            className={` flex items-center ${
                              icon1 === arrowdown1
                                ? " opacity-25 pointer-events-none"
                                : "opacity-100"
                            } ${
                              filteredExistingTags1?.length !== 0
                                ? ""
                                : "pointer-events-none opacity-30"
                            } `}
                          >
                            <p className=" font-sans font-semibold text-Font_12 text-Primary_grey">
                              Select all
                            </p>
                            <div
                              className="w-[26px] ml-[10px] h-[26px] cursor-pointer rounded-full flex items-center justify-center bg-Blue_5"
                              onClick={() => {
                                selectedValue1((type = selectAll1));
                              }}
                            >
                              {selectAll1 &&
                              filteredExistingTags1?.length !== 0 ? (
                                <img
                                  className=" w-[16px] h-[16px]"
                                  src={Selected}
                                  alt=""
                                />
                              ) : (
                                <img
                                  className=" w-[16px] h-[16px]"
                                  src={select_all_with_white_outlined_circle}
                                  alt=""
                                />
                              )}
                              {/* <img
                                className="   w-[16px] h-[16px]"
                                src={select_all_with_white_outlined_circle}
                              /> */}
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <div
                      style={{
                        display: isMobile ? "block" : "none",
                      }}
                    >
                      <div className=" flex  items-center justify-between">
                        <p className=" font-sans font-semibold text-Font_12 text-Primary_grey">
                          Select all
                        </p>
                        <div
                          className={`w-[26px] ml-[10px] h-[26px] cursor-pointer  ${
                            icon1 === arrowdown1
                              ? " opacity-25 pointer-events-none"
                              : "opacity-100"
                          } ${
                            filteredExistingTags1?.length !== 0
                              ? ""
                              : "pointer-events-none opacity-30"
                          } rounded-full flex items-center justify-center bg-Blue_5`}
                          onClick={() => {
                            selectedValue1((type = selectAll1));
                          }}
                        >
                          {selectAll1 && filteredExistingTags1?.length !== 0 ? (
                            <img
                              className=" w-[16px] h-[16px]"
                              src={Selected}
                              alt=""
                            />
                          ) : (
                            <img
                              className=" w-[16px] h-[16px]"
                              src={select_all_with_white_outlined_circle}
                              alt=""
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    {AllTags?.filter(
                      (fill) =>
                        !newTags.some((data) => data?.value === fill?.value)
                    )?.length !== 0 ? (
                      <>
                        {filteredExistingTags1.length > 0 ? (
                          <p
                            className={`font-sans ${
                              isMobile ? "" : "w-[22.75rem]"
                            }  font-normal  text-Font_12 text-Primary_grey ${
                              isMobile ? " min-h-[36px]" : ""
                            } `}
                          >
                            {users
                              ? 'Select tag(s) and click "Assign" to link them to the user(s).'
                              : 'Select tag(s) and click "Assign" to link them to the owner account(s).'}
                          </p>
                        ) : (
                          <p
                            className="font-sans font-[400px]  text-[12px] text-[#dadada]"
                            style={{
                              visibility:
                                filteredExistingTags1.length === 0 && "hidden",
                            }}
                          >
                            ""
                          </p>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    className={`  ${
                      isMobile
                        ? " h-full pb-[20px]   "
                        : AllTags?.filter(
                            (fill) =>
                              !newTags.some(
                                (data) => data?.value === fill?.value
                              )
                          )?.length === 0
                        ? "h-[372px]"
                        : "h-[354px]"
                    } ${
                      isMobile && filteredExistingTags?.length > 0 ? "" : ""
                    } min-h-[372px]   ${loading ? "" : "overflow-y-auto"} 
                
                    `}
                  >
                    {isMobile ? (
                      <>
                        <div
                          className={`transition-height duration-300 absolute ease-in-out overflow-hidden w-full z-50 popup_res_all  ${
                            icon1 == arrowdown1
                              ? "  max-h-[100%]  opacity-100 overflow-y-auto cursor-pointer bottom-[-1.375rem]"
                              : "max-h-0 opacity-0"
                          }  `}
                        >
                          {icon1 == arrowdown1 ? (
                            <div
                              className={` flex flex-wrap pl-[10px] cursor-pointer ${
                                isMobile ? "overflow-x-hidden" : ""
                              }  p-[20px]  overflow-y-auto `}
                            >
                              {filteredExistingTags1?.map((tag) => {
                                return (
                                  <>
                                    {!tag.selected && (
                                      <>
                                        <Box
                                          className=" px-2 Usertag-value-item m-[10px] relative"
                                          key={tag?.value}
                                        >
                                          <div className=" flex items-center ">
                                            <>
                                              <span
                                                className="make-first-letter-small  "
                                                style={{
                                                  fontSize: "var(--Font_12)",
                                                }}
                                              >
                                                {tag.value}
                                              </span>
                                              <div
                                                className=" ml-[4px] cursor-pointer "
                                                onClick={() =>
                                                  handleSelectandDeselect1(
                                                    tag.value
                                                  )
                                                }
                                              >
                                                <img
                                                  src={close_with_blue_circle}
                                                  className=" w-[14px] h-[14px]"
                                                />
                                              </div>
                                            </>
                                          </div>
                                        </Box>
                                      </>
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          ) : null}
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className={`transition-height duration-500 absolute ease-in-out overflow-hidden z-50 w-full  bg-Blue_5 ${
                            icon1 == arrowdown1
                              ? " h-[23.75rem]  opacity-100 overflow-y-auto cursor-pointer bottom-[4.438rem]"
                              : "h-0 opacity-0 bottom-[4.438rem]"
                          } `}
                        >
                          {icon1 == arrowdown1 &&
                          AllTags?.filter((fil) => !fil.selected).length !==
                            0 ? (
                            <div
                              className={` flex flex-wrap pl-[10px] cursor-pointer ${
                                isMobile ? "overflow-x-hidden" : ""
                              }  p-[20px]  overflow-y-auto `}
                            >
                              {filteredExistingTags1?.map((tag) => {
                                return (
                                  <>
                                    {!tag.selected && (
                                      <>
                                        <Box
                                          className=" px-2 Usertag-value-item m-[10px] relative"
                                          key={tag?.value}
                                        >
                                          <div className=" flex items-center ">
                                            <>
                                              <span
                                                className="make-first-letter-small  "
                                                style={{
                                                  fontSize: "var(--Font_12)",
                                                }}
                                              >
                                                {tag.value}
                                              </span>
                                              <div
                                                className=" ml-[4px] cursor-pointer "
                                                onClick={() =>
                                                  handleSelectandDeselect1(
                                                    tag.value
                                                  )
                                                }
                                              >
                                                <img
                                                  src={close_with_blue_circle}
                                                  className=" w-[14px] h-[14px]"
                                                />
                                              </div>
                                            </>
                                          </div>
                                        </Box>
                                      </>
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          ) : null}
                        </div>
                      </>
                    )}

                    {loading ? (
                      <div
                        className={`flex justify-center  ${
                          isMobile ? "mt-[160px]" : " items-center"
                        } ml-[20px] pb-[40px] h-full`}
                      >
                        <img src={dataloading} className="w-[40px] h-[40px]" />
                      </div>
                    ) : AllTags?.filter(
                        (fill) =>
                          !newTags.some((data) => data?.value === fill?.value)
                      )?.length === 0 ? (
                      <div
                        className={`flex flex-col ${
                          isMobile ? "" : "pt-[90px]  pr-[20px]"
                        }  justify-center items-center`}
                      >
                        <img src={notagsuser} className="h-[150px]" />
                        <p className="font-[400]  mt-3 text-[12px] text-[#dadada] text-center">
                          No unassigned tags found.
                        </p>
                      </div>
                    ) : (
                      <div className="flex flex-wrap ml-[20px] ">
                        {filteredExistingTags1?.length > 0 &&
                        AllTags?.length > 0 &&
                        !loading ? (
                          <div
                            className="flex flex-wrap"
                            style={{
                              overflowX: "hidden",
                            }}
                          >
                            {AllTags.filter((fil) =>
                              JSON.stringify(fil.value)?.includes(
                                tagSearchName?.toLowerCase()
                              )
                            )
                              .filter(
                                (filter) =>
                                  !newTags.some(
                                    (data) => data?.value === filter?.value
                                  )
                              )
                              .map((tag) => (
                                <Box
                                  key={tag.value}
                                  className={`px-4 tag-box-item relative  ${
                                    isMobile ? "" : "hover:opacity-60"
                                  } `}
                                  style={{
                                    backgroundColor: tag.selected
                                      ? "#667CA3"
                                      : "#4A6D4B",
                                  }}
                                  onClick={() => handleExistingTag1(tag?.value)}
                                >
                                  <div>
                                    <span
                                      style={{
                                        display: "inline-block",
                                        fontSize: "var(--Font_12)",
                                        paddingRight: "5px",
                                      }}
                                      className=" text-Primary_white"
                                    >
                                      {tag?.value}
                                    </span>
                                    <span className="w-[17px] h-[17]">
                                      {tag.selected ? (
                                        <img
                                          src={not_selected_with_grey_circle}
                                          alt=""
                                        />
                                      ) : (
                                        <img src={Selected} alt="" />
                                      )}
                                    </span>
                                  </div>
                                </Box>
                              ))}
                          </div>
                        ) : (
                          <div className="existing-tag mt-3 w-full">
                            {filteredExistingTags1?.length === 0 &&
                            tagSearchName.length > 0 ? (
                              <div
                                className={` flex flex-col ${
                                  isMobile ? "" : " pt-[90px]"
                                } pr-[20px] justify-center items-center w-full`}
                              >
                                <img src={notagsuser} className="h-[150px]" />
                                <p className="font-[400] mt-3 text-[12px] text-[#dadada] text-center">
                                  No Unassigned tags found.
                                </p>
                              </div>
                            ) : (
                              <div className=" flex flex-col pt-[90px] pr-[20px] justify-center items-center w-full">
                                <img src={notagsuser} className="h-[150px]" />
                                <p className="font-[400] mt-3 text-[12px] text-[#dadada] text-center">
                                  No Unassigned tags found.
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {icon1 == arrowdown1 && (
                    <>
                      <div
                        className={` ${
                          isMobile
                            ? ""
                            : " border-b-[1px] border-[#102C5D] mx-[20px]"
                        }`}
                      ></div>
                    </>
                  )}

                  {!isMobile ? (
                    <>
                      <div className="flex items-center py-[15px] bg-[#1D3A6D] rounded-br-[20px]  ">
                        <div className=" flex items-center justify-center w-[40px] h-[40px]   px-[16px] ml-[20px] py-[9px] bg-Blue_2 mr-[10px] rounded-large">
                          <p className="font-sans font-semibold text-Font_16 text-Primary_white">
                            {" "}
                            {!loading &&
                              filteredExistingTags1?.filter(
                                (fil) => !fil.selected
                              )?.length}
                          </p>
                        </div>
                        <p
                          className={`font-sans  ${
                            isMobile ? "mr-auto " : " "
                          } font-normal text-Font_14  text-Primary_white`}
                        >
                          Total tags selected
                        </p>
                        <div
                          className={` flex w-[4.125rem] h-[1.625rem] items-center justify-center bg-Blue_2 rounded-[5.625rem] ml-[3.688rem] ${
                            AllTags?.filter((fil) => !fil.selected).length == 0
                              ? "opacity-60 cursor-default pointer-events-none"
                              : " cursor-pointer"
                          }`}
                          onClick={handleClick1}
                        >
                          <div className=" font-sans text-Font_12 text-Primary_white font-normal ">
                            {icon1 == arrowup1 ? "Show" : "Hide"}
                          </div>
                          <div className=" flex items-center">
                            <img
                              src={icon1}
                              alt="Status Icon"
                              className=" h-[10px] w-[10px] ml-[5px] "
                            />
                          </div>
                        </div>
                        <div className=" h-[24px] border-r-[0.5px] border-Blue_2 ml-[20px] "></div>
                        <p
                          className=" font-normal text-Font_12 text-Primary_white underline cursor-pointer ml-[20px]"
                          onClick={clearTags1}
                        >
                          Clear
                        </p>
                        <div
                          className={` flex items-center justify-center w-[84px] h-[32px] bg-[#707070]  ${
                            isMobile ? "mr-3" : ""
                          }   rounded-[75px] ml-[20.5px] ${
                            isAnyTagDeselectedUnassign
                              ? "bg-Button_green cursor-pointer hover:opacity-60  "
                              : "bg-Secondary_grey cursor-default "
                          }`}
                          onClick={
                            isAnyTagDeselectedUnassign
                              ? handleAssign
                              : undefined
                          }
                        >
                          <p className="font-sans font-semibold text-[12px] text-[#f9f9f9]">
                            {" "}
                            Assign
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className={`flex items-center py-[15px] ${
                          isMobile
                            ? "fixed bottom-0 z-[99999] border-t-[1px] border-Blue_2 bg-Blue_6 w-full"
                            : ""
                        }  `}
                        style={{
                          display: activeStep !== 1 && isMobile && "none",
                        }}
                      >
                        <div className=" flex items-center justify-center w-[40px] h-[40px]   px-[16px] ml-[20px] py-[9px] bg-Blue_2 mr-[10px] rounded-large">
                          <p className="font-sans font-semibold text-Font_16 text-Primary_white">
                            {" "}
                            {!loading &&
                              filteredExistingTags1?.filter(
                                (fil) => !fil.selected
                              )?.length}
                          </p>
                        </div>
                        <p
                          className={`font-sans  ${
                            isMobile ? "mr-auto " : "mr-[210px] "
                          } font-[400px] text-Font_14  text-Primary_white`}
                        >
                          Total tags selected
                        </p>

                        <div
                          className={` show_btn_res  rounded-[5.625rem] ml-auto ${
                            AllTags?.filter((fil) => !fil.selected).length == 0
                              ? "opacity-60 cursor-default pointer-events-none"
                              : " cursor-pointer"
                          }`}
                          onClick={handleClick1}
                        >
                          <div className=" font-sans text-Font_12 text-Primary_white font-normal ">
                            {icon1 == arrowup1 ? "Show" : "Hide"}
                          </div>
                          <div className=" flex items-center">
                            <img
                              src={icon1}
                              alt="Status Icon"
                              className=" h-[10px] w-[10px] ml-[5px] "
                            />
                          </div>
                        </div>

                        <div className=" h-[24px] border-r-[0.5px] border-Blue_2 ml-[20px] "></div>
                        <p
                          className=" font-normal text-Font_12 text-Primary_white underline ml-[10px] cursor-pointer"
                          onClick={clearTags1}
                        >
                          Clear
                        </p>

                        <div
                          className={` flex items-center justify-center w-[84px] h-[32px] bg-[#707070]  ${
                            isMobile ? "mr-3" : ""
                          }   rounded-[75px] ml-[20.5px] ${
                            isAnyTagDeselectedUnassign
                              ? "bg-[#69B76F] cursor-pointer hover:opacity-60  "
                              : "bg-Secondary_grey cursor-default "
                          }`}
                          onClick={
                            isAnyTagDeselectedUnassign
                              ? handleAssign
                              : undefined
                          }
                        >
                          <p className="font-sans font-semibold text-[12px] text-[#f9f9f9]">
                            {" "}
                            Assign
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserTag;
