import React, { useState } from "react";
import "./index.css";
import QrCode from "../../../assets/GeneralIcons/qr_code.svg";
import GoogleAuth from "../../../assets/GeneralIcons/google_auth.svg";
import MicrosoftAuth from "../../../assets/GeneralIcons/microsoft_auth.svg";
import Appstore from "../../../assets/GeneralIcons/appstore.svg";
import Playstore from "../../../assets/GeneralIcons/playstore.svg";
import AppDownloaded from "../../../assets/GeneralIcons/app_downloaded.svg";
import Greencheck from "../../../assets/CheckmarksIcons/selected_with_green_circle.svg";
import Whitecheck from "../../../assets/CheckmarksIcons/not_selected_with_grey_circle.svg";
import Redirect from "../../../assets/GeneralIcons/redirect.svg";
import ScannerModel from "../ScannerModel";

const TotpDownloadApp = ({ isAppDownload, setIsAppDownload }) => {
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [appType, setAppType] = useState("");

  const handleScanner = (type, url) => {
    setAppType(type);
    setUrl(url);
    setIsScannerOpen(!isScannerOpen);
  };
  return (
    <div className="totp_Top_Main">
      {isScannerOpen && (
        <ScannerModel url={url} appType={appType} close={setIsScannerOpen} />
      )}
      <div className="divide_totp">
        <div className="totp_download_left">
          <h4 className="totp_download_left_1">
            Download An Authentication App
          </h4>
          <p className="totp_download_left_2">
            Install a TOTP-compatible authenticator app on your mobile, tablet
            or desktop device this will generate TOTP code which will be
            required for registration and then every time you login.
          </p>
          <p className="totp_download_left_3">
            We recommend below listed app or you can use any other app
            supporting TOTP.
          </p>
          <div className="totp_download_left_4">
            <div className="totp_auth_google">
              <div className="totp_auth_app">
                <img src={GoogleAuth} alt="GoogleAuth" />
                <h4>Google Authenticator</h4>
              </div>
              <div className="totp_auth_app_box">
                <div className="totp_auth_box_play">
                  <div className="totp_auth_box_play_split">
                    <img
                      src={Playstore}
                      alt="Playstore"
                      className="totp_auth_box_playstore"
                    />
                    <a
                      href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_IN&pli=1"
                      target="_blank"
                    >
                      {" "}
                      <img
                        src={Redirect}
                        alt="Redirect"
                        className="redirect_totp"
                      />
                    </a>
                  </div>
                  <img
                    src={QrCode}
                    alt="QrCode"
                    className="totp_auth_box_qrcode"
                    onClick={() => {
                      handleScanner(
                        "playstore",
                        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_IN&pli=1"
                      );
                    }}
                  />
                </div>
                <div className="totp_auth_box_play">
                  <div className="totp_auth_box_play_split">
                    <img
                      src={Appstore}
                      alt="Appstore"
                      className="totp_auth_box_playstore"
                    />
                    <a
                      href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                      target="_blank"
                    >
                      {" "}
                      <img
                        src={Redirect}
                        alt="Redirect"
                        className="redirect_totp"
                      />
                    </a>
                  </div>
                  <img
                    src={QrCode}
                    alt="QrCode"
                    className="totp_auth_box_qrcode"
                    onClick={() => {
                      handleScanner(
                        "appstore",
                        "https://apps.apple.com/us/app/google-authenticator/id388497605"
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="totp_auth">
              <div className="totp_auth_app">
                <img src={MicrosoftAuth} alt="GoogleAuth" />
                <h4>Microsoft Authenticator</h4>
              </div>
              <div className="totp_auth_app_box">
                <div className="totp_auth_box_play">
                  <div className="totp_auth_box_play_split">
                    <img
                      src={Playstore}
                      alt="Playstore"
                      className="totp_auth_box_playstore"
                    />
                    <a
                      href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en_IN"
                      target="_blank"
                    >
                      {" "}
                      <img
                        src={Redirect}
                        alt="Redirect"
                        className="redirect_totp"
                      />
                    </a>
                  </div>
                  <img
                    src={QrCode}
                    alt="QrCode"
                    className="totp_auth_box_qrcode"
                    onClick={() => {
                      handleScanner(
                        "playstore",
                        "https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en_IN"
                      );
                    }}
                  />
                </div>
                <div className="totp_auth_box_play">
                  <div className="totp_auth_box_play_split">
                    <img
                      src={Appstore}
                      alt="Appstore"
                      className="totp_auth_box_playstore"
                    />
                    <a
                      href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
                      target="_blank"
                    >
                      {" "}
                      <img
                        src={Redirect}
                        alt="Redirect"
                        className="redirect_totp"
                      />
                    </a>
                  </div>
                  <img
                    src={QrCode}
                    alt="QrCode"
                    className="totp_auth_box_qrcode"
                    onClick={() => {
                      handleScanner(
                        "appstore",
                        "https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="divide_totp">
        <div className="totp_download_right">
          <img src={AppDownloaded} alt="AppDownloaded" />
          <div
            className="totp_download_right_button"
            onClick={() => {
              setIsAppDownload(!isAppDownload);
            }}
          >
            <img src={isAppDownload === true ? Greencheck : Whitecheck} />
            <div className="totp_download_right_button_info">
              <h4>Confirm Download</h4>
              <p>I have downloaded the app.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotpDownloadApp;
