import React from "react";
import "./index.css";
import Close from "../../../assets/CloseIcons/close_with_blue_square.svg";
import DefaultClose from "../../../assets/CloseIcons/default_Close.svg";
import { QRCodeSVG } from "qrcode.react";

const ScannerModel = ({ url, appType, close }) => {
  return (
    <div className="scanner_main">
      <div className="scanner_div">
        <div className="scanner_div_top">
          <h4>Scan QR code</h4>
          <div
            class="relative w-[36px] h-[36px] bg-cover bg-center group transition-all duration-300 cursor-pointer"
            style={{ backgroundImage: `url(${DefaultClose})` }}
            onClick={() => {
              close(false);
            }}
          >
            <div
              class="absolute inset-0 bg-cover bg-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 cursor-pointer"
              style={{ backgroundImage: `url(${Close})` }}
            ></div>
          </div>
        </div>
        <div className="scanner_div_bottom_top">
          <div className="scanner_div_bottom">
            <QRCodeSVG value={url} size={200} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScannerModel;
