import React from "react";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "./index.css";
import { useNavigate } from "react-router-dom";

import searchIcon from "../../../assets/FilterSearchIcons/search_white.svg";
import chrome from "../../../assets/MultiPlatformHubIcons/Chrome.svg";
import firefox from "../../../assets/MultiPlatformHubIcons/MozillaFirefox.svg";
import internetExplorer from "../../../assets/MultiPlatformHubIcons/Explorer.svg";
import safari from "../../../assets/MultiPlatformHubIcons/Safari.svg";
import microsoftEdge from "../../../assets/MultiPlatformHubIcons/MicrosoftEdge.svg";
import defaultBrowser from "../../../assets/MultiPlatformHubIcons/DefaultBrowser.svg";
import android from "../../../assets/MultiPlatformHubIcons/Android.svg";
import apple from "../../../assets/MultiPlatformHubIcons/MacOs.svg";

import { toast_position } from "../../../redux/features/Settings/Authorization";
import { setDeviceData } from "../../../redux/features/devices/devices";

const MyDeviceCard = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const deviceData = data;

  const loggedInDevice = deviceData?.find(
    (device) => device.logged_in_flag === "Y"
  );

  //wip
  const handleSearch = () => {
    toast.success("Feature coming soon", toast_position);
  };

  return (
    <div className="device-card-div">
      <div className="deviceCard">
        <div className="deviceCardInner">
          {deviceData?.map((device, index) => (
            <Box
              key={index}
              className="multiSelectDeviceCard"
              onClick={() => {
                let data = {
                  loggedInUser: loggedInDevice,
                  deviceId: device?.id,
                  devices: deviceData,
                };

                console.log(data, "fdfsf");
                dispatch(setDeviceData(data));
                navigate(`/settings/Device/Details`, {
                  state: { ddata: data },
                });
              }}
              sx={{
                border:
                  device?.logged_in_flag === "Y" ? "1px solid #CE720F" : "none",
              }}
            >
              <div className="device-card-top-div">
                <div>
                  <p className="device-card-titles">
                    {device.platform === "Web" ||
                    device.platform === "web" ||
                    device.platform === "PWA" ||
                    device.platform === "pwa"
                      ? device.browser_name
                      : device.model}
                  </p>
                  <p className="device-card-sub">
                    {device?.platform?.toLowerCase() === "web"
                      ? "Browser name"
                      : "Model"}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    marginRight: "10px",
                  }}
                >
                  <p className="device-card-titles">
                    {`${device?.platform
                      ?.slice(0, 1)
                      .toUpperCase()}${device?.platform?.slice(1)}`}
                  </p>
                  <p className="device-card-sub">Platform</p>
                </div>
              </div>
              <div className="device-card-bottom-div">
                <div>
                  <p className="device-card-titles">{device.os_version}</p>
                  <p className="device-card-sub"> Operating system</p>
                </div>

                <div className="browser-logo-div">
                  <img
                    src={
                      device.platform === "Android"
                        ? android
                        : device.platform === "iOS"
                        ? apple
                        : device.browser_name === "Google Chrome"
                        ? chrome
                        : device.browser_name === "Apple Safari"
                        ? safari
                        : device.browser_name === "Mozilla Firefox"
                        ? firefox
                        : device.browser_name === "Microsoft Edge"
                        ? microsoftEdge
                        : device.browser_name === "Microsoft Internet Explorer"
                        ? internetExplorer
                        : defaultBrowser
                    }
                    alt="browser"
                  />
                </div>
              </div>
            </Box>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MyDeviceCard;
