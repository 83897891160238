import React, { useRef, useEffect, useState } from "react";
import { tooltipClasses, Tooltip } from "@mui/material";
import "./index.css";
import ArrowDown from "../../../assets/ArrowsIcons/arrow_down_white_filled.svg";
import CircleCancel from "../../../assets/CloseIcons/close_with_white_circle.svg";
import CheckIcon from "../../../assets/CheckmarksIcons/check_mark_white.svg";
import Search from "../../../assets/FilterSearchIcons/search_white.svg";
import clear_filter from "../../../assets/ViewAllTransactions/Clear filter.png";

const SelectBank = ({
  option,
  filterPayload,
  setFilterPayload,
  selectBank,
  setSelectBank,
  owner,
  statement,
  selectedtBankState,
  setSelectedtBankState,
}) => {
  let options = [...option];
  const accountBalanceRef = useRef(null);
  const inputRef2 = useRef(null);

  const [bankOptions, setBankOptions] = useState(options);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        accountBalanceRef.current &&
        !accountBalanceRef.current.contains(event.target)
      ) {
        setSelectBank(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleChange = (e) => {
    e.stopPropagation();
    let opt = options.filter((bank) => {
      return bank.toLowerCase().includes(e.target.value.toLowerCase());
    });
    setBankOptions(opt);
  };

  return (
    <div className="owner_connected_bank_div">
      <p style={{ height: "22px", marginBottom: statement ? "0" : "" }}>
        {owner || statement ? "Bank" : "Filter by bank"}
      </p>
      <div
        ref={accountBalanceRef}
        className="owner_select_bank"
        onClick={() => {
          setSelectBank(!selectBank);
        }}
        style={{
          height: selectBank
            ? bankOptions.length === 1
              ? "107px"
              : "150px"
            : owner || statement
            ? "37px"
            : "39px",
          outline: selectBank ? "1px solid #E25869" : "",
          width: owner || statement ? "216px" : "189px",
          zIndex: "2147576",
          cursor: "pointer",
        }}
      >
        <div
          style={{ width: owner || statement ? "216px" : "189px" }}
          className="owner_connected_bank_select"
        >
          <div
            className="owner_connected_bank_scroll_line"
            style={{
              display: selectBank ? "block" : "none",
            }}
          ></div>

          <span
            className="truncate"
            style={{
              width: "80%",
              fontSize: selectBank || statement ? "12px" : "14px",
              paddingLeft: "14px",
            }}
          >
            {statement
              ? selectedtBankState.length > 0
                ? selectedtBankState
                : "Select a bank"
              : filterPayload.bankNameInput.length > 0
              ? filterPayload.bankNameInput
              : "Select a bank"}
          </span>
          <div className="flex flex-row items-center">
            <img
              src={clear_filter}
              alt="clear"
              className="w-[9px] h-[9px]"
              style={{
                visibility:
                  filterPayload.bankNameInput.length > 0 ? "visible" : "hidden",
              }}
              onClick={(e) => {
                setFilterPayload((prev) => {
                  return { ...prev, bankNameInput: "" };
                });
                e.stopPropagation();
              }}
            />
            <div
              className="w-[1px] h-[16px] mx-[8px]"
              style={{
                backgroundColor: "var(--Blue_3)",
                visibility:
                  filterPayload.bankNameInput.length > 0 ? "visible" : "hidden",
              }}
            ></div>
            <img
              src={ArrowDown}
              alt="down"
              className="w-[11px] h-[11px]"
              style={{
                transform: selectBank ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </div>
        </div>

        <div
          style={{
            height: "102px",
            overflowX: "hidden",
            overflowY: "auto",
            marginTop: "1px",
            padding: owner || statement ? "9px 2px 0 2px" : "9px 5px 0 5px",
          }}
          className="owner_connected_bank_scroll"
        >
          <div
            className="input_div_2"
            style={{ width: owner || statement ? "200px" : "173px" }}
          >
            <img
              src={Search}
              alt="search"
              className="search_owner_acc"
              onClick={(e) => {
                e.stopPropagation();
                inputRef2.current.focus();
              }}
            />
            <input
              ref={inputRef2}
              type="text"
              onChange={handleChange}
              onClick={(e) => e.stopPropagation()}
              placeholder="Search bank"
            />
            <img
              src={CircleCancel}
              alt="search"
              className="search_owner_acc"
              onClick={(e) => {
                e.stopPropagation();
                inputRef2.current.value = "";
                setBankOptions(options);
                if (statement) {
                  setSelectedtBankState("");
                } else {
                  setFilterPayload({
                    ...filterPayload,
                    bankNameInput: "",
                  });
                }
              }}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
          {bankOptions.length > 0 ? (
            <div>
              {bankOptions.map((bank) => {
                return owner || statement ? (
                  <div
                    className="owner_connected_bank_scroll_com hover:bg-[#506994] relative"
                    style={{
                      backgroundColor:
                        filterPayload?.bankNameInput == bank ||
                        selectedtBankState == bank
                          ? "#3A5686"
                          : "",
                      padding: "0px 10px",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      inputRef2.current.value = "";
                      setBankOptions(options);
                      if (statement) {
                        setSelectedtBankState(bank == "None" ? "" : bank);
                      } else {
                        setFilterPayload({
                          ...filterPayload,
                          bankNameInput: bank == "None" ? "" : bank,
                        });
                      }
                      setSelectBank(false);
                    }}
                  >
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title={bank}
                    >
                      <p
                        style={{
                          width: "170px",
                          maxWidth: "170px",
                          overflow: "hidden",
                          textWrap: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {bank}
                      </p>
                    </Tooltip>
                    <div
                      style={{
                        display:
                          filterPayload?.bankNameInput == bank ||
                          selectedtBankState == bank
                            ? "flex"
                            : "none",
                        textAlign: "right",
                        justifyContent: "end",
                        paddingRight: "1px",
                      }}
                    >
                      <img
                        style={{
                          height: "13px",
                          width: "11px",
                        }}
                        src={CheckIcon}
                        alt="check"
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className="owner_select_bank_scroll_com hover:bg-[#506994]"
                    style={{
                      backgroundColor:
                        filterPayload?.bankNameInput ||
                        selectedtBankState === bank
                          ? "#3A5686"
                          : "",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      inputRef2.current.value = "";
                      setBankOptions(options);
                      if (statement) {
                        setSelectedtBankState(bank == "None" ? "" : bank);
                      } else {
                        setFilterPayload({
                          ...filterPayload,
                          bankNameInput: bank == "None" ? "" : bank,
                        });
                      }
                      setSelectBank(false);
                    }}
                  >
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title={bank}
                    >
                      <p
                        style={{
                          width: "150px",
                          maxWidth: "150px",
                          overflow: "hidden",
                          textWrap: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {bank}
                      </p>
                    </Tooltip>
                    <div
                      style={{
                        display:
                          filterPayload?.bankNameInput ||
                          selectedtBankState == bank
                            ? "flex"
                            : "none",
                        textAlign: "right",
                        justifyContent: "end",
                        paddingRight: "1px",
                      }}
                    >
                      <img
                        style={{
                          height: "13px",
                          width: "11px",
                        }}
                        src={CheckIcon}
                        alt="check"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <p
              style={{
                paddingLeft: "20px",
                color: "#f9f9f9",
                paddingTop: "10px",
                fontSize: "12px",
                fontWeight: "400",
                textAlign: "left",
              }}
            >
              No bank found
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectBank;
