import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tooltip, tooltipClasses } from "@mui/material";
import { formatAccountNumber } from "../../../../../components/_utils/FormatAccountNumber";
import ArrowRightBlue from "../../../../../assets/ArrowsIcons/arrow_right_blue.svg";
import ArrowDownCircle from "../../../../../assets/ArrowsIcons/arrow_down_blue_white.svg";
import DeclinedImage from "../../../../../assets/GeneralIcons/Reject page graphic.svg";
import DeclinedRed from "../../../../../assets/RejectIcons/reject_icon_red_linear.svg";
import FormatAmount from "../../../../../components/_utils/FormatAmount/FormatAmount";
import WhitedashBoard from "../../../../../assets/GeneralIcons/dashboard.svg";

const InternalMultiApprovalDeclined = () => {
  const [declinedPageData, setdeclinedPageData] = useState([]);
  const [declinedPageID, setdeclinedPageID] = useState([]);
  const [isMobileResponsive, setIsMobileResponsive] = useState(
    window.innerWidth <= 800
  );
  useEffect(() => {
    const handleResize = () => {
      setIsMobileResponsive(window.innerWidth <= 800);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    const storedSelectedApproval = JSON.parse(
      sessionStorage.getItem("selectedData")
    );

    if (storedSelectedApproval) {
      setdeclinedPageData(storedSelectedApproval?.selectedSendTransactions);
      setdeclinedPageID(storedSelectedApproval.selectedTransactions);
    } else {
      setdeclinedPageData([]);
      setdeclinedPageID([]);
    }
  }, []);
  return (
    <>
      {isMobileResponsive ? (
        <div className=" bg-Success_BG" style={{ height: "100vh" }}>
          <div className="flex flex-col justify-center items-center">
            <img
              className="h-[177px] my-4"
              src={DeclinedImage}
              alt="successImage"
            />
            <p className="text-center text-xl font-semibold text-[#F9F9F9]">
              {declinedPageID.length === 1
                ? "Transaction has been rejected"
                : "Transactions have been rejected"}
            </p>
          </div>

          <div
            className="pt-4"
            style={{
              height: "calc(100vh - 232px)",
            }}
          >
            <div className="w-full bg-Bottom_tab flex justify-center gap-2 items-center h-[46px] rounded-b-none ">
              <img className="w-[16px] h-[16px]" src={DeclinedRed} alt="" />
              <p className=" text-xs font-semibold text-Primary_white">
                Rejected Transactions - {declinedPageID.length}
              </p>
            </div>
            <div
              className="bg-Primary_white"
              style={{
                overflowY: declinedPageData.length >= 2 ? "scroll" : "hidden",
                paddingBottom: "10px",
                height: "calc(100vh - 385px)",
              }}
            >
              <div className="mb-5 pt-5">
                {declinedPageData?.map((account, i) => {
                  return (
                    <>
                      <div className="w-full h-[30px] bg-White_hover">
                        <div className="flex justify-between items-center px-[15px] pt-1">
                          <p className="h-[22px] font-semibold text-base text-Settled opacity-100">
                            ₹ {<FormatAmount price={account?.amount} />}
                          </p>
                          <span className="font-sans text-xs text-Secondary_grey opacity-100">
                            Amount
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col gap-[10px]">
                        <div className="flex flex-col items-start gap-[10px] pt-[10px] px-[15px]">
                          <div>
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={account?.owner_account_bank_acc_no}
                            >
                              <p className="w-[167px] h-[22px] text-left font-sans font-medium text-[14px] text-Primary_black">
                                {formatAccountNumber(
                                  account?.owner_account_bank_acc_no
                                )}
                              </p>
                            </Tooltip>
                            <span className="flex text-left font-sans text-xs text-Secondary_grey opacity-100">
                              Source account number
                            </span>
                          </div>
                        </div>
                        <div className="relative border-b border-Primary_grey">
                          <img
                            src={ArrowDownCircle}
                            alt="Arrow Down"
                            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[30px] h-[30px]"
                          />
                        </div>

                        <div className="flex flex-col justify-center items-start gap-[10px] pt-[10px] pb-[15px] px-[15px]">
                          <div>
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={account?.internal_account_bank_acc_no}
                            >
                              <p className="whitespace-nowrap overflow-ellipsis overflow-hidden first-line:capitalize lowercase w-[167px] h-[22px] text-left font-sans font-medium text-sm text-Primary_black">
                                {formatAccountNumber(
                                  account?.internal_account_bank_acc_no
                                )}
                              </p>
                            </Tooltip>
                            <span className="flex text-left font-sans text-xs text-Secondary_grey opacity-100">
                              Destination account number
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* {i < declinedPageData.length - 1 && (
                        <div
                          style={{
                            marginTop: "10px",
                            border: "0.5px solid #4A664D",
                            height: "0px",
                            opacity: "100",
                          }}
                        ></div>
                      )} */}
                    </>
                  );
                })}
              </div>
            </div>
            <div className="relative h-[105px] rounded-tl-[20px] bg-Success_BG top-[-13px] rounded-tr-[20px]">
              <Link to={"/transaction/internal_transaction/dashboard"}>
                <div className="flex flex-col gap-1 justify-center items-center h-full">
                  <img
                    className="w-[33px] h-[33px]"
                    src={WhitedashBoard}
                    alt=""
                  />
                  <p className="w-[63px] h-[32px] text-center text-xs font-semibold text-Primary_white">
                    Go to dashboard
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="min-h-screen bg-Success_BG">
          <div className="flex justify-center">
            <div className="flex flex-row max-w-screen-lg justify-center">
              <div className="flex-1 mt-[66px] py-[6.25rem]">
                <div className="flex w-[22.688rem] mr-[6.25rem] justify-center items-center bg-Success_BG">
                  <div className="p-2 flex flex-col items-center justify-center text-center text-white text-2xl">
                    <img
                      src={DeclinedImage}
                      className="w-[330px] h-[334px]"
                      alt=""
                      srcSet=""
                    />
                    <div className="pt-5">
                      <p className="text-center text-xl font-semibold text-[#F9F9F9]">
                        {declinedPageID.length === 1
                          ? "Transaction has been rejected"
                          : "Transactions have been rejected"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-1 mt-[66px] py-[35px]">
                <div className="flex flex-col h-[554px] w-[472px] mx-5 bg-[#FFFFFF] rounded-[30px] items-center justify-between">
                  <div className="h-[66px] w-[472px] bg-Bottom_tab rounded-tl-[20px] rounded-tr-[20px]">
                    <div className="flex justify-center my-5 gap-[1px]">
                      <p className="w-[181px] text-left whitespace-nowrap font-semibold text-base leading-[22px] tracking-[0px] text-Primary_white opacity-100">
                        Rejected transactions - {declinedPageID.length}
                      </p>
                    </div>
                  </div>
                  <div className="h-[341px] w-full flex my-5 overflow-y-auto">
                    <div className="w-full h-auto">
                      {declinedPageData?.map((account, i) => {
                        return (
                          <>
                            <div className="w-full h-8 bg-White_hover pt-2 flex items-center">
                              <div className="flex justify-between px-5 w-full">
                                <p className="font-semibold text-base text-Settled">
                                  ₹ {<FormatAmount price={account?.amount} />}
                                </p>
                                <span className="font-sans text-xs text-Secondary_grey opacity-100">
                                  Amount
                                </span>
                              </div>
                            </div>
                            <div className="flex my-[15px] justify-between px-5">
                              <div className="flex justify-start items-center gap-[10px] w-full">
                                <div>
                                  <Tooltip
                                    componentsProps={{
                                      popper: {
                                        sx: {
                                          [`& .${tooltipClasses.tooltip}`]: {
                                            backgroundColor: (theme) =>
                                              theme.palette.common.black,
                                          },
                                        },
                                      },
                                    }}
                                    title={account?.owner_account_bank_acc_no}
                                  ></Tooltip>
                                  <p className="w-[167px] h-[22px] text-left font-sans text-sm leading-[22px] tracking-normal text-Primary_black">
                                    {formatAccountNumber(
                                      account?.owner_account_bank_acc_no
                                    )}
                                  </p>
                                  <span className="flex items-start font-sans text-xs leading-[17px] tracking-normal text-Secondary_grey opacity-100">
                                    Source account number
                                  </span>
                                </div>
                              </div>

                              <img
                                src={ArrowRightBlue}
                                alt="Arrow Right Blue"
                                className="w-[18px] h-[18px] self-center"
                              />

                              <div className="flex justify-end items-start gap-[10px] w-full">
                                <div className="text-right">
                                  <Tooltip
                                    componentsProps={{
                                      popper: {
                                        sx: {
                                          [`& .${tooltipClasses.tooltip}`]: {
                                            backgroundColor: (theme) =>
                                              theme.palette.common.black,
                                          },
                                        },
                                      },
                                    }}
                                    title={
                                      account?.internal_account_bank_acc_no
                                    }
                                  >
                                    <p className="w-[167px] h-[22px] text-right font-sans text-sm leading-[22px] tracking-normal text-Primary_black">
                                      {formatAccountNumber(
                                        account?.internal_account_bank_acc_no
                                      )}
                                    </p>
                                  </Tooltip>
                                  <span className="flex items-start ml-3 font-sans text-xs leading-[17px] tracking-normal text-Secondary_grey opacity-100">
                                    Destination account number
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/* {i < successPageData.length - 1 && (
                                    <div
                                      style={{
                                        marginTop: "10px",
                                        border: "0.5px solid #4A664D",
                                        height: "0px",
                                        width: "411px",
                                        opacity: "100",
                                      }}
                                    ></div>
                                  )} */}
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="flex justify-center items-center h-[136px] w-[472px] bg-Bottom_tab rounded-[20px]">
                    <Link to={"/transaction/internal_transaction/dashboard"}>
                      <div className="w-[62px] flex justify-center items-center flex-col gap-[4px]  h-[76px]">
                        <img
                          className="w-[40px] h-[40px] "
                          src={WhitedashBoard}
                          alt=""
                        />
                        <p className="w-[62px] h-[32px] text-center font-semibold text-xs leading-[15px] tracking-[0px] text-Primary_white opacity-100">
                          Go to dashboard
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InternalMultiApprovalDeclined;
