import React from "react";
import "./index.css";
import Warning from "../../../assets/ErrorIcons/error_with_white_circle.svg";
import BackupCodeImg from "../../../assets/GeneralIcons/backup_codes.svg";
import Copy from "../../../assets/GeneralIcons/copy_clipboard.svg";
import { tooltipClasses, Tooltip } from "@mui/material";

const TotpBackupCodes = ({
  backupCodes,
  open,
  setOpen,
  setTooltipText,
  tooltipText,
}) => {
  const handleCopy = () => {
    navigator.clipboard
      .writeText(backupCodes)
      .then(() => {
        setTooltipText("Copied!");
        setOpen(true);

        setTimeout(() => {
          setOpen(false);
          setTooltipText("Click to copy");
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  return (
    <div className="totp_Top_Main">
      <div className="divide_totp">
        <div className="totp_backup_codes_left">
          <h4 className="totp_backup_codes_left_1">
            Download & Save Backup Codes
          </h4>
          <p className="totp_backup_codes_left_2">
            Backup codes allow to log in to your account if you're unable to
            receive TOTP codes from your authenticator app.{" "}
          </p>
          <div className="totp_backup_codes_left_3">
            <Tooltip
              title={tooltipText}
              open={open}
              onClose={() => setOpen(false)}
              componentsProps={{
                popper: {
                  sx: {
                    [`& .${tooltipClasses.tooltip}`]: {
                      backgroundColor: (theme) => theme.palette.common.black,
                    },
                  },
                },
              }}
            >
              <button
                className="totp_backup_codes_left_copy"
                onClick={handleCopy}
                onMouseEnter={() => setOpen(true)} // Show tooltip on hover
                onMouseLeave={() =>
                  !tooltipText.includes("Copied") && setOpen(false)
                }
              >
                <img src={Copy} alt="Copy" />
              </button>
            </Tooltip>

            <div className="totp_backup_codes_left_all_backup">
              {backupCodes?.map((val, i) => {
                return <div key={i}>{val}</div>;
              })}
            </div>
          </div>
          <p className="totp_backup_codes_left_4">
            Ensure you save the backup codes securely. Do not share your backup
            codes with anyone.
          </p>
        </div>
      </div>
      <div className="divide_totp">
        <div className="totp_backup_codes_right">
          <div className="totp_backup_codes_right_top">
            <img src={Warning} alt="Warning" />
            <p>
              If you use a backup code to log in, your TOTP setup will be
              disabled, and you'll have to set it up again.
            </p>
          </div>
          <div className="totp_backup_codes_right_bottom">
            <img src={BackupCodeImg} alt="BackupCodeImg" />
            <p className="text-Primary_white font-sans font-normal text-Font_12 mt-[10px]">
              To complete the process, click “Download”
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotpBackupCodes;
