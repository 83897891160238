import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./index.css";
import dataLoading from "../../../../assets/LoaderIcons/Loader.gif";
import internalTransaction from "../../../../assets/GeneralIcons/initiate_internal_transaction.svg";
import view_statement from "../../../../assets/ViewIcons/view_statement.svg";
import Refreshbalancecard from "../../../../assets/RefreshIcons/refresh_balance.svg";
import { Tooltip, tooltipClasses } from "@mui/material";
import assigntag from "../../../../assets/GeneralIcons/assign_tag.svg";
import arrow_left_white from "../../../../assets/ArrowsIcons/arrow_left_white.svg";
import Close from "../../../../assets/CloseIcons/close.svg";
import refresh_white from "../../../../assets/RefreshIcons/reload-refresh_white.svg";

import editIcon from "../../../../assets/EditIcons/edit_white.svg";
import arrowRight from "../../../../assets/ArrowsIcons/arrow_left_blue_box.svg";
import infoIcon from "../../../../assets/InfoIcons/Info.svg";
import {
  ownerAccDetail,
  addOwnerAccTags,
  removeOwnerAccTags,
  setTags,
  getOwnerAccBalance,
  setshowsuccestoast,
  ownerViewAllAcc,
  getOwnerAccStatitics,
  getOwnerAccWeekStatitics,
} from "../../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import { formatAccountNumber } from "../../../../components/_utils/FormatAccountNumber/index";
import RefreshBalanceToast from "../../../../components/_utils/RefreshBalanceToast/index";
import BalanceLoader from "../../../../components/_utils/BalanceLoader";
import formatTotalBalance from "../../../../utils/formatTotalBalance";
import OwnerDetailsSelectTag from "../../../../components/Accounts/OwnerAccounts/OwnerDetailsSelectTag";
import OwnerViewStatementModal from "../../../../components/Accounts/OwnerAccounts/OwnerViewStatementModal";
import transactionHistory from "../../../../assets/InternalIcons/internal_with_white_circle without fill.svg";
import beneHistory from "../../../../assets/GeneralIcons/beneficiary_white_circle.svg";
import LinkedLogo from "../../../../assets/GeneralIcons/linked_white_circle.svg";
import whiteStar from "../../../../assets/OwnerAccount/whiteStar.svg";
import yellowStar from "../../../../assets/OwnerAccount/yellowStar.svg";
import RefreshIcon from "../../../../assets/RefreshIcons/reload-refresh_white.svg";

import { checkUsertoken } from "../../../../redux/features/login/login";
import { Box, Button } from "@mui/material";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import IfscCodeMapper from "../../../../components/_utils/IfscLogoMapper";
import AnimationUpAndDown from "../../../../components/BeneTransaction/OwnerPopup/AnimationUpAndDown";
import TransactionHistory from "../../../../components/TransactionHistoryModal";
import { beneViewAllAcc } from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import UserTag from "../../../../components/UserTag";
import MinimumBal from "../../../../components/_utils/MinimumBalance";
import FormatAmount from "../../../../components/_utils/FormatAmount/FormatAmount";
import OwnerAllStatement from "../OwnerAllStatement";
import DatePickerComponent from "../../../../components/_utils/Calendar";
import arrowforward from "../../../../assets/ArrowsIcons/arrow_right_white – 1.svg";
import OwnerAccStatisticsDropdown from "../../../../components/_utils/OwnerAccStatisticsDropdown/index.jsx";
import NoStatistics from "../../../../assets/Graphics/OBJECTS.svg";
import OwnerStatisticsGraph from "../../../../components/Accounts/OwnerAccounts/OwnerStatisticsGraph/index.jsx";

function OwnerDetailsPage(props) {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  const { beneViewAllAccData } = useSelector(
    (state) => state.beneAllVerification
  );
  const [loading, setLoading] = useState(false);

  const [viewStatementOpenPop, setViewstatementOpenPop] = useState(false);
  const [selectedTagOpenPop, setSelectedTagOpenPop] = useState(false);
  const [loaderr, setLoaderr] = useState(false);
  const [ownerDetailData, setOwnerdetaildata] = useState([]);
  const [accId, setAccId] = useState("");
  const [currentConnectedBal, setCurrentConnectedBal] = useState("");
  const [prevConnectedBal, setPrevConnectedBal] = useState("");
  const [refreshedConnectedId, setRefreshedConnectedId] = useState("");
  const [isMore, setIsMore] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const [showStatistics, setShowStatistics] = useState(false);
  const [showAssignedTags, setShowAssignedTags] = useState(false);
  const [showDisplay, setShowDisplay] = useState(false);
  const [balanceFailed, setBalanceFailed] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [openBeneHistory, setOpenBeneHistory] = useState(false);
  const [viewBalance, setViewBalance] = useState(false);
  const [newTags, setNewTags] = useState([]);
  const [existingTags, setExistingTags] = useState([]);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1024);
  const [refreshId, setRefreshId] = useState("");
  const [AllTags, setAllTags] = useState([]);
  const [minBalance, setMinBalance] = useState(false);
  const [amount, setAmount] = useState(() => {
    const savedAmount = localStorage.getItem("minBalanceAmount");
    return savedAmount ? savedAmount : "";
  });
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [startDateState, setStartDateState] = useState(
    new Date(new Date().setDate(1))
  );
  // const [endDateState, setEndDateState] = useState(currentDate);
  const [endDateState, setEndDateState] = useState(new Date());

  const [startDate, setStartDate] = useState(new Date(new Date().setDate(1)));
  const [endDate, setEndDate] = useState(new Date());
  const [isDateConfirm, setIsDateConfirm] = useState(false);
  const dateRef = useRef(null);
  const yearDropdownRef = useRef(null);
  const haveName = newTags?.some((val) => val?.type === "ownerTags");
  const [amountRange, setAmountRange] = useState("Absolute (amount)");
  const [amountRangeValue, setAmountRangeValue] = useState("");
  const [investmentRate, setInvestmentRate] = useState(
    "Bank of India Overnight Fund-Reg(G) - 6.62%"
  );
  const [investmentRateValue, setInvestmentRateValue] = useState(6.62);
  const [selectAmountRange, setSelectAmountRange] = useState(false);
  const [selectInvestmentRate, setSelectInvestmentRate] = useState(false);
  const [accountBalancesReportData, setAccountBalancesReportData] = useState(
    []
  );
  const [accountBalancesReportAllData, setAccountBalancesReportAllData] =
    useState([]);
  const [accountBalancesReportWeekData, setAccountBalancesReportWeekData] =
    useState([]);
  const [
    accountBalancesReportWeekAllData,
    setAccountBalancesReportWeekAllData,
  ] = useState([]);
  const [accountBalancesReportError, setAccountBalancesReportError] =
    useState("");
  const [accountBalancesReportWeekError, setAccountBalancesReportWeekError] =
    useState("");
  const [infoState, setInfoState] = useState(false);
  const [missedIncomeGraphData, setMissedIncomeGraphData] = useState([
    0, 0, 0, 0, 0, 0, 0,
  ]);
  const [actualIncomeGraphData, setActualIncomeGraphData] = useState([
    0, 0, 0, 0, 0, 0, 0,
  ]);
  const infoRef = useRef(null);

  const formatDate = (
    dateString,
    withComma = false,
    dateType = false,
    short = true
  ) => {
    if (dateType) {
      let date = new Date(dateString);
      const day = String(date?.getDate()).padStart(2, "0");
      const month = String(date?.getMonth() + 1).padStart(2, "0");
      const year = date?.getFullYear();

      return day + "-" + month + "-" + year;
    } else {
      return `${dateString?.getDate()} ${dateString?.toLocaleString("default", {
        month: short ? "short" : "long",
      })}${withComma ? "," : ""} ${dateString?.getFullYear()}`;
    }
  };

  const {
    ownerAccBalanceLoading,
    ownerAccBalanceError,
    ownerAccountAvailabletags,
    showSuccessToast,
    removeOwnerAccTagsLoading,
    getOwnerAccStatiticsLoading,
    getOwnerAccWeekStatiticsLoading,
  } = useSelector((state) => state.ownerAllVerification);
  const services = useSelector((state) => state.refreshToken.services);

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  const getOwnerDetaildata = async () => {
    setLoading(true);
    try {
      dispatch(checkUsertoken());
      let response;

      if (props?.popType == "ownerStatement") {
        response = await dispatch(ownerAccDetail(props?.ownerDetailId));
      } else {
        response = await dispatch(ownerAccDetail(id));
      }

      // If response is empty or has an error, navigate to 404 page
      if (!response?.payload || response?.error?.status === 404) {
        navigate("/*");
        return;
      }

      dispatch(
        beneViewAllAcc({
          bank_account_no: response?.payload?.owner_accounts[0]?.bank_acc_no,
          ifsc: response?.payload?.owner_accounts[0]?.ifsc_code,
          pan: response?.payload?.owner_accounts[0]?.pan_no,
          type: "internalAccount",
        })
      );

      setOwnerdetaildata(response?.payload?.owner_accounts[0]);

      if (
        response?.payload?.owner_accounts[0]?.tags &&
        response?.payload?.owner_accounts[0]?.tags.length > 0
      ) {
        const data = response?.payload?.owner_accounts[0].tags?.map((res) => {
          return { selected: true, value: res, type: "ownerTags" };
        });

        setNewTags(data);
        setLoading(true);
      }

      setLoading(false);

      // Existing navigation conditions
      if (response?.error?.message == "Request failed with status code 404") {
        navigate("/*");
      }

      if (response?.payload === undefined) {
        navigate("/feature_not_assigned");
      }
    } catch (error) {
      setLoading(false);
      navigate("/*");
    }
  };

  useEffect(() => {
    getOwnerDetaildata();
  }, [id]);

  useEffect(() => {
    getOwnerDetaildata();
    if (ownerAccountAvailabletags?.length == 0) {
      let availableTags = sessionStorage?.getItem("AvailableTags");

      const parsedTags = JSON?.parse(availableTags, ...newTags);
      const details = dispatch(setTags(parsedTags));
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (infoRef.current && !infoRef.current.contains(event.target)) {
        setInfoState(false);
      }

      if (dateRef.current && !dateRef.current.contains(event.target)) {
        setShowDatePicker(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleAddTags = async (item) => {
    try {
      dispatch(checkUsertoken());

      const selectedTags = newTags
        .filter((tag) => tag.selected === false)
        .map((tag) => tag.value);

      try {
      } catch (error) {}

      let response = await dispatch(
        addOwnerAccTags({
          owner_id: [id],
          tags: item?.type === "newAss" ? item?.data1 : [item],
        })
      );

      if (response?.payload?.status === true) {
        getOwnerDetaildata();
        setShowDisplay(false);
        // setSelectedTagOpenPop(false);
        // setLoading(true);
        setNewTags((prev) => [...prev, response?.meta?.arg?.tags]);
      } else {
        setLoading(false);
        toast.error(response?.payload?.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleRemoveTags = async (item) => {
    setLoading(true);
    if (removeOwnerAccTagsLoading) {
      setLoading(true);
    }

    try {
      dispatch(checkUsertoken());

      let param = Array.isArray(item) ? item : [item];
      const response = await dispatch(
        removeOwnerAccTags({ owner_id: [id], tags: param })
      );

      if (response?.payload?.status === true) {
        getOwnerDetaildata();
        setNewTags(response?.payload?.tags?.value || []);
        setLoading(false);
      } else {
        setLoading(false);
        // toast.error(response?.payload?.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error removing tag:", error);
    }
  };

  useEffect(() => {
    // Any logic that requires the side effect should be invoked here
    // For example, you can decide when to call `handleRemoveTags`
  }, [dispatch, id, getOwnerDetaildata, setNewTags, setLoading]);

  const handleToastClick = () => {
    toast.info("Currently in Development", toast_position);
  };

  const handleOwnerDetailsToast = () => {
    setSelectedTagOpenPop(true);
  };

  const gotHome = () => {
    navigate("/accounts/owner/dashboard");
  };

  const handleRefresh = async (ownerid) => {
    setPrevConnectedBal(ownerDetailData?.owner_account_balance);
    setRefreshId(ownerDetailData?.id);
    try {
      dispatch(checkUsertoken());
      setBalanceFailed(false);
      let response = await dispatch(getOwnerAccBalance(ownerid));
      if (response?.payload?.status == true) {
        setRefreshedConnectedId(id);
        setCurrentConnectedBal(
          response?.payload?.owner_accounts[0]?.owner_account_balance
        );
        setViewBalance(true);

        setShowDisplay(true);

        setOwnerdetaildata((prevState) => {
          return {
            ...prevState,
            owner_account_balance_last_fetched_at_in_words:
              response?.payload?.owner_accounts[0]
                ?.owner_account_balance_last_fetched_at_in_words,
            owner_account_balance:
              response?.payload?.owner_accounts[0]?.owner_account_balance,
            owner_account_balance_last_fetched_at:
              response.payload?.owner_accounts[0]
                .owner_account_balance_last_fetched_at,
            difference: Math.abs(
              Number(prevState.owner_account_balance) -
                Number(response.payload.owner_accounts[0].owner_account_balance)
            ),
            increaseOrDecrease:
              Number(prevState.owner_account_balance) >
              Number(response.payload.owner_accounts[0].owner_account_balance)
                ? "decreased"
                : Number(prevState.owner_account_balance) <
                  Number(
                    response.payload.owner_accounts[0].owner_account_balance
                  )
                ? "increased"
                : "",
          };
        });
      } else {
        setTimeout(() => {
          toast.dismiss();

          setShowDisplay(true);
          setBalanceFailed(true);
          toast.error(response?.error?.message, toast_position);
        }, 500);
      }
    } catch (error) {
      toast.error(error, toast_position);
      throw error;
    }
  };

  const isFavTagExist = ownerDetailData?.tags?.some(
    (tag) => tag === "favourite"
  );

  const handleFavourite = () => {
    if (isFavTagExist) {
      handleRemoveTags("favourite");
    } else {
      handleAddTags("favourite");
    }
  };

  const handleCloseModal = () => {
    setViewstatementOpenPop(false);
    setSelectedTagOpenPop(false);
    setNewTags((prev) => {
      return [...prev].map((result) => {
        return { selected: true, value: result?.value, type: result?.type };
      });
    });
  };
  const handleSelectedtag = () => {
    setSelectedTagOpenPop(true);
  };

  const handleViewStatement = () => {
    setViewstatementOpenPop(true);
  };

  useEffect(() => {
    if (currentConnectedBal > prevConnectedBal) {
      setIsMore(true);
    } else {
      setIsMore(false);
    }
  }, [currentConnectedBal, prevConnectedBal]);

  useEffect(() => {
    if (ownerAccBalanceLoading && !openHistory) {
      RefreshBalanceToast();
    }

    if (!ownerAccBalanceLoading && !ownerAccBalanceError) {
      setTimeout(() => {
        toast.dismiss();

        if (showSuccessToast && !openHistory) {
          setTimeout(() => {
            toast.success("Balance refreshed successfully", {
              position: "bottom-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            dispatch(setshowsuccestoast());
          }, 250);
        }
      }, 500);
    }
  }, [ownerAccBalanceLoading]);

  const toggleDetails = () => {
    setShowDetails(true);
    setShowStatistics(false);
    setShowAssignedTags(false);
  };

  const toggleStatistics = () => {
    setShowDetails(false);
    setShowStatistics(true);
    setShowAssignedTags(false);
    if (!showStatistics) {
      setStartDateState(new Date(new Date().setDate(1)));
      // setEndDateState(currentDate);
      setEndDateState(new Date());
      setStartDate(new Date(new Date().setDate(1)));
      setEndDate(new Date());
    }
  };

  const toggleAdditionalDetails = () => {
    setShowAssignedTags(true);
    setShowStatistics(false);
    setShowDetails(false);
  };

  // Statistics Logic Start ---------------------------

  const handleWeekData = (weekData, pastSevenDates) => {
    const updatedData = [...missedIncomeGraphData];

    pastSevenDates.forEach((item, index) => {
      const specDate = new Date(item);

      // Find the matching week date
      const matchedWeekData = weekData.find((data) => {
        const weekDate = new Date(data?.date);
        return (
          specDate.getDate() === weekDate.getDate() &&
          specDate.getMonth() === weekDate.getMonth() &&
          specDate.getFullYear() === weekDate.getFullYear()
        );
      });

      // If a match is found, use its daily_interest, else 0
      if (matchedWeekData) {
        if (amountRangeValue !== "") {
          updatedData[index] = formatTotalBalance(
            matchedWeekData?.daily_interest,
            amountRangeValue
          ).amount;
        } else {
          updatedData[index] = matchedWeekData?.daily_interest;
        }
      } else {
        updatedData[index] = 0;
      }
    });

    setMissedIncomeGraphData(updatedData);
  };

  const handleStatisticsData = async (
    start_Date,
    end_Date,
    interest_rate,
    type = "week"
  ) => {
    try {
      dispatch(checkUsertoken());
      let reportData = [];

      const handleResponse = (response, isWeek) => {
        if (response?.payload?.status === true) {
          reportData =
            response?.payload?.owner_account_details?.account_balances_report;

          if (isWeek) {
            setAccountBalancesReportWeekData(reportData);
            setAccountBalancesReportWeekAllData(response?.payload);
          } else {
            setAccountBalancesReportData(reportData);
            setAccountBalancesReportAllData(response?.payload);
          }
        } else {
          const error = response?.payload?.response?.data?.errors?.base?.[0];
          if (isWeek) {
            setAccountBalancesReportWeekData([]);
            setAccountBalancesReportWeekAllData([]);
            setAccountBalancesReportWeekError(error);
          } else {
            setAccountBalancesReportData([]);
            setAccountBalancesReportAllData([]);
            setAccountBalancesReportError(error);
          }
        }
      };

      let response;

      if (type !== "week") {
        response = await dispatch(
          getOwnerAccStatitics({
            id: id,
            start_date: start_Date,
            end_date: end_Date,
            // interest_rate: interest_rate,
          })
        );
        handleResponse(response, false);
      } else {
        response = await dispatch(
          getOwnerAccWeekStatitics({
            id: id,
            start_date: start_Date,
            end_date: end_Date,
            // interest_rate: interest_rate,
          })
        );
        handleResponse(response, true);
      }

      if (type === "week") {
        handleWeekData(reportData, pastSevenDates);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleDateChange = (dates) => {
    setIsDateConfirm(false);
    const [start, end] = dates;
    setStartDateState(start);
    setEndDateState(end);
  };

  const handleCancel = () => {
    setShowDatePicker(false);
    setStartDateState(new Date(new Date().setDate(1)));
    // setEndDateState(currentDate);
    setEndDateState(new Date());
    setStartDate(new Date(new Date().setDate(1)));
    setEndDate(new Date());
  };

  const handleConfirm = () => {
    // const todays = new Date();
    // if (
    //   todays.getFullYear() === endDateState.getFullYear() &&
    //   todays.getMonth() === endDateState.getMonth() &&
    //   todays.getDate() === endDateState.getDate()
    // ) {
    //   toast.error(
    //     "End date cannot be the same as current date.",
    //     toast_position
    //   );
    //   return;
    // }

    // const startdatetemp = startDateState;
    // let enddatetemp = new Date(startdatetemp);

    // enddatetemp.setMonth(startdatetemp.getMonth() + 6);
    // enddatetemp.setDate(enddatetemp.getDate() - 1);

    // if (endDateState > enddatetemp) {
    //   toast.error(
    //     "Start and end dates must be within a 6-month period.",
    //     toast_position
    //   );
    //   return;
    // }

    setIsDateConfirm(true);
    setShowDatePicker(false);
    setStartDate(startDateState);
    setEndDate(endDateState);
  };

  useEffect(() => {
    handleStatisticsData(
      formatDate(sixDaysAgo),
      formatDate(yesterdaysDate),
      investmentRateValue,
      "week"
    );
  }, [investmentRateValue, amountRangeValue]);

  useEffect(() => {
    if (showStatistics) {
      handleStatisticsData(
        formatDate(startDateState),
        formatDate(endDateState),
        investmentRateValue,
        "month"
      );
    }
  }, [investmentRateValue, endDate, startDate, amountRangeValue]);

  function getPastSevenDaysDates() {
    // Six days ago
    const sixDaysAgo = new Date(currentDate);
    sixDaysAgo.setDate(currentDate.getDate() - 6);

    const yesterdaysDate = new Date(currentDate);

    // Function to format the date in "23 Feb" format
    const formatDateDays = (date) => {
      const day = date.getDate();
      const month = date.toLocaleString("default", { month: "short" }); // Get short month name
      return `${day} ${month}`;
    };

    const pastSevenDays = [];
    const pastSevenDates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(sixDaysAgo);
      date.setDate(sixDaysAgo.getDate() + i);
      pastSevenDays.push(formatDateDays(date));
      pastSevenDates.push(date);
    }

    return {
      sixDaysAgo: sixDaysAgo,
      yesterdaysDate: yesterdaysDate,
      pastSevenDays: pastSevenDays,
      pastSevenDates: pastSevenDates,
    };
  }

  const { sixDaysAgo, yesterdaysDate, pastSevenDays, pastSevenDates } =
    getPastSevenDaysDates();

  // Statistics Logic End ---------------------------

  return (
    <>
      <div
        style={{
          height: props?.popType == "ownerStatement" ? "92vh" : "100vh",
          overflowX: "hidden",
          overflowY:
            viewStatementOpenPop || selectedTagOpenPop ? "hidden" : "auto",
        }}
      >
        <div>
          {/* {viewStatementOpenPop && (
            <div className="fixed top-0 w-full h-full bg-[#000] bg-opacity-80 flex items-center justify-center z-[9999]">
              {
                <OwnerViewStatementModal
                  statementId={id}
                  allData={ownerDetailData}
                  onClose={handleCloseModal}
                />
              }
            </div>
          )} */}
          {viewStatementOpenPop && (
            <div
              style={{ zIndex: "2147483647" }}
              className="fixed top-0 left-0 w-full h-full bg-custom-backdrop bg-opacity-80"
            >
              <div
                className="fixed top-0 left-0 w-screen h-screen z-[9998] "
                style={{ backgroundColor: "#000000", opacity: "0.8" }}
              ></div>
              <div
                style={{ backgroundColor: "#1d3a6d" }}
                className="main_pop_div relative"
              >
                <div className="absolute top-0 bg-Blue_3 z-[99999] px-[20px] left-0 h-[70px] flex justify-between items-center w-full">
                  <p className="popup-header">View account statement</p>
                  <div
                    onClick={() => setViewstatementOpenPop(false)}
                    className="h-[30px] w-[30px] flex justify-center items-center bg-Blue_5 cursor-pointer rounded-[5px] hover:bg-Blue_5_hover"
                  >
                    <img className="h-[15px] w-[15px]" src={Close} alt="" />
                  </div>
                </div>
                <OwnerAllStatement
                  popType={"ownerStatement"}
                  ownerDetailId={id}
                  ownerDetailData={ownerDetailData}
                />
              </div>
            </div>
          )}

          {openHistory && (
            <div className="fixed top-0 left-0 w-screen h-screen z-[9998] bg-[#000] bg-opacity-80">
              <TransactionHistory
                onClose={() => setOpenHistory(false)}
                Owner_account_id={ownerDetailData?.id}
                Owner_account_number={ownerDetailData?.bank_acc_no}
                OwnerDetail={true}
                BeneAccId={beneViewAllAccData?.internal_accounts?.[0]?.id}
                popuptype={"Internal transaction"}
              />
            </div>
          )}
          {openBeneHistory && (
            <div className="fixed top-0 left-0 w-screen h-screen z-[9998] bg-[#000] bg-opacity-80">
              <TransactionHistory
                onClose={() => setOpenBeneHistory(false)}
                Owner_account_id={ownerDetailData?.id}
                Owner_account_number={ownerDetailData?.bank_acc_no}
                OwnerDetail={true}
                BeneAccId={beneViewAllAccData?.internal_accounts?.[0]?.id}
                popuptype={"Beneficiary transaction"}
              />
            </div>
          )}
          {minBalance && (
            <div className="fixed w-full h-full flex items-center bg-Primary_black justify-center z-[9998] bg-opacity-80">
              <MinimumBal
                setMinBalance={setMinBalance}
                amount={amount}
                setAmount={setAmount}
              />
            </div>
          )}
          {selectedTagOpenPop && (
            <div
              className="fixed top-0 w-full h-full bg-[#000] bg-opacity-80 flex items-center justify-center z-[9999]"
              onClick={handleCloseModal}
            >
              {
                // <OwnerDetailsSelectTag
                //   onClose={handleCloseModal}
                //   handleAddTags={handleAddTags}
                //   existingTags={existingTags}
                //   setExistingTags={setExistingTags}
                //   handleRemoveTags={handleRemoveTags}
                //   newTags={newTags}
                //   setNewTags={setNewTags}
                //   ownerExistingTag={ownerDetailData?.tags}
                // />

                <UserTag
                  onClose={handleCloseModal}
                  handleAddTags={handleAddTags}
                  existingTags={existingTags}
                  setExistingTags={setExistingTags}
                  handleRemoveTags={handleRemoveTags}
                  newTags={newTags}
                  setNewTags={setNewTags}
                  ownerExistingTag={ownerDetailData?.tags}
                  AllTags={AllTags}
                  setAllTags={setAllTags}
                  loading={loading}
                  setLoading={setLoading}
                  getOwnerDetaildata={getOwnerDetaildata}
                  id={id}
                  ownerDetailData={ownerDetailData}
                  type={"ownerDetails"}
                />
              }
            </div>
          )}
        </div>
        {loading ? (
          <div className="owner-loader-container justify-center items-center flex">
            <img src={dataLoading} alt="" />
          </div>
        ) : (
          <>
            {isMobileView && (
              <>
                <div className=" h-[60px] w-full p-[10px]  flex fixed z-10 items-center bg-Blue_6 ">
                  <img
                    src={arrow_left_white}
                    className=" ml-[10px] w-[16px] h-[16px] cursor-pointer "
                    onClick={gotHome}
                  />
                  <div className=" flex flex-col ml-5">
                    <p className=" text-[16px] font-[400px] font-sans text-[#ffffff]">
                      {" "}
                      Owner
                    </p>
                    <p className=" text-[12px] font-sans font-[600px] text-Primary_grey">
                      Account details
                    </p>
                  </div>
                </div>
                <div className="content-page-Responsive ">
                  <div className="container-Responsive">
                    <div className="acc-section-responsive">
                      <div className="major-div-responsive">
                        <div className="individual-bank-detail-responsive">
                          <div className="individual-account-Responsive">
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={ownerDetailData?.name}
                              arrow
                              placement="bottom"
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                },
                              }}
                            >
                              <p className="text-Primary_white text-base text-left font-sans font-normal capitalize block">
                                {ownerDetailData?.name?.toLowerCase()}
                              </p>
                            </Tooltip>

                            <div className="acc-number-label mt-1 text-xs">
                              Account holder name
                            </div>
                          </div>
                          <div className="acc-balance-Responsive ">
                            <div className=" flex flex-col w-full ">
                              <div>
                                {ownerDetailData?.owner_account_balance ? (
                                  <div className="acc-balance-content-Reponsive">
                                    <div
                                      className="text-sm text-Primary_white text-[16px] "
                                      style={{
                                        fontWeight: "600",
                                        color:
                                          ownerDetailData?.owner_account_balance >=
                                          0
                                            ? "var(--Settled)"
                                            : "var(--Failed)",
                                        paddingRight: "10px",
                                      }}
                                    >
                                      ₹{" "}
                                      {
                                        formatTotalBalance(
                                          Number(
                                            ownerDetailData?.owner_account_balance
                                          )
                                        ).amount
                                      }
                                    </div>
                                    <div className=" border border-r-1 flex flex-col h-[14px] mt-[3px] mr-[4px]"></div>

                                    <span
                                      style={{
                                        position: "relative",
                                        left: "-15px",
                                        marginLeft: "5px",
                                        top: "-5px",
                                      }}
                                    >
                                      <AnimationUpAndDown
                                        loader={ownerAccBalanceLoading}
                                        currentId={refreshedConnectedId}
                                        previousId={id}
                                        currentBalance={currentConnectedBal}
                                        previousBalance={prevConnectedBal}
                                        refreshType={"single"}
                                        classType={"owner_details"}
                                      />
                                    </span>

                                    <div
                                      className={`flex items-center justify-center  ${
                                        !services?.includes(
                                          "VAccountBalance"
                                        ) && !services?.includes("Admin")
                                          ? "opacity-50"
                                          : ""
                                      } `}
                                    >
                                      {loaderr ? (
                                        <>
                                          <div className=" flex items-center mt-[2px]">
                                            <BalanceLoader
                                              loader={ownerAccBalanceLoading}
                                              type={"createTxn"}
                                              setAccId={setRefreshId}
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="flex">
                                            <img
                                              onClick={() => {
                                                if (
                                                  services?.includes(
                                                    "VAccountBalance"
                                                  ) ||
                                                  services?.includes("Admin")
                                                ) {
                                                  if (
                                                    ownerDetailData?.connected_banking ===
                                                    "Y"
                                                  ) {
                                                    setAccId(
                                                      ownerDetailData?.id
                                                    );
                                                    handleRefresh(
                                                      ownerDetailData?.id
                                                    );
                                                  }
                                                } else {
                                                  navigate(
                                                    "/feature_not_assigned",
                                                    {
                                                      state: {
                                                        unavailableService:
                                                          "VAccountBalance",
                                                      },
                                                    }
                                                  );
                                                }
                                                setAccId(ownerDetailData?.id);
                                                handleRefresh(
                                                  ownerDetailData?.id
                                                );
                                                setLoaderr(true);
                                              }}
                                              className=" flex items-center justify-center cursor-pointer mt-[4px] w-[14px] h-[14px]"
                                              src={refresh_white}
                                            />
                                            <span className=" font-sans font-[600px] mt-[1px]  ml-[5px] text-[12px] text-Primary_grey">
                                              {" "}
                                              Refresh
                                            </span>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="acc-balance-content-responsive">
                                    <div
                                      className={`text-Primary_white text-base font-sans font-normal`}
                                    >
                                      N/A
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="acc-number-balance  text-xs">
                                {ownerDetailData?.owner_account_balance ? (
                                  formatTotalBalance(
                                    Number(
                                      ownerDetailData?.owner_account_balance
                                    )
                                  ).prefix == "crores" ? (
                                    <p>
                                      Balance - In crores{" "}
                                      {(ownerDetailData?.connected_banking ===
                                        "Y" ||
                                        "N") &&
                                      ownerDetailData?.active_status !== "Y"
                                        ? `- updated  ${ownerDetailData?.owner_account_balance_last_fetched_at_in_words}`
                                        : null}
                                    </p>
                                  ) : (
                                    <p>
                                      Balance - updated{" "}
                                      {
                                        ownerDetailData?.owner_account_balance_last_fetched_at_in_words
                                      }
                                    </p>
                                  )
                                ) : (
                                  <>
                                    <p>Balance </p>
                                  </>
                                )}
                              </div>

                              {viewBalance && (
                                <>
                                  <div className=" view-Balance h-[40px] mt-[20px] pt-[10px] pb-[10px] ">
                                    {ownerDetailData?.connected_banking ==
                                      "Y" &&
                                    showDisplay &&
                                    !balanceFailed ? (
                                      <div className="acc-status-detail-page-responsive">
                                        <div className="acc-balance-content-responsive">
                                          <div
                                            className="text-[16px]"
                                            style={{
                                              fontWeight: "600",

                                              paddingRight: "20px",
                                              color:
                                                ownerDetailData?.increaseOrDecrease ===
                                                "increased"
                                                  ? "var(--Settled)"
                                                  : ownerDetailData?.increaseOrDecrease ===
                                                    "decreased"
                                                  ? "var(--Failed)"
                                                  : "var(--Primary_white)",
                                            }}
                                          >
                                            ₹{" "}
                                            {
                                              formatTotalBalance(
                                                ownerDetailData?.difference
                                              ).amount
                                            }
                                          </div>
                                          <div className="acc-number-label mt-[2px] ">
                                            Balance{" "}
                                            {ownerDetailData?.increaseOrDecrease ==
                                            ""
                                              ? "increased"
                                              : ownerDetailData?.increaseOrDecrease}{" "}
                                            by{" "}
                                            {formatTotalBalance(
                                              ownerDetailData?.difference
                                            ).prefix == "crores"
                                              ? "- in crores"
                                              : ""}
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          {/* <div
                            className={`individual-account-Responsive ${
                              ownerDetailData?.connected_banking === "N"
                                ? " opacity-50"
                                : " opacity-100"
                            }`}
                            style={{
                              borderTop: "2px solid var(--Blue_3)",
                              borderBottom: "0px",
                              padding: "10px 20px",
                            }}
                          >
                            <div className={`flex items-center`}>
                              <p className="text-Primary_white">
                                ₹ <FormatAmount price={amount} />
                              </p>
                              <div
                                className={`w-5 h-5 p-[5px] bg-Blue_3 rounded-md ml-4 ${
                                  ownerDetailData?.connected_banking === "N"
                                    ? "cursor-not-allowed"
                                    : "cursor-pointer"
                                }`}
                                onClick={() => {
                                  if (
                                    ownerDetailData?.connected_banking === "Y"
                                  ) {
                                    setMinBalance(true);
                                  } else {
                                    return;
                                  }
                                }}
                              >
                                <img src={editIcon} />
                              </div>
                            </div>
                            <div className="acc-number-label mt-1 text-xs">
                              Minimum balance
                            </div>
                          </div> */}
                        </div>

                        <div className="bank-details-Responsive">
                          <div className="accno-details-Reponsive ">
                            <div className=" flex flex-col py-[15px]">
                              <span className="accno-response">
                                {formatAccountNumber(
                                  ownerDetailData?.bank_acc_no
                                )}
                              </span>
                              <div className="acc-number-label text-xs">
                                Account number
                              </div>
                            </div>

                            <div className=" recipient-box5-responsive  ">
                              <Box
                                className="text-center  opacity-50  h-full flex flex-col justify-center gap-1 p-2 "
                                sx={{
                                  opacity:
                                    ownerDetailData?.connected_banking == "Y"
                                      ? "1"
                                      : "0.5",
                                  cursor:
                                    ownerDetailData?.connected_banking == "Y"
                                      ? "pointer"
                                      : "default",

                                  "&:hover": {
                                    backgroundColor:
                                      ownerDetailData?.connected_banking === "Y"
                                        ? "var(--Blue_8)"
                                        : "inherit",
                                  },
                                }}
                                onClick={() => {
                                  if (
                                    ownerDetailData?.connected_banking == "Y"
                                  ) {
                                    // handleViewStatement();
                                    handleToastClick();
                                  }
                                }}
                              >
                                <div className="recipient-image-div opacity-50  flex justify-center">
                                  <Box className="flex justify-center w-10">
                                    <img
                                      src={view_statement}
                                      alt="document"
                                      className="recipient-img"
                                    />
                                  </Box>
                                </div>
                                <div className="flex justify-center px-2">
                                  <Box className="image-label h-8/12 self-center antialiased truncate...">
                                    View statement
                                  </Box>
                                </div>
                              </Box>
                            </div>
                          </div>

                          <div
                            className={`ifsc-div-main-Resposive  flex flex-row`}
                          >
                            <div
                              onClick={() => {
                                if (services?.includes("VIFSC")) {
                                  // navigate(
                                  //   `/accounts/owner/owner_account_details/IFSC_Details/${ownerDetailData?.ifsc_code}`
                                  // );
                                  handleToastClick();
                                } else {
                                  navigate("/feature_not_assigned", {
                                    state: {
                                      unavailableService: "VIFSC",
                                    },
                                  });
                                }
                              }}
                              className={`ifsc-div-content-Responsive   ${
                                services?.includes("VIFSC")
                                  ? "cursor-pointer   "
                                  : " cursor-not-allowed"
                              }  `}
                            >
                              <div
                                className={`${
                                  services?.includes("VIFSC")
                                    ? "h-full w-[120px]"
                                    : ""
                                }`}
                              >
                                <div className="ifsc-response">
                                  {ownerDetailData?.ifsc_code}
                                </div>
                                <div className="ifsc-code-element">
                                  IFSC code
                                </div>
                              </div>

                              <div className="grid place-content-center w-[20px] h-[22px] mt-1 bg-Blue_3 rounded-md">
                                <img
                                  src={arrowRight}
                                  alt="document"
                                  className={`w-[15px] h-[15px] ${
                                    services?.includes("VIFSC")
                                      ? "cursor-pointer"
                                      : "cursor-default"
                                  }`}
                                />
                              </div>
                            </div>

                            <div className="bank-logo-details-responsive ">
                              <div className="logo-bank-container-responsive">
                                <img
                                  src={require("../../../../assets/IfscLogo/" +
                                    IfscCodeMapper(ownerDetailData?.ifsc_code))}
                                  alt="bank"
                                  className="detailed-bank-logo-responsive"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="acc-status-main-responsive">
                          <div className="acc-connected-responsive">
                            <div
                              className={`text-base ${
                                ownerDetailData?.connected_banking === "Y"
                                  ? "active-status"
                                  : ownerDetailData?.connected_banking === "N"
                                  ? "not-active-status font-semibold"
                                  : ""
                              }`}
                            >
                              {ownerDetailData?.connected_banking === "Y"
                                ? "Active"
                                : "Y" &&
                                  ownerDetailData?.connected_banking === "N"
                                ? "Inactive"
                                : ""}
                            </div>
                            <div className="acc-number-label mt-[1px]">
                              Connected banking
                            </div>
                          </div>
                        </div>
                        <div className="h-[10px]  bg-Blue_2"> </div>
                        <div className="h-[39px] text-[14px] w-full bg-[#314D7E] text-left text-Primary_white font-semibold px-[15px] py-[10px]">
                          Quick Actions
                        </div>
                        <div className=" h-[96px] w-auto flex border-b-[2px solid #244277]">
                          <div
                            style={{ border: "none", width: "34.3%" }}
                            className="recipient-box2 flex-grow"
                          >
                            <Box
                              className=" opacity-50 mt-2  text-center  flex flex-col justify-center gap-1 p-2"
                              onClick={handleToastClick}
                            >
                              <div className="recipient-image-div flex justify-center  ">
                                <Box className="flex justify-center w-10">
                                  <img
                                    src={internalTransaction}
                                    alt="document"
                                    className="recipient-img"
                                  />
                                </Box>
                              </div>
                              <div className="flex justify-center px-2">
                                <Box className="image-label h-8/12 w-20 self-center antialiased truncate...">
                                  Initiate internal transaction
                                </Box>
                              </div>
                            </Box>
                          </div>
                          <div
                            style={{ margin: "0", height: "95px" }}
                            className="ownerdetail-border-div"
                          ></div>{" "}
                          <div
                            style={{ border: "none", width: "34.3%" }}
                            className={`recipient-boxTrans-resp flex-grow ${
                              services?.includes("IVAccount" || "Admin")
                                ? "hover:bg-Blue_8 cursor-pointer"
                                : "cursor-not-allowed"
                            } `}
                          >
                            <Box
                              className={`mt-2  text-center  flex flex-col justify-center gap-1 p-2 ${
                                services?.includes("IVAccount" || "Admin")
                                  ? "hover:bg-Blue_8 cursor-pointer"
                                  : "opacity-50 cursor-not-allowed"
                              }`}
                              onClick={() => {
                                if (
                                  services?.includes("IVAccount" || "Admin")
                                ) {
                                  if (
                                    beneViewAllAccData?.internal_accounts?.[0]
                                      ?.id
                                  ) {
                                    navigate(
                                      `/accounts/internal_account/details/${beneViewAllAccData?.internal_accounts?.[0]?.id}`
                                    );
                                  } else {
                                    toast.error(
                                      "Unable to get Id",
                                      toast_position
                                    );
                                  }
                                } else {
                                  navigate("/feature_not_assigned", {
                                    state: {
                                      unavailableService: "IVAccount",
                                    },
                                  });
                                }
                              }}
                            >
                              <div className="recipient-image-div flex justify-center  ">
                                <Box className="flex justify-center w-10">
                                  <img
                                    src={LinkedLogo}
                                    alt="document"
                                    className="recipient-img"
                                  />
                                </Box>
                              </div>
                              <div className="flex justify-center px-2">
                                <Box className="image-label h-8/12 w-20 self-center antialiased truncate...">
                                  View linked internal account
                                </Box>
                              </div>
                            </Box>
                          </div>
                          <div
                            style={{ margin: "0", height: "95px" }}
                            className="ownerdetail-border-div"
                          ></div>{" "}
                          <div
                            style={{
                              border: "none",
                              cursor:
                                !services?.includes("COwnerTag") &&
                                !services?.includes("DOwnerTag")
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                            className=" recipient-box6-responsive flex-grow"
                          >
                            <Box
                              className={`text-center mt-2 ${
                                !services?.includes("COwnerTag") &&
                                !services?.includes("DOwnerTag")
                                  ? "opacity-30"
                                  : " opacity-100"
                              } flex flex-col justify-center gap-1  p-2`}
                              onClick={() => {
                                if (
                                  !services?.includes("COwnerTag") &&
                                  !services?.includes("DOwnerTag")
                                ) {
                                  navigate("/feature_not_assigned");
                                }
                                handleOwnerDetailsToast();
                              }}
                            >
                              <div className="recipient-image-div  flex justify-center">
                                <Box className="flex justify-center w-10">
                                  <img
                                    src={assigntag}
                                    alt="document"
                                    className="recipient-img"
                                  />
                                </Box>
                              </div>
                              <div className="flex justify-center px-2  ">
                                <Box>
                                  <div className=" image-label h-8/12 self-center antialiased truncate... w-6">
                                    Assign tag
                                  </div>
                                </Box>
                              </div>
                            </Box>
                          </div>
                        </div>
                        <div className="h-[10px]  bg-Blue_2"> </div>
                        <div className=" h-[96px] w-auto flex">
                          <div className="opacity-50 recipient-box3 flex-grow">
                            <Box
                              className={`text-center h-full flex flex-col justify-center gap-1 p-2 ${
                                services?.includes("VBeneficiaryTxn")
                                  ? "hover:bg-Blue_8 cursor-pointer"
                                  : "opacity-50 cursor-not-allowed"
                              }`}
                              onClick={() => {
                                if (services?.includes("VBeneficiaryTxn")) {
                                  //setOpenBeneHistory(true)
                                  handleToastClick();
                                } else {
                                  navigate("/feature_not_assigned", {
                                    state: {
                                      unavailableService: "VBeneficiaryTxn",
                                    },
                                  });
                                }
                              }}
                            >
                              <div className="recipient-image-div flex justify-center">
                                <Box className="flex justify-center w-10">
                                  <img
                                    src={beneHistory}
                                    alt="document"
                                    className="recipient-img"
                                  />
                                </Box>
                              </div>
                              <div className="flex justify-center px-2">
                                <Box className="image-label h-8/12 self-center antialiased truncate...">
                                  Beneficiary
                                </Box>
                              </div>
                            </Box>
                          </div>
                          <div
                            style={{ margin: "0", height: "95px" }}
                            className="ownerdetail-border-div"
                          ></div>{" "}
                          <div
                            className={`recipient-boxTrans h-[96px] flex-grow ${
                              services?.includes("VInternalTxn")
                                ? "hover:bg-Blue_8 cursor-pointer"
                                : "cursor-not-allowed"
                            }`}
                          >
                            <Box
                              className={`text-center h-full flex flex-col justify-center gap-1 p-2 ${
                                services?.includes("VInternalTxn")
                                  ? "hover:bg-Blue_8 cursor-pointer"
                                  : "opacity-50 cursor-not-allowed"
                              }`}
                              onClick={() => {
                                if (services?.includes("VInternalTxn")) {
                                  setOpenHistory(true);
                                } else {
                                  navigate("/feature_not_assigned", {
                                    state: {
                                      unavailableService: "VInternalTxn",
                                    },
                                  });
                                }
                              }}
                            >
                              <div className="recipient-image-div flex justify-center">
                                <Box className="flex justify-center w-10">
                                  <img
                                    src={transactionHistory}
                                    alt="document"
                                    className="recipient-img"
                                  />
                                </Box>
                              </div>
                              <div className="flex justify-center px-2">
                                <Box className="image-label h-8/12 self-center antialiased truncate...">
                                  Internal
                                </Box>
                              </div>
                            </Box>
                          </div>
                        </div>
                        <div className="bg-Blue_8 h-[20px]">
                          <p className="mx-auto leading-[20px] text-center w-[124px] font-semibold text-Primary_white text-[10px]">
                            Transaction history
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="details-div-section-responsive ">
                    <div className="details-section-responsive">
                      <div className="buttonsforOwnerdetails-responsive">
                        <button
                          className={`DetailsOwnerButton-responsive w-20 h-10  ${
                            showDetails ? "effective" : "hover:bg-[#A3A3A3]"
                          }`}
                          onClick={toggleDetails}
                        >
                          <span
                            className={`text-[12px] text-center font-sans  ${
                              showDetails
                                ? "text-Detail_chip font-semibold"
                                : "text-Primary_white"
                            } `}
                          >
                            Details
                          </span>
                        </button>
                        <button
                          className={`DetailsOwnerButton-responsive w-20 h-10                           
                              ${
                                showStatistics
                                  ? "effective"
                                  : ownerDetailData?.connected_banking ===
                                      "Y" &&
                                    (services?.includes("Admin") ||
                                      services?.includes("VAccountBalance"))
                                  ? "hover:bg-[#A3A3A3]"
                                  : ownerDetailData?.connected_banking ===
                                      "Y" &&
                                    (!services?.includes("Admin") ||
                                      !services?.includes("VAccountBalance"))
                                  ? "opacity-75"
                                  : " opacity-75"
                              }`}
                          onClick={() => {
                            if (
                              ownerDetailData?.connected_banking === "Y" &&
                              (services?.includes("Admin") ||
                                services?.includes("VAccountBalance"))
                            ) {
                              // toggleStatistics();
                              // handleStatisticsData(
                              //   formatDate(startDateState),
                              //   formatDate(endDateState),
                              //   null,
                              //   "month"
                              // );
                              // handleStatisticsData(
                              //   formatDate(startWeekDate),
                              //   formatDate(endWeekDate),
                              //   investmentRateValue,
                              //   "week"
                              // );
                              toast.info(
                                "Feature coming soon for mobile!",
                                toast_position
                              );
                            } else if (
                              ownerDetailData?.connected_banking === "Y" &&
                              (!services?.includes("Admin") ||
                                !services?.includes("VAccountBalance"))
                            ) {
                              navigate("/feature_not_assigned", {
                                state: {
                                  unavailableService: "VAccountBalance",
                                },
                              });
                            }
                          }}
                        >
                          <span
                            className={`text-[12px] text-center font-sans  ${
                              showStatistics
                                ? "text-Detail_chip font-semibold"
                                : "text-Primary_white"
                            } `}
                          >
                            Statistics
                          </span>
                        </button>
                        <button
                          className={`AssignedButton-responsive w-[7rem] h-7 ${
                            showAssignedTags
                              ? "effective"
                              : "hover:bg-[#A3A3A3]"
                          }`}
                          onClick={toggleAdditionalDetails}
                        >
                          <span
                            className={`text-[12px] text-center font-sans ${
                              showAssignedTags
                                ? "text-Detail_chip font-semibold"
                                : "text-Primary_white"
                            }`}
                          >
                            Assigned tags
                          </span>
                        </button>
                      </div>
                      {showDetails ? (
                        <div
                          key={ownerDetailData?.id}
                          className=" bank-card-row-responsive  "
                        >
                          <div className="bank-detail-card-responsive  w-1/4 ">
                            <h4 className="  text-[16px] font-[600px] font-sans mb-[10px] text-Primary_grey font-semibold">
                              Bank Details
                            </h4>

                            <div className="w-50 h-50 acc-type-section-div">
                              <div className="data-div">
                                {ownerDetailData?.account_type &&
                                  ownerDetailData.account_type
                                    .charAt(0)
                                    .toUpperCase() +
                                    ownerDetailData.account_type
                                      .slice(1)
                                      .toLowerCase()}
                              </div>
                              <div className="acc-type-div">Account type</div>
                            </div>
                          </div>

                          <div className="support-detail-card-responsive  w-1/4 mb-[30px]">
                            <div className="w-50 h-50">
                              <h4 className="mb-[10px] text-Primary_grey font-semibold text-[16px] font-[600px] font-sans">
                                Owner Details
                              </h4>
                              <div className="pan-detail-main-div-responsive ml-[-5px] flex row">
                                <div
                                  onClick={() => {
                                    if (services?.includes("VPAN")) {
                                      // navigate(`/settings/PAN_Verification`, {
                                      //   state: ownerDetailData?.pan_no,
                                      // });
                                      toast.info(
                                        "Feature coming soon for mobile!",
                                        toast_position
                                      );
                                    } else {
                                      navigate("/feature_not_assigned", {
                                        state: {
                                          unavailableService: "VPAN",
                                        },
                                      });
                                    }
                                  }}
                                  className={`pan-div-content-responsive relative   ${
                                    services?.includes("VPAN")
                                      ? "cursor-pointer"
                                      : " cursor-not-allowed"
                                  }  `}
                                >
                                  <div
                                    className={`${
                                      services?.includes("VPAN")
                                        ? "h-full w-[130px]"
                                        : ""
                                    }`}
                                  >
                                    <div className="pan-response">
                                      {ownerDetailData?.pan_no}
                                    </div>
                                    <div className="pan-number-element">
                                      PAN number
                                    </div>
                                  </div>

                                  <div className="grid place-content-center w-[15px] h-[15px] bg-Blue_3 rounded-md absolute right-[5px] mt-[8px]">
                                    <img
                                      src={arrowRight}
                                      alt="document"
                                      className={`w-[15px] h-[15px] ${
                                        services?.includes("VPAN")
                                          ? "cursor-pointer"
                                          : "cursor-default"
                                      }`}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="ownerdetail-border-div-bottom-responsive"></div>{" "}
                              <div className="gstin-detail-main-div-responsive ml-[-5px] flex row">
                                <div
                                  onClick={() => {
                                    if (services?.includes("VGSTIN")) {
                                      // navigate(
                                      //   `/accounts/beneficiary_account/details/GST_details/${ownerDetailData?.gstin_detail}`
                                      // );
                                      toast.info(
                                        "Feature coming soon for mobile!",
                                        toast_position
                                      );
                                    } else {
                                      navigate("/feature_not_assigned", {
                                        state: {
                                          unavailableService: "VGSTIN",
                                        },
                                      });
                                    }
                                  }}
                                  className={`pan-div-content-responsive relative   ${
                                    services?.includes("VGSTIN")
                                      ? "cursor-pointer"
                                      : "cursor-not-allowed"
                                  }  `}
                                >
                                  <div
                                    className={`${
                                      services?.includes("VGSTIN")
                                        ? "h-full w-[130px]"
                                        : ""
                                    }`}
                                  >
                                    <div className="pan-response">
                                      {ownerDetailData?.gstin_detail}
                                    </div>
                                    <div className="pan-number-element">
                                      GST number
                                    </div>
                                  </div>

                                  <div className="grid place-content-center w-[15px] h-[15px] bg-Blue_3 rounded-md absolute right-[5px] mt-[8px]">
                                    <img
                                      src={arrowRight}
                                      alt="document"
                                      className={`w-[15px] h-[15px] ${
                                        services?.includes("VGSTIN")
                                          ? "cursor-pointer"
                                          : "cursor-default"
                                      }`}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="ownerdetail-border-div-bottom-responsive"></div>{" "}
                              <div className="mt-[8px] pl-[6px]">
                                <div
                                  id="email-id-firstLetter"
                                  className="data-div"
                                >
                                  {ownerDetailData?.email_id}
                                </div>
                                <div className="owner-div">Entity email ID</div>
                              </div>
                            </div>
                          </div>
                          <div className="support-detail-card-responsive  w-1/4 mb-[30px]">
                            <div className="w-50 h-50">
                              <h4 className="title2 text-[16px] font-[600px] font-sans">
                                Support information
                              </h4>
                            </div>
                            <div className="w-50 h-50">
                              <div className=" flex flex-row mt-2 justify-between">
                                <div id="id-firstLetter" className="data-div">
                                  {ownerDetailData?.id}
                                </div>
                              </div>
                              <div className="pb-3 supp-info-div">
                                Owner account ID
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className=" assigned-card-row-responsive gap-10">
                          <div
                            className="assigned-detail-card-responsive   w-1/4 flex flex-row flex-wrap"
                            style={{ height: haveName > 0 ? "auto" : "66px" }}
                          >
                            {haveName ? (
                              newTags
                                ?.filter((tag) => tag.type === "ownerTags")
                                ?.map((tag) => {
                                  return (
                                    <>
                                      <Box
                                        className=" rounded-xl"
                                        style={{
                                          height: "26px !important",
                                          text: "12px",
                                          background: "var(--Blue_7)",
                                          margin: "5px 10px 5px 0",
                                          padding: "0px 10px 5px 9px",
                                          color: "var(--Primary_white)",
                                          width: "auto !important",
                                          cursor: "default",
                                        }}
                                        key={tag?.value}
                                      >
                                        <>
                                          <span
                                            id=" id-textTag"
                                            style={{
                                              fontSize: "12px",
                                              paddingRight: "5px",
                                            }}
                                          >
                                            {tag?.value}
                                          </span>
                                        </>
                                      </Box>
                                    </>
                                  );
                                })
                            ) : (
                              <p className="NotagsAssigned">
                                No tags assigned to this account.
                              </p>
                            )}

                            {!isMobileView && (
                              <div
                                className="editTag"
                                onClick={() => handleSelectedtag()}
                              >
                                <img
                                  src={editIcon}
                                  alt="editIcon"
                                  className="edit-icon"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            {!isMobileView && (
              <div
                style={{
                  marginTop: props?.popType == "ownerStatement" ? "0" : "72px",
                  paddingTop: props?.popType == "ownerStatement" ? "40px" : "0",
                  height:
                    props?.popType == "ownerStatement" ? "340px" : "300px",
                }}
                className="content-page relative"
              >
                <div className="ownerDetail-container">
                  <div className="acc-section">
                    <div className="major-div">
                      <div className="individual-bank-detail">
                        <div className="w-full flex">
                          <div className="individual-account">
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={ownerDetailData?.name}
                              arrow
                              placement="bottom"
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                },
                              }}
                            >
                              <p className="text-Primary_white text-base text-left font-sans font-normal capitalize w-[320px] block">
                                {ownerDetailData?.name?.toLowerCase()}
                              </p>
                            </Tooltip>

                            <div className="acc-number-label mt-1 text-xs">
                              Account holder name
                            </div>
                          </div>
                          <div className="acc-balance mt-[-4px]">
                            {ownerDetailData?.owner_account_balance ? (
                              <div className="acc-balance-content">
                                <div
                                  className=" refresh-amt-div text-[16px]"
                                  style={{
                                    fontWeight: "600",
                                    color:
                                      ownerDetailData?.owner_account_balance >=
                                      0
                                        ? "var(--Settled)"
                                        : "var(--Failed)",
                                    paddingRight: "20px",
                                  }}
                                >
                                  ₹{" "}
                                  {
                                    formatTotalBalance(
                                      Number(
                                        ownerDetailData?.owner_account_balance
                                      )
                                    ).amount
                                  }
                                </div>

                                <span
                                  style={{
                                    position: "relative",
                                    left: "-30px",
                                    marginLeft: "5px",
                                    top: "-5px",
                                  }}
                                >
                                  <AnimationUpAndDown
                                    loader={ownerAccBalanceLoading}
                                    currentId={refreshedConnectedId}
                                    previousId={id}
                                    currentBalance={currentConnectedBal}
                                    previousBalance={prevConnectedBal}
                                    refreshType={"single"}
                                    classType={"owner_details"}
                                  />
                                </span>

                                <div className="refresh-div">
                                  {ownerAccBalanceLoading &&
                                  !ownerAccBalanceError &&
                                  accId === ownerDetailData?.id ? (
                                    <BalanceLoader
                                      ownerDetails="BalanceownerDetails"
                                      loader={ownerAccBalanceLoading}
                                    />
                                  ) : !ownerAccBalanceLoading &&
                                    accId === ownerDetailData?.id ? (
                                    <BalanceLoader
                                      loader={ownerAccBalanceLoading}
                                      setAccId={setAccId}
                                      ownerDetails="BalanceownerDetails"
                                    />
                                  ) : (
                                    <div
                                      onClick={() => {
                                        if (
                                          props?.popType == "ownerStatement"
                                        ) {
                                          toast.warn(
                                            "Action restricted in popup mode.",
                                            toast_position
                                          );
                                          return;
                                        }
                                        if (
                                          services?.includes(
                                            "VAccountBalance"
                                          ) ||
                                          services?.includes("Admin")
                                        ) {
                                          if (
                                            ownerDetailData?.connected_banking ===
                                            "Y"
                                          ) {
                                            setAccId(ownerDetailData?.id);
                                            handleRefresh(ownerDetailData?.id);
                                          }
                                        } else {
                                          navigate("/feature_not_assigned", {
                                            state: {
                                              unavailableService:
                                                "VAccountBalance",
                                            },
                                          });
                                        }
                                        if (
                                          ownerDetailData?.connected_banking !==
                                          "N"
                                        ) {
                                          setAccId(ownerDetailData?.id);
                                          handleRefresh(ownerDetailData?.id);
                                        }
                                      }}
                                      // className="refresh-img"
                                    >
                                      {/* Only the Image */}
                                      <img
                                        src={RefreshIcon}
                                        alt="Refresh balance"
                                      />
                                    </div>
                                  )}
                                </div>

                                {/* here */}
                              </div>
                            ) : (
                              <div className="acc-balance-content">
                                <div className={`text-sm`}>N/A</div>
                              </div>
                            )}
                            <div className="acc-number-balance  text-xs">
                              {ownerDetailData?.owner_account_balance ? (
                                formatTotalBalance(
                                  Number(ownerDetailData?.owner_account_balance)
                                ).prefix == "crores" ? (
                                  <p>
                                    Balance - In crores{" "}
                                    {(ownerDetailData?.connected_banking ===
                                      "Y" ||
                                      "N") &&
                                    ownerDetailData?.active_status !== "Y"
                                      ? `- updated  ${ownerDetailData?.owner_account_balance_last_fetched_at_in_words}`
                                      : null}
                                  </p>
                                ) : (
                                  <p>
                                    Balance - updated{" "}
                                    {
                                      ownerDetailData?.owner_account_balance_last_fetched_at_in_words
                                    }
                                  </p>
                                )
                              ) : (
                                <>
                                  <p>Balance </p>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="recipient flex flex-row bg-Blue_7">
                          {/* HERE WILL COME THE STAR FAV DIV!!!! */}
                          <div
                            style={{ border: "none" }}
                            className="recipient-box2 flex-grow  "
                          >
                            <Box
                              className=" opacity-50  text-center h-full flex flex-col justify-center gap-1 p-2 cursor-not-allowed"
                              onClick={handleToastClick}
                            >
                              <div className="recipient-image-div flex justify-center  ">
                                <Box className="flex justify-center w-10">
                                  <img
                                    src={internalTransaction}
                                    alt="document"
                                    className="recipient-img"
                                  />
                                </Box>
                              </div>
                              <div className="flex justify-center">
                                <Box className="image-label h-8/12 self-center antialiased truncate...">
                                  Initiate internal transaction
                                </Box>
                              </div>
                            </Box>
                          </div>
                          <div className="ownerdetail-border-div"></div>
                          <div
                            className={`${
                              props?.popType == "ownerStatement"
                                ? "recipient-box3 flex-grow cursor-not-allowed opacity-60"
                                : services?.includes("IVAccount" || "Admin")
                                ? "recipient-boxTrans flex-grow hover:bg-Blue_8 cursor-pointer"
                                : "recipient-boxTrans flex-grow opacity-50 cursor-not-allowed"
                            }`}
                          >
                            <Box
                              className={`${
                                props?.popType == "ownerStatement"
                                  ? "text-center   h-full flex flex-col justify-center gap-1 p-2 opacity-60"
                                  : "text-center   h-full flex flex-col justify-center gap-1 p-2"
                              }`}
                              onClick={() => {
                                if (props?.popType == "ownerStatement") {
                                  toast.warn(
                                    "Action restricted in popup mode.",
                                    toast_position
                                  );
                                } else {
                                  if (
                                    services?.includes("IVAccount" || "Admin")
                                  ) {
                                    if (
                                      beneViewAllAccData?.internal_accounts?.[0]
                                        ?.id
                                    ) {
                                      navigate(
                                        `/accounts/internal_account/details/${beneViewAllAccData?.internal_accounts?.[0]?.id}`
                                      );
                                    } else {
                                      toast.error(
                                        "Unable to get Id",
                                        toast_position
                                      );
                                    }
                                  } else {
                                    navigate("/feature_not_assigned", {
                                      state: {
                                        unavailableService: "IVAccount",
                                      },
                                    });
                                  }
                                }
                              }}
                            >
                              <div className="recipient-image-div flex justify-center">
                                <Box className="flex justify-center w-10">
                                  <img
                                    src={LinkedLogo}
                                    alt="document"
                                    className="recipient-img"
                                  />
                                </Box>
                              </div>
                              <div className="flex justify-center">
                                <Box className="image-label h-8/12 self-center antialiased truncate...">
                                  View linked internal account
                                </Box>
                              </div>
                            </Box>
                          </div>
                        </div>
                      </div>

                      <div className="acc-status-main">
                        <div className="w-full flex">
                          <div className="acc-connected">
                            <div
                            // className="border-right"
                            >
                              <div
                                className={`text-base text-[16px] font-[600px] ${
                                  ownerDetailData?.connected_banking === "Y"
                                    ? "active-status"
                                    : ownerDetailData?.connected_banking === "N"
                                    ? "not-active-status"
                                    : ""
                                }`}
                              >
                                {ownerDetailData?.connected_banking === "Y"
                                  ? "Active"
                                  : "Y" &&
                                    ownerDetailData?.connected_banking === "N"
                                  ? "Inactive"
                                  : ""}
                              </div>
                              <div className="acc-number-label mt-[1px]">
                                Connected banking
                              </div>
                            </div>
                          </div>
                          {/* <div className={`flex flex-col`}>
                            <div
                              className={`py-6 pl-6 ${
                                ownerDetailData?.connected_banking === "N"
                                  ? "opacity-50"
                                  : "opacity-100"
                              }`}
                            >
                              <div className="flex items-center">
                                <p className="text-Primary_white ">
                                  ₹ <FormatAmount price={amount} />
                                </p>
                                <div
                                  className={`w-5 h-5 p-[5px] bg-Blue_3 rounded-md ml-4 ${
                                    ownerDetailData?.connected_banking === "N"
                                      ? "cursor-not-allowed"
                                      : "cursor-pointer"
                                  }`}
                                  onClick={() => {
                                    if (
                                      ownerDetailData?.connected_banking === "Y"
                                    ) {
                                      setMinBalance(true);
                                    } else {
                                      return;
                                    }
                                  }}
                                >
                                  <img src={editIcon} />
                                </div>
                              </div>
                              <p className="acc-number-label mt-[1px]">
                                Minimum balance
                              </p>
                            </div>
                          </div> */}

                          {/* {ownerDetailData?.connected_banking == "Y" &&
                          showDisplay &&
                          !balanceFailed ? (
                            <div className="acc-status-detail-page">
                              <div className="acc-status-content">
                                <div
                                  className="text-[16px]"
                                  style={{
                                    fontWeight: "600",

                                    paddingRight: "20px",
                                    color:
                                      ownerDetailData?.increaseOrDecrease ===
                                      "increased"
                                        ? "var(--Settled)"
                                        : ownerDetailData?.increaseOrDecrease ===
                                          "decreased"
                                        ? "var(--Failed)"
                                        : "var(--Primary_white)",
                                  }}
                                >
                                  ₹{" "}
                                  {
                                    formatTotalBalance(
                                      ownerDetailData?.difference
                                    ).amount
                                  }
                                </div>
                                <div className="acc-number-label mt-[2px]">
                                  Balance{" "}
                                  {ownerDetailData?.increaseOrDecrease == ""
                                    ? "increased"
                                    : ownerDetailData?.increaseOrDecrease}{" "}
                                  by{" "}
                                  {formatTotalBalance(
                                    ownerDetailData?.difference
                                  ).prefix == "crores"
                                    ? "- in crores"
                                    : ""}
                                </div>
                              </div>
                            </div>
                          ) : null} */}
                        </div>
                        <div className="statement flex flex-row items-center bg-Blue_7 ">
                          {/* <div className="logo-bank-container">
                              <img
                                src={require("../../../../assets/IfscLogo/" +
                                  IfscCodeMapper(ownerDetailData?.ifsc_code))}
                                alt="bank"
                                className="detailed-bank-logo"
                              />
                            </div> */}
                          {/* If Set as Favourite Design Approves Just un comment below code and remove above div */}
                          {/* <div className="recipient-box4 pl-[22px] pr-[22px] flex-grow cursor-pointer hover:bg-Blue_8">
                          <Box
                            className="text-center h-full flex flex-col justify-center gap-1 p-2"
                            onClick={() => {
                              if (
                                beneViewAllAccData?.internal_accounts?.[0]?.id
                              ) {
                                navigate(
                                  `/accounts/internal_account/details/${beneViewAllAccData?.internal_accounts?.[0]?.id}`
                                );
                              } else {
                                toast.error("Unable to get Id", toast_position);
                              }
                            }}
                          >
                            <div className="recipient-image-div flex justify-center">
                              <Box className="flex justify-center w-10">
                                <img
                                  src={FavoriteIcon}
                                  alt="document"
                                  className="recipient-img"
                                />
                              </Box>
                            </div>
                            <div className="flex justify-center px-1">
                              <Box className="image-label h-8/12 self-center antialiased truncate...">
                                Set as favourite
                              </Box>
                            </div>
                          </Box>
                        </div> */}
                          {/* <div className="ownerdetail-border-div"></div>{" "} */}
                          <div className=" recipient-box5  flex-grow">
                            <Box
                              className="text-center h-full flex flex-col justify-center gap-1 p-2 "
                              sx={{
                                opacity:
                                  ownerDetailData?.connected_banking == "Y" &&
                                  props?.popType != "ownerStatement" &&
                                  (services?.includes("VAccountBalance") ||
                                    services?.includes("Admin"))
                                    ? "1"
                                    : "0.5",
                                cursor:
                                  props?.popType == "ownerStatement" ||
                                  (!services?.includes("VAccountBalance") &&
                                    !services?.includes("Admin"))
                                    ? "not-allowed"
                                    : ownerDetailData?.connected_banking == "Y"
                                    ? "pointer"
                                    : "not-allowed",

                                "&:hover": {
                                  backgroundColor:
                                    ownerDetailData?.connected_banking ===
                                      "Y" && props?.popType != "ownerStatement"
                                      ? "var(--Blue_8)"
                                      : "inherit",
                                },
                              }}
                              onClick={() => {
                                if (props?.popType == "ownerStatement") {
                                  toast.warn(
                                    "Action restricted in popup mode.",
                                    toast_position
                                  );
                                } else if (
                                  services?.includes("VAccountBalance") ||
                                  services?.includes("Admin")
                                ) {
                                  if (
                                    ownerDetailData?.connected_banking == "Y"
                                  ) {
                                    handleViewStatement();
                                  }
                                } else {
                                  navigate("/feature_not_assigned", {
                                    state: {
                                      unavailableService:
                                        "VAccountBalance,Admin",
                                    },
                                  });
                                }
                              }}
                            >
                              <div className="recipient-image-div  flex justify-center">
                                <Box className="flex justify-center w-10">
                                  <img
                                    src={view_statement}
                                    alt="document"
                                    className="recipient-img"
                                  />
                                </Box>
                              </div>
                              <div className="flex justify-center">
                                <Box className="image-label h-8/12 self-center antialiased">
                                  View account statement
                                </Box>
                              </div>
                            </Box>
                          </div>
                          <div className="ownerdetail-border-div"></div>{" "}
                          {ownerDetailData?.connected_banking === "Y" &&
                            balanceFailed &&
                            showDisplay && (
                              <div className="border-l-1   border-Blue_3 h-12 mt-5 ml-[-1px]"></div>
                            )}
                          <div
                            className={`${
                              props?.popType == "ownerStatement"
                                ? "recipient-box6 flex-grow opacity-60 cursor-not-allowed"
                                : ` recipient-box6  flex-grow   ${
                                    !services?.includes("COwnerTag") &&
                                    !services?.includes("DOwnerTag")
                                      ? "opacity-[0.5] cursor-not-allowed"
                                      : " opacity-1 hover:bg-Blue_8 cursor-pointer"
                                  } `
                            }`}
                          >
                            <Box
                              className={`${
                                props?.popType == "ownerStatement"
                                  ? "text-center h-full flex flex-col justify-center gap-1 p-2 opacity-60 cursor-not-allowed"
                                  : "text-center h-full flex flex-col justify-center gap-1 p-2"
                              }`}
                              onClick={() => {
                                if (props?.popType == "ownerStatement") {
                                  toast.warn(
                                    "Action restricted in popup mode.",
                                    toast_position
                                  );
                                } else if (
                                  !services?.includes("COwnerTag") &&
                                  !services?.includes("DOwnerTag")
                                ) {
                                  navigate("/feature_not_assigned");
                                } else {
                                  {
                                    handleSelectedtag();
                                  }
                                }
                              }}
                            >
                              <div className="recipient-image-div  flex justify-center">
                                <Box className="flex justify-center w-10">
                                  <img
                                    src={assigntag}
                                    alt="document"
                                    className="recipient-img"
                                  />
                                </Box>
                              </div>
                              <div className="flex justify-center">
                                <Box>
                                  <div className=" image-label h-8/12 self-center antialiased w-6">
                                    Assign tag
                                  </div>
                                </Box>
                              </div>
                            </Box>
                          </div>
                        </div>
                      </div>

                      <div className="bank-details">
                        <div className="bank-detailsDiv flex">
                          <div className="accno-details justify-between">
                            <div>
                              <span className="accno-response">
                                {formatAccountNumber(
                                  ownerDetailData?.bank_acc_no
                                )}
                              </span>
                              <div className="acc-number-label text-xs">
                                Account number
                              </div>
                            </div>
                            {/* If New Design Approves Just un comment below code*/}
                            <div className="accno-details-img">
                              <img
                                src={require("../../../../assets/IfscLogo/" +
                                  IfscCodeMapper(ownerDetailData?.ifsc_code))}
                                alt="bank"
                                className="accno-details-detailed-bank-logo"
                              />
                            </div>
                          </div>

                          <div className={`ifsc-div-main   flex flex-row`}>
                            <div
                              onClick={() => {
                                if (props?.popType == "ownerStatement") {
                                  toast.warn(
                                    "Action restricted in popup mode.",
                                    toast_position
                                  );
                                } else if (services?.includes("VIFSC")) {
                                  navigate(
                                    `/accounts/owner/owner_account_details/IFSC_Details/${ownerDetailData?.ifsc_code}`
                                  );
                                } else {
                                  navigate("/feature_not_assigned", {
                                    state: {
                                      unavailableService: "VIFSC",
                                    },
                                  });
                                }
                              }}
                              className={`ifsc-div-content   ${
                                services?.includes("VIFSC") &&
                                props?.popType != "ownerStatement"
                                  ? "cursor-pointer  hover:bg-Blue_8 hover:shadow-lg hover:rounded-md   "
                                  : " cursor-not-allowed"
                              }  `}
                            >
                              <div
                                className={`${
                                  services?.includes("VIFSC") &&
                                  props?.popType != "ownerStatement"
                                    ? "h-full w-[130px]"
                                    : ""
                                }`}
                              >
                                <div className="ifsc-response">
                                  {ownerDetailData?.ifsc_code}
                                </div>
                                <div className="ifsc-code-element">
                                  IFSC code
                                </div>
                              </div>

                              <div className="grid place-content-center w-[22px] h-[22px] bg-Blue_3 rounded-md">
                                <img
                                  src={arrowRight}
                                  alt="document"
                                  className={`w-[15px] h-[15px] ${
                                    props?.popType == "ownerStatement"
                                      ? "cursor-not-allowed"
                                      : services?.includes("VIFSC")
                                      ? "cursor-pointer"
                                      : "cursor-default"
                                  }`}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="statement-view-acc flex flex-row">
                          <div className="w-[102px]"></div>
                          <div className="flex flex-col bg-Blue_7">
                            <div className="h-[70px]  flex items-center">
                              <div
                                className={`${
                                  props?.popType == "ownerStatement"
                                    ? "recipient-box3 flex-grow opacity-60 cursor-not-allowed"
                                    : services?.includes("VBeneficiaryTxn")
                                    ? "recipient-boxTrans flex-grow hover:bg-Blue_8 cursor-pointer"
                                    : "recipient-boxTrans flex-grow opacity-50 cursor-not-allowed"
                                }`}
                              >
                                <Box
                                  className={`${
                                    props?.popType == "ownerStatement"
                                      ? "text-center h-full flex flex-col justify-center gap-1 p-2 opacity-60 cursor-not-allowed"
                                      : "text-center h-full flex flex-col justify-center gap-1 p-2"
                                  }`}
                                  onClick={() => {
                                    if (props?.popType == "ownerStatement") {
                                      toast.warn(
                                        "Action restricted in popup mode.",
                                        toast_position
                                      );
                                    } else {
                                      if (
                                        services?.includes("VBeneficiaryTxn")
                                      ) {
                                        setOpenBeneHistory(true);
                                      } else {
                                        navigate("/feature_not_assigned", {
                                          state: {
                                            unavailableService:
                                              "VBeneficiaryTxn",
                                          },
                                        });
                                      }
                                    }
                                  }}
                                >
                                  <div className="recipient-image-div flex justify-center">
                                    <Box className="flex justify-center w-10">
                                      <img
                                        src={beneHistory}
                                        alt="document"
                                        className="recipient-img"
                                      />
                                    </Box>
                                  </div>
                                  <div className="flex justify-center">
                                    <Box className="image-label h-8/12 self-center antialiased">
                                      Beneficiary
                                    </Box>
                                  </div>
                                </Box>
                              </div>
                              <div className="ownerdetail-border-div"></div>
                              <div
                                className={`${
                                  props?.popType == "ownerStatement"
                                    ? "recipient-box3 flex-grow opacity-60 cursor-not-allowed"
                                    : services?.includes("VInternalTxn")
                                    ? "recipient-boxTrans flex-grow hover:bg-Blue_8 cursor-pointer"
                                    : "recipient-boxTrans flex-grow opacity-50 cursor-not-allowed"
                                }`}
                              >
                                <Box
                                  className={`${
                                    props?.popType == "ownerStatement"
                                      ? "text-center h-full flex flex-col justify-center gap-1 p-2 opacity-60 cursor-not-allowed"
                                      : "text-center h-full flex flex-col justify-center gap-1 p-2"
                                  }`}
                                  onClick={() => {
                                    if (props?.popType == "ownerStatement") {
                                      toast.warn(
                                        "Action restricted in popup mode.",
                                        toast_position
                                      );
                                    } else {
                                      if (services?.includes("VInternalTxn")) {
                                        setOpenHistory(true);
                                      } else {
                                        navigate("/feature_not_assigned", {
                                          state: {
                                            unavailableService: "VInternalTxn",
                                          },
                                        });
                                      }
                                    }
                                  }}
                                >
                                  <div className="recipient-image-div flex justify-center">
                                    <Box className="flex justify-center w-10">
                                      <img
                                        src={transactionHistory}
                                        alt="document"
                                        className="recipient-img"
                                      />
                                    </Box>
                                  </div>
                                  <div className="flex justify-center">
                                    <Box className="image-label h-8/12 self-center antialiased">
                                      Internal
                                    </Box>
                                  </div>
                                </Box>
                              </div>
                            </div>
                            <div className="bg-Blue_8 h-[20px]">
                              <p className="mx-auto leading-[20px] text-center w-[124px] font-semibold text-Primary_white text-[10px]">
                                Transaction history
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="details-div-section w-screen">
                  <div className="details-section">
                    <div className="buttonsforOwnerdetails">
                      <button
                        className={`DetailsOwnerButton w-[73px] h-[26px] mr-5 ${
                          showDetails ? "effective" : "hover:bg-[#A3A3A3]"
                        }`}
                        onClick={toggleDetails}
                      >
                        <span
                          className={`text-[12px] text-center font-sans ${
                            showDetails
                              ? "text-Detail_chip font-semibold"
                              : "text-Primary_white"
                          } `}
                        >
                          Details
                        </span>
                      </button>
                      <button
                        className={`DetailsOwnerButton w-[73px] h-[26px] mr-5 ${
                          showStatistics
                            ? "effective"
                            : ownerDetailData?.connected_banking === "Y" &&
                              (services?.includes("Admin") ||
                                services?.includes("VAccountBalance"))
                            ? "hover:bg-[#A3A3A3] cursor-pointer"
                            : ownerDetailData?.connected_banking === "Y" &&
                              (!services?.includes("Admin") ||
                                !services?.includes("VAccountBalance"))
                            ? "opacity-75 cursor-not-allowed"
                            : " opacity-75 cursor-default"
                        }`}
                        onClick={() => {
                          if (
                            ownerDetailData?.connected_banking === "Y" &&
                            (services?.includes("Admin") ||
                              services?.includes("VAccountBalance"))
                          ) {
                            toggleStatistics();
                            if (showStatistics) {
                              handleStatisticsData(
                                formatDate(startDateState),
                                formatDate(endDateState),
                                investmentRateValue,
                                "month"
                              );

                              handleStatisticsData(
                                formatDate(sixDaysAgo),
                                formatDate(yesterdaysDate),
                                investmentRateValue,
                                "week"
                              );
                            }
                          } else if (
                            ownerDetailData?.connected_banking === "Y" &&
                            (!services?.includes("Admin") ||
                              !services?.includes("VAccountBalance"))
                          ) {
                            navigate("/feature_not_assigned", {
                              state: {
                                unavailableService: "VAccountBalance",
                              },
                            });
                          }
                        }}
                      >
                        <span
                          className={`text-[12px] text-center font-sans ${
                            showStatistics
                              ? "text-Detail_chip font-semibold"
                              : "text-Primary_white"
                          } `}
                        >
                          Statistics
                        </span>
                      </button>
                      <button
                        className={`AssignedButton w-[97px] h-[26px] ${
                          showAssignedTags ? "effective" : "hover:bg-[#A3A3A3]"
                        }`}
                        onClick={toggleAdditionalDetails}
                      >
                        <span
                          className={`text-[12px] text-center font-sans ${
                            showAssignedTags
                              ? "text-Detail_chip font-semibold"
                              : "text-Primary_white"
                          }`}
                        >
                          Assigned tags
                        </span>
                      </button>
                    </div>
                    {showDetails ? (
                      <div
                        key={ownerDetailData?.id}
                        className="bank-card-row  gap-10"
                      >
                        <div className="bank-detail-card  w-1/4 ">
                          <h4 className="title5">Bank Details</h4>

                          <div className="w-50 h-50 acc-type-section-div">
                            <div className="data-div">
                              {ownerDetailData?.account_type &&
                                ownerDetailData.account_type
                                  .charAt(0)
                                  .toUpperCase() +
                                  ownerDetailData.account_type
                                    .slice(1)
                                    .toLowerCase()}
                            </div>
                            <div className="acc-type-div">Account type</div>
                          </div>
                        </div>

                        <div className="owner-detail-card w-1/4 mb-[20px]">
                          <div className="w-50 h-50">
                            <h4 className="title5 font-medium">
                              Owner Details
                            </h4>
                            <div className="pan-detail-main-div ml-[-5px] flex row">
                              <div
                                onClick={() => {
                                  if (props?.popType == "ownerStatement") {
                                    toast.warn(
                                      "Action restricted in popup mode.",
                                      toast_position
                                    );
                                  } else if (services?.includes("VPAN")) {
                                    navigate(`/settings/PAN_Verification`, {
                                      state: ownerDetailData?.pan_no,
                                    });
                                  } else {
                                    navigate("/feature_not_assigned", {
                                      state: {
                                        unavailableService: "VPAN",
                                      },
                                    });
                                  }
                                }}
                                className={`pan-div-content   ${
                                  services?.includes("VPAN") &&
                                  props?.popType != "ownerStatement"
                                    ? "cursor-pointer  hover:bg-Blue_8 hover:shadow-lg hover:rounded-[10px]   "
                                    : " cursor-not-allowed"
                                }  `}
                              >
                                <div
                                  className={`${
                                    services?.includes("VPAN")
                                      ? "h-full w-[130px]"
                                      : ""
                                  }`}
                                >
                                  <div className="pan-response">
                                    {ownerDetailData?.pan_no}
                                  </div>
                                  <div className="pan-number-element">
                                    PAN number
                                  </div>
                                </div>

                                <div className="grid place-content-center w-[22px] h-[22px] bg-Blue_3 rounded-md ml-[130px]">
                                  <img
                                    src={arrowRight}
                                    alt="document"
                                    className={`w-[15px] h-[15px] ${
                                      props?.popType == "ownerStatement"
                                        ? "cursor-not-allowed"
                                        : services?.includes("VPAN")
                                        ? "cursor-pointer"
                                        : "cursor-default"
                                    }`}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="ownerdetail-border-div-bottom"></div>{" "}
                            <div className="gstin-detail-main-div ml-[-5px] flex row">
                              <div
                                onClick={() => {
                                  if (props?.popType == "ownerStatement") {
                                    toast.warn(
                                      "Action restricted in popup mode.",
                                      toast_position
                                    );
                                  } else if (services?.includes("VGSTIN")) {
                                    navigate(`/settings/GST_Verification`, {
                                      state: ownerDetailData?.gstin_detail,
                                    });
                                  } else {
                                    navigate("/feature_not_assigned", {
                                      state: {
                                        unavailableService: "VGSTIN",
                                      },
                                    });
                                  }
                                }}
                                className={`pan-div-content   ${
                                  services?.includes("VGSTIN") &&
                                  props?.popType != "ownerStatement"
                                    ? "cursor-pointer  hover:bg-Blue_8 hover:shadow-lg hover:rounded-[10px]   "
                                    : " cursor-not-allowed"
                                }  `}
                              >
                                <div
                                  className={`${
                                    services?.includes("VGSTIN")
                                      ? "h-full w-[130px]"
                                      : ""
                                  }`}
                                >
                                  <div className="pan-response">
                                    {ownerDetailData?.gstin_detail}
                                  </div>
                                  <div className="pan-number-element">
                                    GST number
                                  </div>
                                </div>

                                <div className="grid place-content-center w-[22px] h-[22px] bg-Blue_3 rounded-md ml-[130px]">
                                  <img
                                    src={arrowRight}
                                    alt="document"
                                    className={`w-[15px] h-[15px] ${
                                      props?.popType == "ownerStatement"
                                        ? "cursor-not-allowed"
                                        : services?.includes("VGSTIN")
                                        ? "cursor-pointer"
                                        : "cursor-default"
                                    }`}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="ownerdetail-border-div-bottom"></div>{" "}
                            <div className="mt-[8px]">
                              <div
                                id="email-id-firstLetter"
                                className="data-div"
                              >
                                {ownerDetailData?.email_id}
                              </div>
                              <div className="owner-div">Entity email ID</div>
                            </div>
                          </div>
                        </div>
                        <div className="support-detail-cards w-1/4 mb-[20px]">
                          <div className="w-50 h-50">
                            <h4 className="title5 font-medium">
                              Support information
                            </h4>
                          </div>
                          <div className="w-50 h-50">
                            <div className=" flex flex-row mt-2 justify-between">
                              <div id="id-firstLetter" className="data-div">
                                {ownerDetailData?.id}
                              </div>
                            </div>
                            <div className="pb-3  owner_div_Acc">
                              Owner account ID
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : showStatistics ? (
                      <>
                        <div className="assigned-card-row gap-10">
                          <div
                            className="statistics-first-card flex flex-row"
                            style={{ height: "24.688rem" }}
                          >
                            <div className="flex-[67%] statistics-first-card-left">
                              <p className="statistics-second-card-p1">
                                Missed potential income for last 7 days
                              </p>
                              <p className="statistics-second-card-p2">
                                Income that could have been generated if the
                                idle amount had been invested through Xpentra.
                              </p>

                              <div className="statistics-first-card-left-div">
                                {getOwnerAccWeekStatiticsLoading ? (
                                  <div className="statistics-graph-no-data mt-[4.375rem]">
                                    <div className="w-[40px] h-[40px]">
                                      <img src={dataLoading} alt="Loader" />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="mt-[-20px]">
                                    {accountBalancesReportWeekData?.length >
                                    0 ? (
                                      <div>
                                        <OwnerStatisticsGraph
                                          missedIncomeGraphData={
                                            missedIncomeGraphData
                                          }
                                          actualIncomeGraphData={
                                            actualIncomeGraphData
                                          }
                                          amountRangeValue={amountRangeValue}
                                          pastSevenDays={pastSevenDays}
                                        />
                                        <div className="text-center text-Primary_white text-[14px] mt-[-5px]">
                                          Last 7 days
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="statistics-graph-no-data">
                                        <div>
                                          <div className="ml-[32px]">
                                            <img
                                              className="statistics-graph-no-data-img"
                                              src={NoStatistics}
                                              alt="No Statistics"
                                            />
                                          </div>
                                          <div className="statistics-graph-no-data-text text-wrap">
                                            {accountBalancesReportWeekError}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="flex-[33%] w-[20.125rem] pl-[22px] statistics-first-card-right relative">
                              <div className="statistics-first-card-right-div">
                                <p className="statistics-first-card-right-text">
                                  Amount range
                                </p>
                                <div className="statistics-first-card-right-dropdown">
                                  <OwnerAccStatisticsDropdown
                                    setAmountRange={setAmountRange}
                                    amountRange={amountRange}
                                    setAmountRangeValue={setAmountRangeValue}
                                    amountRangeValue={amountRangeValue}
                                    selectAmountRange={selectAmountRange}
                                    setSelectAmountRange={setSelectAmountRange}
                                    setInfoState={setInfoState}
                                    type="amountRange"
                                  />
                                </div>
                                <div
                                  style={{
                                    borderBottom: "1px solid var(--Blue_3)",
                                    width: "304px",
                                    marginTop: "52px",
                                  }}
                                ></div>
                              </div>

                              {/* <div className="mt-[11px]">
                                <p className="statistics-first-card-right-text">
                                  Investment schemes
                                </p>
                                <div>
                                  <OwnerAccStatisticsDropdown
                                    setInvestmentRate={setInvestmentRate}
                                    investmentRate={investmentRate}
                                    selectInvestmentRate={selectInvestmentRate}
                                    setSelectInvestmentRate={
                                      setSelectInvestmentRate
                                    }
                                    setInvestmentRateValue={
                                      setInvestmentRateValue
                                    }
                                    investmentRateValue={investmentRateValue}
                                    setInfoState={setInfoState}
                                    type="investment"
                                  />
                                </div>
                              </div> */}
                              <div className="mt-[15px] bg-red">
                                <div className="flex flex-row">
                                  <span className=" text-Queued text-[16px] font-semibold relative">
                                    ₹{" "}
                                    {
                                      formatTotalBalance(
                                        accountBalancesReportWeekAllData?.accrued_interest_amount ||
                                          0,
                                        amountRangeValue !== ""
                                          ? amountRangeValue
                                          : "amount"
                                      ).amount
                                    }
                                  </span>{" "}
                                  <div>
                                    <img
                                      ref={infoRef}
                                      className="w-[14px] h-[14px] ml-[4px] cursor-pointer"
                                      src={infoIcon}
                                      alt="info"
                                      onClick={() => {
                                        setInfoState(!infoState);
                                      }}
                                    />
                                  </div>
                                  {infoState ? (
                                    // <div className="absolute mt-[25px] ml-[-14px] w-[20.5rem] h-[6.5rem] text-[12px] text-Primary_white bg-Blue_7 rounded-[10px] px-[20px] py-[15px]">
                                    //   This amount is based on the interest that
                                    //   could have earned by auto-investing in the
                                    //   scheme selected above using Xpentra,
                                    //   calculated at {investmentRateValue + "%"}{" "}
                                    //   of the daily closing balance.
                                    // </div>

                                    <div className="absolute mt-[25px] ml-[-14px] w-[20.5rem] h-[5.5rem] text-[12px] text-Primary_white bg-Blue_7 rounded-[10px] px-[20px] py-[15px]">
                                      This amount is based on the interest that
                                      could have earned by auto-investing using
                                      Xpentra, calculated at 6.5% of the idle
                                      funds.
                                    </div>
                                  ) : null}
                                </div>
                                <p className="text-[12px] text-Primary_white">
                                  Total missed potential income
                                </p>
                              </div>
                              <div className="absolute bottom-[0px]">
                                <div className="flex flex-row mb-[15px]">
                                  <div className="w-[20px] h-[20px] bg-custom-red rounded-[5px]"></div>
                                  <p className="ml-[10px] text-custom-heading text-[12px] font-semibold">
                                    Actual income
                                  </p>
                                </div>
                                <div className="flex flex-row">
                                  <div className="w-[20px] h-[20px] bg-Queued rounded-[5px]"></div>
                                  <p className="ml-[10px] text-custom-heading text-[12px] font-semibold">
                                    Missed potential income
                                  </p>
                                </div>
                                <div className="mt-[10px]">
                                  <p className="text-custom-heading text-[12px] font-normal">
                                    * Amounts rounded off as per banking
                                    standards.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="assigned-card-row gap-10">
                          <div
                            className="statistics-second-card"
                            style={{ height: "auto" }}
                          >
                            <div className="statistics-second-card-top-bar">
                              <div className="statistics-second-card-summary">
                                <p className="statistics-second-card-p1">
                                  Balance Summary
                                </p>
                                <p className="statistics-second-card-p2">
                                  Daily balance summary of this account
                                </p>
                              </div>
                              <div>
                                <div
                                  className="OAS-owner_acc_Calendar_main_div relative flex flex-col items-center bg-[#667CA3] cursor-pointer right-[-4px]"
                                  ref={dateRef}
                                  style={{
                                    width: "305px",
                                    height: showDatePicker ? "" : "39px",
                                    border: showDatePicker
                                      ? "1px solid #ff5860"
                                      : "none",
                                  }}
                                >
                                  <div
                                    className="flex items-center justify-around rounded-[5px] bg-[#667CA3] cursor-pointer"
                                    style={{
                                      width: "290px",
                                      height: showDatePicker ? "" : "39px",
                                    }}
                                    onClick={() => {
                                      setShowDatePicker(!showDatePicker);
                                    }}
                                  >
                                    <div className="OAS-owner_acc_date_heading_div cursor-pointer">
                                      <div className="OAS-owner_acc_date_heading_left">
                                        {startDateState
                                          ? formatDate(
                                              startDateState,
                                              true,
                                              false,
                                              true
                                            )
                                          : "Select from date"}
                                      </div>
                                    </div>
                                    <img
                                      src={arrowforward}
                                      className="OAS-owner_acc_viewall_calendar_icon"
                                    />
                                    <div className="OAS-owner_acc_date_select_div cursor-pointer">
                                      <div className="OAS-owner_acc_date_heading_right">
                                        {" "}
                                        {endDateState
                                          ? formatDate(
                                              endDateState,
                                              true,
                                              false,
                                              true
                                            )
                                          : "Select to date"}
                                      </div>
                                    </div>
                                  </div>
                                  {showDatePicker && (
                                    <div
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <DatePickerComponent
                                        startDate={startDateState}
                                        endDate={endDateState}
                                        handleDateChange={handleDateChange}
                                        handleCancel={handleCancel}
                                        handleConfirm={handleConfirm}
                                        showDatePicker={showDatePicker}
                                        yearDropdownRef={yearDropdownRef}
                                        dateRef={dateRef}
                                        setShowDatePicker={setShowDatePicker}
                                        isOpen={true}
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="statistics-date-note">
                                  * Start and end dates must be within a 6-month
                                  period.
                                </div>
                              </div>
                            </div>
                            <div className="statistics-second-card-middle-bar">
                              <div>
                                <p className="statistics-second-card-p1-total">
                                  Total missed potential income
                                </p>
                                <p className="statistics-second-card-p3">
                                  From{" "}
                                  {formatDate(startDate, true, false, true)} to{" "}
                                  {formatDate(endDate, true, false, true)}
                                </p>
                              </div>
                              <div className="relative">
                                <span className="statistics-second-card-span">
                                  ₹{" "}
                                  {
                                    formatTotalBalance(
                                      accountBalancesReportAllData?.accrued_interest_amount ||
                                        0,
                                      amountRangeValue !== ""
                                        ? amountRangeValue
                                        : "amount"
                                    ).amount
                                  }
                                </span>
                                <div className="mt-[25px]">
                                  <p className="text-custom-heading text-[12px] font-normal">
                                    * Amounts rounded off as per banking
                                    standards.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="statistics-second-card-header-bar">
                              <div>Date</div>
                              <div className="statistics-second-card-header-bar-div2">
                                Closing balance
                              </div>
                              <div>Minimum balance</div>
                              <div className="statistics-second-card-header-bar-div2">
                                Idle funds
                              </div>
                              <div>Missed potential income</div>
                            </div>
                            <div
                              className="statistics-second-card-data-div"
                              style={{
                                overflowY:
                                  accountBalancesReportData?.length > 0
                                    ? "auto"
                                    : "hidden",
                              }}
                            >
                              {getOwnerAccStatiticsLoading ? (
                                <>
                                  <div className="statistics-graph-no-data mt-[2.375rem]">
                                    <div className="w-[40px] h-[40px]">
                                      <img src={dataLoading} alt="Loader" />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {accountBalancesReportData?.length > 0 ? (
                                    accountBalancesReportData.map(
                                      (data, index) => {
                                        return (
                                          <div
                                            className="statistics-second-card-data-bars"
                                            style={{
                                              borderBottom:
                                                accountBalancesReportData?.length -
                                                  1 ===
                                                index
                                                  ? ""
                                                  : "1px solid var(--Blue_3)",
                                            }}
                                          >
                                            <div className="statistics-second-card-data-div-date">
                                              {formatDate(
                                                data?.date,
                                                false,
                                                true
                                              )}
                                            </div>
                                            <div className="statistics-second-card-data-div-amount1">
                                              ₹{" "}
                                              {
                                                formatTotalBalance(
                                                  Number(data?.closing_balance),
                                                  amountRangeValue !== ""
                                                    ? amountRangeValue
                                                    : "amount"
                                                ).amount
                                              }
                                            </div>
                                            <div className="statistics-second-card-data-div-amount2">
                                              ₹ 0.00
                                            </div>
                                            <div className="statistics-second-card-data-div-amount3">
                                              ₹{" "}
                                              {
                                                formatTotalBalance(
                                                  Number(data?.closing_balance),
                                                  amountRangeValue !== ""
                                                    ? amountRangeValue
                                                    : "amount"
                                                ).amount
                                              }
                                            </div>
                                            <div className="statistics-second-card-data-div-income">
                                              ₹{" "}
                                              {
                                                formatTotalBalance(
                                                  Number(data?.daily_interest),
                                                  amountRangeValue !== ""
                                                    ? amountRangeValue
                                                    : "amount"
                                                ).amount
                                              }
                                            </div>
                                          </div>
                                        );
                                      }
                                    )
                                  ) : (
                                    <div className="statistics-second-card-no-data">
                                      <div>
                                        <div className="ml-[32px]">
                                          <img
                                            className="statistics-second-card-no-data-img"
                                            src={NoStatistics}
                                            alt="No Statistics"
                                          />
                                        </div>
                                        <div className="statistics-second-card-no-data-text text-wrap">
                                          {accountBalancesReportError}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="assigned-card-row gap-10">
                        <div
                          className="assigned-detail-card  w-1/4 flex flex-row flex-wrap"
                          style={{ height: haveName > 0 ? "auto" : "66px" }}
                        >
                          {haveName ? (
                            newTags
                              ?.filter((tag) => tag.type === "ownerTags")
                              ?.map((tag) => {
                                return (
                                  <>
                                    <Box
                                      className=" rounded-[17px]"
                                      style={{
                                        height: "26px !important",
                                        text: "12px",
                                        background: "var(--Blue_7)",
                                        margin: "5px 10px 5px 0",
                                        padding: "0px 10px 5px 9px",
                                        color: "var(--Primary_white)",
                                        width: "auto !important",
                                        cursor: "default",
                                      }}
                                      key={tag?.value}
                                    >
                                      <>
                                        <span
                                          id=" id-textTag"
                                          style={{
                                            fontSize: "12px",
                                          }}
                                        >
                                          {tag?.value}
                                        </span>
                                      </>
                                    </Box>
                                  </>
                                );
                              })
                          ) : (
                            <p className="NotagsAssigned">
                              No tags assigned to this account.
                            </p>
                          )}

                          <div
                            className={`${
                              props?.popType == "ownerStatement" &&
                              "disableedittag"
                            } editTag  ${
                              !services?.includes("COwnerTag") &&
                              !services?.includes("DOwnerTag")
                                ? " cursor-default opacity-[0.5]"
                                : "hover:bg-Blue_8 cursor-pointer opacity-1"
                            } `}
                            onClick={() => {
                              if (props?.popType == "ownerStatement") {
                                toast.warn(
                                  "Action restricted in popup mode.",
                                  toast_position
                                );
                              } else if (
                                !services?.includes("COwnerTag") &&
                                !services?.includes("DOwnerTag")
                              ) {
                                navigate("/feature_not_assigned");
                              } else {
                                {
                                  handleSelectedtag();
                                }
                              }
                            }}
                          >
                            <img
                              src={editIcon}
                              alt="editIcon"
                              className="edit-icon"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default OwnerDetailsPage;
