import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { useNavigate } from "react-router-dom";
import OwnerListViewComponent from "../OwnerListViewComponent";

const ListView = ({
  data,
  handleRefresh,
  isopen,
  page,
  refreshAllData,
  refreshType,
  setRefreshtype,
  setFailedRefreshId,
  failedRefreshId,
  successRefreshId,
  setSuccessRefreshId,
  currency,
  splitView,
  postiveBalanceData,
  negativeBalanceData,
  refreshAll,
  typepop,
  setIsNeft,
  setIsImps,
  setIsRtgs,
  txnPayLoad,
  setTxnPayLoad,
  BenEnableNextButton,
  setOpenOwner,
  flow,
  handleViewStatement,
  setStatementId,
  setAccData,
  setPrev_Total_balance_state,
  total_balance,
  handleButtonClick,
  favouritesFilter,
}) => {
  const { ownerAccBalanceLoading, ownerAccBalanceError } = useSelector(
    (state) => state.ownerAllVerification
  );
  const [accId, setAccId] = useState("");
  const [prevId, setPrevId] = useState("");
  const [prevBalance, setPrevBalance] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listdata = data;

  return (
    <>
      {splitView ? (
        <div className="w-[1100px] mx-auto flex flex-col px-[40px]">
          <div className="split_view_header">
            <div style={{ marginLeft: "-15px" }} className="w-[50%]">
              <p>Positive Balance</p>
            </div>
            <div className="w-[50%] pl-[47px]">
              <p>Negative Balance</p>
            </div>
          </div>
          <div className="flex w-full mb-[50px]">
            <div className="w-[50%] flex flex-wrap animation_test pb-[100px]">
              {postiveBalanceData.length > 0 ? (
                <div>
                  {postiveBalanceData.map((item, index) => {
                    return (
                      <div
                        key={item.id}
                        style={{
                          maxWidth: "425px",
                          height: "49px",
                          margin: "3px",
                        }}
                      >
                        <OwnerListViewComponent
                          index={index}
                          item={item}
                          handleRefresh={handleRefresh}
                          isopen={isopen}
                          page={page}
                          refreshAllData={refreshAllData}
                          refreshType={refreshType}
                          setRefreshtype={setRefreshtype}
                          setFailedRefreshId={setFailedRefreshId}
                          failedRefreshId={failedRefreshId}
                          successRefreshId={successRefreshId}
                          setSuccessRefreshId={setSuccessRefreshId}
                          currency={currency}
                          refreshAll={refreshAll}
                          typepop={typepop}
                          setIsNeft={setIsNeft}
                          setIsImps={setIsImps}
                          setIsRtgs={setIsRtgs}
                          txnPayLoad={txnPayLoad}
                          setTxnPayLoad={setTxnPayLoad}
                          BenEnableNextButton={BenEnableNextButton}
                          setOpenOwner={setOpenOwner}
                          flow={flow}
                          handleViewStatement={handleViewStatement}
                          ownerAccBalanceLoading={ownerAccBalanceLoading}
                          ownerAccBalanceError={ownerAccBalanceError}
                          navigate={navigate}
                          dispatch={dispatch}
                          accId={accId}
                          setAccId={setAccId}
                          setStatementId={setStatementId}
                          setAccData={setAccData}
                          prevId={prevId}
                          setPrevId={setPrevId}
                          prevBalance={prevBalance}
                          setPrevBalance={setPrevBalance}
                          setPrev_Total_balance_state={
                            setPrev_Total_balance_state
                          }
                          total_balance={total_balance}
                          handleButtonClick={handleButtonClick}
                          favouritesFilter={favouritesFilter}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p style={{ padding: "0" }} className="noaccountfound">
                  No accounts were found with a positive balance.
                </p>
              )}
            </div>
            <div className="owner_split_div"></div>
            <div className="w-[50%] pl-[30px] animation_test2 pb-[100px]">
              {negativeBalanceData.length > 0 ? (
                <div>
                  {negativeBalanceData.map((item, index) => {
                    return (
                      <div
                        key={item.id}
                        style={{
                          maxWidth: "425px",
                          height: "49px",
                          margin: "3px",
                        }}
                      >
                        <OwnerListViewComponent
                          index={index}
                          item={item}
                          handleRefresh={handleRefresh}
                          isopen={isopen}
                          page={page}
                          refreshAllData={refreshAllData}
                          refreshType={refreshType}
                          setRefreshtype={setRefreshtype}
                          setFailedRefreshId={setFailedRefreshId}
                          failedRefreshId={failedRefreshId}
                          successRefreshId={successRefreshId}
                          setSuccessRefreshId={setSuccessRefreshId}
                          currency={currency}
                          refreshAll={refreshAll}
                          typepop={typepop}
                          setIsNeft={setIsNeft}
                          setIsImps={setIsImps}
                          setIsRtgs={setIsRtgs}
                          txnPayLoad={txnPayLoad}
                          setTxnPayLoad={setTxnPayLoad}
                          BenEnableNextButton={BenEnableNextButton}
                          setOpenOwner={setOpenOwner}
                          flow={flow}
                          handleViewStatement={handleViewStatement}
                          ownerAccBalanceLoading={ownerAccBalanceLoading}
                          ownerAccBalanceError={ownerAccBalanceError}
                          navigate={navigate}
                          dispatch={dispatch}
                          accId={accId}
                          setAccId={setAccId}
                          setStatementId={setStatementId}
                          setAccData={setAccData}
                          prevId={prevId}
                          setPrevId={setPrevId}
                          prevBalance={prevBalance}
                          setPrevBalance={setPrevBalance}
                          setPrev_Total_balance_state={
                            setPrev_Total_balance_state
                          }
                          total_balance={total_balance}
                          handleButtonClick={handleButtonClick}
                          favouritesFilter={favouritesFilter}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p style={{ paddingLeft: "10px" }} className="noaccountfound">
                  No accounts were found with a negative balance.
                </p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{ paddingBottom: "100px", width: "93%" }}
          className="ownerCard gap-x-[20px] 2.6xl:gap-x-[20px] 3.1xl:gap-x-[15px] 3.6xl:gap-x-[15px] 4.6xl:gap-x-[15px] 5.6xl:gap-x-[15px] gap-y-[10px] 2.6xl:gap-y-[10px] 3.1xl:gap-y-[15px] 3.6xl:gap-y-[15px] 4.6xl:gap-y-[15px] 5.6xl:gap-y-[15px]"
        >
          {listdata.map((item, index) => {
            return (
              <div
                key={item.id}
                className="ownerListViewDiv"
                style={{
                  position: "relative",
                  width: "425px",
                  maxWidth: "425px",
                  height: "auto",
                  margin: "3px",
                }}
              >
                <OwnerListViewComponent
                  index={index}
                  item={item}
                  handleRefresh={handleRefresh}
                  isopen={isopen}
                  page={page}
                  refreshAllData={refreshAllData}
                  refreshType={refreshType}
                  setRefreshtype={setRefreshtype}
                  setFailedRefreshId={setFailedRefreshId}
                  failedRefreshId={failedRefreshId}
                  successRefreshId={successRefreshId}
                  setSuccessRefreshId={setSuccessRefreshId}
                  currency={currency}
                  refreshAll={refreshAll}
                  typepop={typepop}
                  setIsNeft={setIsNeft}
                  setIsImps={setIsImps}
                  setIsRtgs={setIsRtgs}
                  txnPayLoad={txnPayLoad}
                  setTxnPayLoad={setTxnPayLoad}
                  BenEnableNextButton={BenEnableNextButton}
                  setOpenOwner={setOpenOwner}
                  flow={flow}
                  handleViewStatement={handleViewStatement}
                  ownerAccBalanceLoading={ownerAccBalanceLoading}
                  ownerAccBalanceError={ownerAccBalanceError}
                  navigate={navigate}
                  dispatch={dispatch}
                  accId={accId}
                  setAccId={setAccId}
                  setStatementId={setStatementId}
                  setAccData={setAccData}
                  prevId={prevId}
                  setPrevId={setPrevId}
                  prevBalance={prevBalance}
                  setPrevBalance={setPrevBalance}
                  setPrev_Total_balance_state={setPrev_Total_balance_state}
                  total_balance={total_balance}
                  handleButtonClick={handleButtonClick}
                  favouritesFilter={favouritesFilter}
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default ListView;
