import React, { useState, useEffect } from "react";
import xpentraLogo from "../../assets/TransactionDashboardIcons/Xpentra.png";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  loginNewToken,
  resetLoginError,
} from "../../redux/features/login/login";
import { MDBContainer, MDBCol, MDBRow } from "mdb-react-ui-kit";
import loginBg from "../../assets/loginImage/login_bg.png";
import trustIcon from "../../assets/trust_icon.png";
import eyeOff from "../../assets/PasswordIcons/password_hidden_darkgrey.svg";
import eyeOn from "../../assets/PasswordIcons/password_visible_darkgrey.svg";
import company from "../../assets/company.png";
import PasswordNotMatched from "../../assets/loginImage/passwordnotmatched.svg";
import { setEntity } from "../../redux/features/login/login";
import SkippingVerification from "../../assets/CreateTransactionIcons/Skipping verivifaction.png";
import LoaderIcon from "../../assets/LoaderIcons/Loader.gif";
import LoginBgMob from "../../assets/loginImage/loginBgMob.svg";
import LoginTotp from "../LoginTotp/LoginTotp";
import {
  removeInitialError,
  disabledEnabledError,
} from "../../redux/features/totp/totp";
import { toast } from "react-toastify";
import { toast_position } from "../../redux/features/Settings/Authorization";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { multipleEntity, entity } = useSelector((state) => state.login);
  const entityData = useSelector((state) => state.login.data);
  const loggedIn = sessionStorage.getItem("loggedIn");
  const { data } = useSelector((state) => state.refreshToken);
  const [cred, setCred] = useState({
    user_Id: "",
    password: "",
    totp: "",
    type: "",
  });

  const [isCredentialsFilled, setIsCredentialsFilled] = useState(false);
  const [isBackupCodes, setIsBackupCodes] = useState(false);

  const [flag, setFlag] = useState(true);
  const [isFocus, setIsFocus] = useState(false);

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, "");

    setCred((prevCred) => {
      const updatedCred = { ...prevCred, [name]: sanitizedValue };
      return updatedCred;
    });
    setError("");
  };

  const { isLoading, isError, loginErrorMessage } = useSelector(
    (state) => state.login
  );

  const handleNavigation = (e, path) => {
    if (isLoggingIn) {
      e.preventDefault();
    } else {
      navigate(path);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      if (
        loginErrorMessage === "Invalid TOTP code" &&
        cred.totp?.length !== 6 &&
        cred.totp?.length !== 7
      ) {
        dispatch(resetLoginError());
        setFlag(false);
      }
    }
  }, [loginErrorMessage]);

  useEffect(() => {
    if (entityData?.employees?.first_login_flag === "Y") {
      navigate("/setNewPassword");
    } else if (multipleEntity) {
    } else if (data !== null || loggedIn) {
      if (cred.type === "backup") {
        navigate("/settings/security_center/Set_Up_Totp", { replace: true });
        setTimeout(() => {
          toast.success(
            "Logged in successfully using backup codes",
            toast_position
          );
          toast.error("TOTP has been disabled", toast_position);
        }, 300);
      } else {
        navigate("/home", { replace: true });
      }
    }
  }, [multipleEntity, loggedIn]);

  useEffect(() => {
    setIsCredentialsFilled(
      cred.user_Id.trim() !== "" && cred.password.trim() !== ""
    );
  }, [cred]);

  const handleshowhide = () => {
    setShowPassword(!showPassword);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && isCredentialsFilled) {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    if (isCredentialsFilled) {
      setIsLoggingIn(true);
      try {
        const response = await dispatch(loginNewToken(cred));
        dispatch(removeInitialError());
        dispatch(disabledEnabledError());
        const { status } = response.payload;

        if (status === true) {
        } else {
          setError("Invalid username or password.");
        }
      } catch (error) {
        console.error("Login error:", error);
      } finally {
        setIsLoggingIn(false);
      }
    }
  };

  return (
    <>
      {flag === true ? (
        <>
          <div id="webviewd">
            <div
              className="web_view h-screen overflow-y-hidden"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <MDBContainer
                fluid
                className={`p-2 my-5 mx-auto w-[100%] max-w-[1024px] h-custom`}
              >
                <MDBRow
                  className={`center flex-container w-[100%] max-w-[1024px]`}
                  style={{
                    backgroundColor: error ? "#314D7E" : "",
                    width: error ? "1024px" : "",
                    marginLeft: error ? "auto" : "",
                    borderLeft: error ? "2px solid #ce362a" : "transparent",
                    borderTop: error ? "2px solid #ce362a" : "transparent",
                    borderBottom: error ? "2px solid #ce362a" : "transparent",
                    borderRadius: error ? "40px 40px 40px 40px" : "",
                  }}
                >
                  <MDBCol className="flex justify-center w-full">
                    {error ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <img
                          src={PasswordNotMatched}
                          alt="bg_image"
                          style={{
                            width: "200px",
                            height: "230px",
                            marginLeft: "60px",
                          }}
                        />
                        <div
                          style={{ marginLeft: "60px" }}
                          className="text-center text-[#F9F9F9]"
                        >
                          <h5
                            className="text-center text-xl font-semibold"
                            style={{ marginRight: "60px" }}
                          >
                            Unable to login!
                          </h5>
                          <p className="text-[#F9F9F9] text-left text-sm leading-[2.5]">
                            You are unable to log in due to one of the following
                            reasons:
                          </p>
                          <ul
                            className="text-sm"
                            style={{
                              textAlign: "left",
                              listStyleType: "disc",
                              paddingLeft: "20px",
                              lineHeight: "2",
                            }}
                          >
                            <li>Incorrect User ID</li>
                            <li>Incorrect Password</li>
                            <li>Both User ID and Password are incorrect</li>
                            <li>Unauthorized User ID</li>
                            <li>Account is blocked</li>
                          </ul>
                          <p className="text-[#F9F9F9] text-left text-sm mt-2 mb-6">
                            Please enter the correct User ID or Password, or
                            contact the administrator to verify your login
                            credentials.
                          </p>
                        </div>
                      </div>
                    ) : (
                      <img
                        src={loginBg}
                        alt="bg_image"
                        style={{
                          margin: "auto",
                          display: "block",
                          width: "482px",
                          height: "375px",
                        }}
                      />
                    )}
                  </MDBCol>

                  {!error && (
                    <MDBCol
                      col="10"
                      md="6"
                      className="flex justify-center w-[7%]"
                    >
                      <br />
                    </MDBCol>
                  )}

                  <MDBCol className="w-11/12 h-[515px]">
                    {!multipleEntity ? (
                      <div className="wrapper">
                        <div className="loginlogo">
                          <img width="" src={xpentraLogo} alt="xpentraLogo" />
                        </div>

                        <div className="input-data">
                          <input
                            onChange={handleChange}
                            value={cred.user_Id}
                            name="user_Id"
                            onKeyDown={handleKeyPress}
                            type="text"
                            autoComplete="username"
                            required
                            className={`${error ? "pr-9" : "pr-8"}`}
                          />
                          {error && (
                            <img
                              src={SkippingVerification}
                              alt="Skipped Verification Icon"
                              className="w-5 h-5 absolute right-2 top-1/2 transform -translate-y-1/2"
                              style={{
                                transition:
                                  "opacity 0.3s ease, transform 0.3s ease",
                              }}
                            />
                          )}
                          <div
                            className={
                              error ? "underline errorline" : "underline"
                            }
                          ></div>
                          <label
                            style={{
                              color: error ? "#FF0001" : "",
                              fontWeight: error ? "bold" : "",
                            }}
                          >
                            User ID
                          </label>
                        </div>

                        <div className="input-data mt-8 relative">
                          <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            onChange={handleChange}
                            onKeyDown={handleKeyPress}
                            value={cred.password}
                            autoComplete="current-password"
                            required
                            className={`${error ? "pr-16" : "pr-8"}`}
                          />

                          <span
                            className={`absolute top-1/2 transform -translate-y-1/2 ${
                              error ? "right-10" : "right-2"
                            } z-10 cursor-pointer transition-all duration-300`}
                            onClick={handleshowhide}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              className="w-4 h-4"
                              src={showPassword ? eyeOn : eyeOff}
                              alt="Toggle Password Visibility"
                            />
                          </span>

                          {error && (
                            <img
                              src={SkippingVerification}
                              alt="Skipped Verification Icon"
                              className="w-5 h-5 absolute right-2 top-1/2 transform -translate-y-1/2"
                              style={{
                                transition:
                                  "opacity 0.3s ease, transform 0.3s ease",
                              }}
                            />
                          )}

                          <div
                            className={
                              error ? "underline errorline" : "underline"
                            }
                          ></div>

                          <label
                            style={{
                              color: error ? "#FF0001" : "",
                              fontWeight: error ? "bold" : "",
                            }}
                          >
                            Password
                          </label>
                        </div>

                        <div className="flex justify-between my-4 forgotpass">
                          <Link to="/contactAdmin">Forgot your password?</Link>
                        </div>

                        <div
                          id="webviewd"
                          className={`text-center pt-2 mb-2 ${
                            !isCredentialsFilled ? "pointer-events-none" : ""
                          }`}
                        >
                          <button
                            id="button"
                            className={`mb-0 px-5 w-full  btncolor ${
                              error || !isCredentialsFilled ? "" : "filled"
                            }`}
                            disabled={error || !isCredentialsFilled}
                            style={{
                              marginTop: `${error ? "120px" : "150px"}`,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              pointerEvents:
                                error || !isCredentialsFilled ? "none" : "auto",
                            }}
                            onClick={(e) => {
                              handleLogin(e);
                            }}
                          >
                            {isLoggingIn ? (
                              <img
                                src={LoaderIcon}
                                alt="Loading"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  transform: "scale(0.6)",
                                }}
                              />
                            ) : (
                              "Login"
                            )}
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="multi_wrapper">
                        <div className="loginlogo">
                          <img width="" src={xpentraLogo} alt="xpentraLogo" />
                        </div>
                        <p className="multi_entity_text">
                          Multiple entities found linked to your login
                          credentials. Select the one you wish to proceed with.
                        </p>
                        <div className="multi_entity_div">
                          {entityData?.entity.map((val) => {
                            return (
                              <div
                                key={val.entity_id}
                                onClick={() => dispatch(setEntity(val))}
                                style={{
                                  background:
                                    entity?.[0].entity_id === val.entity_id
                                      ? "#506994 0% 0% no-repeat padding-box"
                                      : "#ffffff 0% 0% no-repeat padding-box",
                                }}
                                className="multi_entity_card"
                              >
                                <img
                                  className="multi_entity_company_img"
                                  src={company}
                                  alt="Company"
                                />
                                <div className="flex flex-col">
                                  <p
                                    style={{
                                      color:
                                        entity?.[0].entity_id === val.entity_id
                                          ? "#f9f9f9"
                                          : "#000",
                                    }}
                                    className="multi_entity_company_name"
                                  >
                                    {val.entity_name}
                                  </p>
                                  <p
                                    style={{
                                      color:
                                        entity?.[0].entity_id === val.entity_id
                                          ? "#dadada"
                                          : "#716f70",
                                    }}
                                    className="multi_entity_name"
                                  >
                                    {val.entity_id}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>

                        <div
                          className={`text-center pt-2 mb-2 ${
                            !isCredentialsFilled ? "pointer-events-none" : ""
                          }`}
                        >
                          <button
                            id="button"
                            className={`mb-0 px-5 w-full  btncolor ${
                              error || !isCredentialsFilled ? "" : "filled"
                            }`}
                            disabled={error || !isCredentialsFilled}
                            style={{
                              marginTop: `${error ? "120px" : "160px"}`,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              pointerEvents:
                                error || !isCredentialsFilled ? "none" : "auto",
                            }}
                            onClick={(e) => {
                              handleLogin(e);
                            }}
                          >
                            {isLoggingIn ? (
                              <img
                                src={LoaderIcon}
                                alt="Loading"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                }}
                              />
                            ) : (
                              "Login"
                            )}
                          </button>
                        </div>
                      </div>
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
              <div className="flex-container text-center text-md-start justify-between w-[1024px] max-w-[1024px] fixed bottom-0">
                <div className="text-Primary_white text-xs mb-3 items-center my-auto">
                  © Copyright {new Date().getFullYear()} Q2Pay Technologies Pvt
                  Ltd. All rights reserved |
                  <Link className="text-Primary_white" to="/aboutus">
                    About Us
                  </Link>
                  |
                  <Link className="text-Primary_white" to="/privacy_policy">
                    Privacy
                  </Link>
                  |
                  <Link className="text-Primary_white" to="/terms_conditions">
                    Terms
                  </Link>
                </div>

                <div className="text-Primary_white text-xs items-center mb-3 my-auto">
                  <div
                    tag="a"
                    color="none"
                    className="mx-2"
                    style={{ color: "var(--Primary_white)" }}
                  >
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.entrust.net/customer/profile?domain=q2pay.in&lang=en"
                    >
                      <img
                        src={trustIcon}
                        alt="trust_icon"
                        className="img-fluid"
                        style={{
                          margin: "auto",
                          display: "block",
                          width: "32px",
                        }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="mobileviewd">
            <div
              className="web_view overflow-y-hidden"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="mx-auto w-full sm:max-w-[100%] md:max-w-[100%] lg:max-w-[100%] xl:max-w-[100%]">
                <div
                  className={`centerm w-full mobile-view-login`}
                  style={{
                    backgroundColor: error ? "#314D7E" : "",
                    width: error ? "100%" : "",
                    margin: error ? "40px auto" : "",
                    borderLeft: error ? "2px solid #ce362a" : "transparent",
                    borderTop: error ? "2px solid #ce362a" : "transparent",
                    borderBottom: error ? "2px solid #ce362a" : "transparent",
                    borderRight: error ? "2px solid #ce362a" : "transparent",
                  }}
                >
                  <div className="flex justify-center">
                    {error ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          textAlign: "center",
                          margin: "20px auto",
                        }}
                      >
                        <img
                          src={PasswordNotMatched}
                          alt="bg_image"
                          style={{
                            width: "80%",
                            height: "80%",
                            margin: "20px auto",
                          }}
                        />
                        <div
                          style={{ padding: "20px" }}
                          className="text-center text-[#F9F9F9]"
                        >
                          <h5 className="text-center text-xl font-semibold">
                            Unable to login!
                          </h5>
                          <p className="text-[#F9F9F9] text-left text-xs leading-[2.5]">
                            You are unable to log in due to one of the following
                            reasons:
                          </p>
                          <ul
                            className="text-xs"
                            style={{
                              textAlign: "left",
                              listStyleType: "disc",
                              paddingLeft: "20px",
                              lineHeight: "2",
                            }}
                          >
                            <li>Incorrect User ID</li>
                            <li>Incorrect Password</li>
                            <li>Both User ID and Password are incorrect</li>
                            <li>Unauthorized User ID</li>
                            <li>Account is blocked</li>
                          </ul>
                          <p className="text-[#F9F9F9] text-left text-xs mt-2">
                            Please enter the correct User ID or Password, or
                            contact the administrator to verify your login
                            credentials.
                          </p>
                        </div>
                      </div>
                    ) : (
                      <img
                        src={LoginBgMob}
                        className="m-auto"
                        alt="bg_image"
                        style={{
                          margin: "auto",
                          display: "block",
                          width: "79%",
                          height: "100%",
                        }}
                      />
                    )}
                  </div>

                  <div className="w-full">
                    {!multipleEntity ? (
                      <div className="wrapperm">
                        <div className="loginlogo">
                          <img width="" src={xpentraLogo} alt="xpentraLogo" />
                        </div>

                        <div className="input-data">
                          <input
                            onChange={handleChange}
                            value={cred.user_Id}
                            name="user_Id"
                            onKeyDown={handleKeyPress}
                            type="text"
                            autoComplete="username"
                            required
                            className={` ${error ? "pr-9" : ""}`}
                          />
                          {error && (
                            <img
                              src={SkippingVerification}
                              alt="Skipped Verification Icon"
                              className="w-5 h-5 absolute right-2 top-1/2 transform -translate-y-1/2"
                              style={{
                                transition:
                                  "opacity 0.3s ease, transform 0.3s ease",
                              }}
                            />
                          )}
                          <div
                            className={
                              error ? "underline errorline" : "underline"
                            }
                          ></div>
                          <label
                            style={{
                              color: error ? "#FF0001" : "",
                              fontWeight: error ? "bold" : "",
                            }}
                          >
                            User ID
                          </label>
                        </div>

                        <div className="input-data mt-8 relative">
                          <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            onChange={handleChange}
                            onKeyDown={handleKeyPress}
                            value={cred.password}
                            autoComplete="current-password"
                            required
                            className={` ${error ? "pr-16" : "pr-8"}`}
                          />

                          <span
                            className={`absolute top-1/2 transform -translate-y-1/2 ${
                              error ? "right-10" : "right-2"
                            } z-10 cursor-pointer transition-all duration-300`}
                            onClick={handleshowhide}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              className="w-4 h-4"
                              src={showPassword ? eyeOn : eyeOff}
                              alt="Toggle Password Visibility"
                            />
                          </span>

                          {error && (
                            <img
                              src={SkippingVerification}
                              alt="Skipped Verification Icon"
                              className="w-5 h-5 absolute right-2 top-1/2 transform -translate-y-1/2"
                              style={{
                                transition:
                                  "opacity 0.3s ease, transform 0.3s ease",
                              }}
                            />
                          )}

                          <div
                            className={
                              error ? "underline errorline" : "underline"
                            }
                          ></div>

                          <label
                            style={{
                              color: error ? "#FF0001" : "",
                              fontWeight: error ? "bold" : "",
                            }}
                          >
                            Password
                          </label>
                        </div>

                        <div className="flex justify-between my-4 forgotpass">
                          <Link to="/contactAdmin">Forgot your password?</Link>
                        </div>

                        <div
                          className={`text-center pt-2 mb-2 ${
                            !isCredentialsFilled ? "pointer-events-none" : ""
                          }`}
                        >
                          <button
                            id="button"
                            className={`mb-0 px-5 w-full btncolor ${
                              error || !isCredentialsFilled ? "" : "filled"
                            }`}
                            disabled={error || !isCredentialsFilled}
                            style={{
                              marginTop: `${error ? "120px" : "90px"}`,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              pointerEvents:
                                error || !isCredentialsFilled ? "none" : "auto",
                            }}
                            onClick={(e) => {
                              handleLogin(e);
                            }}
                          >
                            {isLoggingIn ? (
                              <img
                                src={LoaderIcon}
                                alt="Loading"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                }}
                              />
                            ) : (
                              "Login"
                            )}
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="multi_wrapperm">
                        <div className="loginlogo">
                          <img width="" src={xpentraLogo} alt="xpentraLogo" />
                        </div>
                        <p className="multi_entity_text">
                          Multiple entities found linked to your login
                          credentials. Select the one you wish to proceed with.
                        </p>
                        <div className="multi_entity_div">
                          {entityData?.entity.map((val) => {
                            return (
                              <div
                                key={val.entity_id}
                                onClick={() => dispatch(setEntity(val))}
                                style={{
                                  background:
                                    entity?.[0].entity_id === val.entity_id
                                      ? "#506994 0% 0% no-repeat padding-box"
                                      : "#ffffff 0% 0% no-repeat padding-box",
                                }}
                                className="multi_entity_card"
                              >
                                <img
                                  className="multi_entity_company_img"
                                  src={company}
                                  alt="Company"
                                />
                                <div className="flex flex-col">
                                  <p
                                    style={{
                                      color:
                                        entity?.[0].entity_id === val.entity_id
                                          ? "#f9f9f9"
                                          : "#000",
                                    }}
                                    className="multi_entity_company_name"
                                  >
                                    {val.entity_name}
                                  </p>
                                  <p
                                    style={{
                                      color:
                                        entity?.[0].entity_id === val.entity_id
                                          ? "#dadada"
                                          : "#716f70",
                                    }}
                                    className="multi_entity_name"
                                  >
                                    {val.entity_id}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>

                        <div
                          className={`text-center pt-2 mb-2 ${
                            !isCredentialsFilled ? "pointer-events-none" : ""
                          }`}
                        >
                          <button
                            id="button"
                            className={`mb-0 px-5 w-full btncolor ${
                              error || !isCredentialsFilled ? "" : "filled"
                            }`}
                            disabled={error || !isCredentialsFilled}
                            style={{
                              marginTop: `${error ? "120px" : "90px"}`,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              pointerEvents:
                                error || !isCredentialsFilled ? "none" : "auto",
                            }}
                            onClick={(e) => {
                              handleLogin(e);
                            }}
                          >
                            {isLoggingIn ? (
                              <img
                                src={LoaderIcon}
                                alt="Loading"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                }}
                              />
                            ) : (
                              "Login"
                            )}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="log_top_div">
          <LoginTotp
            isFocus={isFocus}
            setIsFocus={setIsFocus}
            isLoading={isLoading}
            data={entityData}
            isError={isError}
            handleLogin={handleLogin}
            handleKeyPress={handleKeyPress}
            cred={cred}
            setCred={setCred}
            isBackupCodes={isBackupCodes}
            setIsBackupCodes={setIsBackupCodes}
          />
          {/* <div className="hidden sm:flex justify-between items-center w-full max-w-5xl fixed bottom-0 text-Primary_white">
            <div className="text-[10px] mb-3 my-auto text-left">
              © Copyright {new Date().getFullYear()} Q2Pay Technologies Pvt Ltd.
              All rights reserved |
              <a
                href="/aboutus"
                onClick={(e) => handleNavigation(e, "/aboutus")}
              >
                About Us
              </a>
              |
              <a
                href="/privacy_policy"
                onClick={(e) => handleNavigation(e, "/privacy_policy")}
              >
                Privacy
              </a>
              |
              <a
                href="/terms_conditions"
                onClick={(e) => handleNavigation(e, "/terms_conditions")}
              >
                Terms
              </a>
            </div>

            <div className="text-[10px] items-center mb-3 my-auto">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.entrust.net/customer/profile?domain=q2pay.in&lang=en"
                className="block"
              >
                <img
                  src={trustIcon}
                  alt="trust_icon"
                  className="img-fluid"
                  style={{ width: "32px" }}
                />
              </a>
            </div>
          </div> */}
        </div>
      )}
    </>
  );
};

export default LoginPage;
