import React, { useRef, useEffect } from "react";
import "./typedropdown.css";
import { Button } from "@mui/material";
import ArrowDown from "../../../assets/OwnerAccount/arrowdown5.png";
import Close from "../../../assets/CloseIcons/close.svg";
import CheckIcon from "../../../assets/Beneficiary Icons/whitecheckmark.png";

const TypeDropdown = ({
  statementTypeState,
  setStatementTypeState,
  typeOpen,
  setTypeOpen,
  owner,
}) => {
  const accountBalanceRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        accountBalanceRef.current &&
        !accountBalanceRef.current.contains(event.target)
      ) {
        setTypeOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div className="owner_connected_bank_div">
      <p style={{ height: "22px", marginBottom: "0" }}>Type</p>
      <Button
        ref={accountBalanceRef}
        className="owner_connected_bank"
        onClick={() => {
          setTypeOpen(!typeOpen);
        }}
        sx={{
          cursor: "pointer",
          opacity: "1",
          height: typeOpen ? "111px" : "37px",
          outline: typeOpen ? "1px solid #E25869" : "",
        }}
      >
        <div className="owner_connected_bank_select">
          <div
            className="OAS_owner_connected_bank_scroll_line"
            style={{ display: typeOpen ? "block" : "none" }}
          ></div>

          <span
            style={{
              fontSize: "12px",
              paddingLeft: "10px",
              color: statementTypeState.length > 0 ? "#f9f9f9" : "#dadada",
            }}
          >
            {statementTypeState.length > 0 ? statementTypeState : "Select type"}
          </span>
          <div className="w-[44px] flex justify-end gap-x-[10px]">
            <div
              onClick={(e) => {
                e.stopPropagation();

                setStatementTypeState("");
                setTypeOpen(false);
              }}
              className="w-[23px] flex justify-between border-r-[1px] border-Blue_2 border-solid"
              style={{
                display: statementTypeState.length > 0 ? "flex" : "none",
              }}
            >
              <img className="h-[12px] w-[12px]" src={Close} alt="" />
            </div>
            <img
              src={ArrowDown}
              alt="down"
              style={{
                height: "12px",
                transform: typeOpen ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </div>
        </div>
        <div
          style={{ zIndex: "0 !important" }}
          className="owner_connected_bank_scroll"
        >
          <Button
            className="owner_connected_bank_scroll_com hover:bg-Blue_7"
            sx={{
              backgroundColor: statementTypeState === "Credit" ? "#3A5686" : "",
            }}
            onClick={(e) => {
              e.stopPropagation();

              setStatementTypeState("Credit");
              setTypeOpen(false);
            }}
          >
            Credit
            <div
              style={{
                display: statementTypeState == "Credit" ? "flex" : "none",
                textAlign: "right",
                justifyContent: "end",
                paddingRight: "1px",
              }}
            >
              <img
                style={{
                  height: "13px",
                  width: "11px",
                }}
                src={CheckIcon}
                alt="check"
              />
            </div>
          </Button>
          <Button
            className="owner_connected_bank_scroll_com hover:bg-Blue_7"
            sx={{
              backgroundColor: statementTypeState === "Debit" ? "#3A5686" : "",
            }}
            onClick={(e) => {
              e.stopPropagation();

              setStatementTypeState("Debit");
              setTypeOpen(false);
            }}
          >
            Debit
            <div
              style={{
                display: statementTypeState == "Debit" ? "flex" : "none",
                textAlign: "right",
                justifyContent: "end",
                paddingRight: "1px",
              }}
            >
              <img
                style={{
                  height: "13px",
                  width: "11px",
                }}
                src={CheckIcon}
                alt="check"
              />
            </div>
          </Button>
        </div>
      </Button>
    </div>
  );
};

export default TypeDropdown;
