import React from "react";
import "./index.css";
import Copy from "../../../assets/GeneralIcons/copy_clipboard.svg";
import Greencheck from "../../../assets/CheckmarksIcons/selected_with_green_circle.svg";
import Whitecheck from "../../../assets/CheckmarksIcons/not_selected_with_grey_circle.svg";
import QrScanned from "../../../assets/GeneralIcons/scanned_sucess.svg";
import { QRCodeCanvas } from "qrcode.react";
import { tooltipClasses, Tooltip } from "@mui/material";

const TotpScanner = ({
  hashKey,
  isQrScanned,
  setIsQrScanned,
  open,
  setOpen,
  tooltipText,
  setTooltipText,
}) => {
  const handleCopy = () => {
    navigator.clipboard
      .writeText(hashKey?.key)
      .then(() => {
        setTooltipText("Copied!");
        setOpen(true);

        setTimeout(() => {
          setOpen(false);
          setTooltipText("Click to copy");
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <div className="totp_Top_Main">
      <div className="divide_totp">
        <div className="totp_scanner_left">
          <h4 className="totp_scanner_left_1">
            Scan QR code or enter the passkey
          </h4>
          <p className="totp_scanner_left_2">
            Use the authenticator app to scan the QR code shown below, or
            manually enter the passkey into the app.
          </p>
          <p className="totp_scanner_left_3">
            Note: If you want to register an authenticator across several
            devices, scan or enter the passkey in each app simultaneously.
          </p>
          <div className="totp_scanner_left_4">
            <div className="totp_scanner_left_4_qrcode">
              <h4>Scan QR code</h4>
              <div className="totp_scanner_left_4_qrcode_scanner_main">
                <div className="totp_scanner_left_4_qrcode_scanner">
                  <QRCodeCanvas
                    value={`otpauth://totp/YourApp:${hashKey?.email}?secret=${hashKey?.key}&issuer=YourAp`}
                    size={90}
                    ty
                  />
                </div>
              </div>
            </div>
            <h4>OR</h4>
            <div className="totp_scanner_left_4_copycode">
              <Tooltip
                title={tooltipText}
                open={open}
                onClose={() => setOpen(false)}
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black,
                      },
                    },
                  },
                }}
              >
                <button
                  className="totp_scanner_left_4_copycode_butt"
                  onClick={handleCopy}
                  onMouseEnter={() => setOpen(true)} // Show tooltip on hover
                  onMouseLeave={() =>
                    !tooltipText.includes("Copied") && setOpen(false)
                  }
                >
                  <img
                    src={Copy}
                    alt="Copy"
                    className="totp_scanner_left_4_copycode_img"
                  />
                </button>
              </Tooltip>

              <h4>Copy Passkey</h4>
              <p>{hashKey?.key}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="divide_totp">
        <div className="totp_download_right">
          <img src={QrScanned} alt="AppDownloaded" />
          <div
            className="totp_download_right_button"
            onClick={() => {
              setIsQrScanned(!isQrScanned);
            }}
          >
            <img src={isQrScanned === true ? Greencheck : Whitecheck} />
            <div className="totp_download_right_button_info">
              <h4>Confirm Action</h4>
              <p>I have scanned QR code / entered the passkey.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotpScanner;
