import React from "react";
import { toast } from "react-toastify";
import LoaderIcon from "../../../assets/LoaderIcons/Loader.gif";

const width = window.innerWidth;

const paragraphStyle = {
  width: "160px",
  height: "19px",
  textAlign: "left",
  font: "normal normal normal 14px/19px Open Sans",
  letterSpacing: "0px",
  color: "var(--Primary_white)",
  opacity: 1,
};
const divStyle = {
  display: "flex",
  justifyContent: width < 480 ? "left" : "center",
  alignItems: "center",
};
const imageStyle = {
  width: "50px",
  height: "40px",
  opacity: 1,
  paddingRight: "10px",
};
const LoadingToastEnable = (type) => {
  return toast(
    <div style={divStyle}>
      <img src={LoaderIcon} alt="LoaderIcon" style={imageStyle} />
      <p style={paragraphStyle}>
        {type === "enable" ? "Verifying authentication code" : "Disabling TOTP"}
      </p>
    </div>,
    {
      position: "bottom-right",
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      fontSize: "20px",
      style: {
        width: width < 480 ? `${width}px` : "310px",
        height: "50px",
        borderRadius: width < 480 ? "0px" : "10px",
        backgroundColor: "var(--Toast_processing)",
        color: "var(--Primary_white)",
        zIndex: "9999999",
      },
    }
  );
};

export default LoadingToastEnable;
