import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputAdornment, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import {
  loginNewToken,
  resetLoginError,
} from "../../../redux/features/login/login";
import Loader from "../../../assets/LoaderIcons/Loader.gif";
import PasswordNotMatched from "../../../assets/loginImage/passwordnotmatched.svg";
import SessionTimeout from "../../../assets/Graphics/session_time_out.svg";
import SkippingVerification from "../../../assets/CreateTransactionIcons/Skipping verivifaction.png";
import TrustIcon from "../../../assets/trust_icon.png";
import WhiteEyeOff from "../../../assets/PasswordIcons/password_hidden_darkgrey.svg";
import WhiteEyeOn from "../../../assets/PasswordIcons/password_visible_darkgrey.svg";
import XpentraLogo from "../../../assets/TransactionDashboardIcons/Xpentra.png";
import LoginTotp from "../../LoginTotp/LoginTotp";
import {
  removeInitialError,
  disabledEnabledError,
} from "../../../redux/features/totp/totp";

const Relogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reLoginInputRef = useRef(null);
  const [isReLoggingIn, setIsReLoggingIn] = useState(false);
  const [isReLoginError, setIsReLoginError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isCredentialsFilled, setIsCredentialsFilled] = useState(false);
  const [cred, setCred] = useState({
    user_Id: "",
    password: "",
    totp: "",
    type: "",
  });
  const entityData = useSelector((state) => state.login.data);

  useEffect(() => {
    const storedUserId = sessionStorage.getItem("user_id") || "";
    setCred((prevCred) => ({ ...prevCred, user_Id: storedUserId }));
  }, []);

  const { isLoading, loginErrorMessage, isError } = useSelector(
    (state) => state.login
  );

  const [flag, setFlag] = useState(true);
  const [isFocus, setIsFocus] = useState(false);
  const [isBackupCodes, setIsBackupCodes] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      if (loginErrorMessage === "Invalid TOTP code") {
        setFlag(false);
      }
    }
  }, [loginErrorMessage]);

  useLayoutEffect(() => {
    dispatch(resetLoginError());
  }, [flag]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.trim();

    setCred((prevCred) => {
      return {
        ...prevCred,
        [name]: sanitizedValue,
      };
    });
    setIsReLoginError(false);
  };

  useEffect(() => {
    setIsCredentialsFilled(
      cred.user_Id.trim() !== "" && cred.password.trim() !== ""
    );
  }, [cred]);

  const handleshowhide = () => {
    setShowPassword(!showPassword);
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter" && isCredentialsFilled) {
      handleReLogin();
    }
  };

  const handleReLogin = async () => {
    if (isCredentialsFilled) {
      setIsReLoggingIn(true);
      try {
        const response = await dispatch(loginNewToken(cred));
        dispatch(removeInitialError());
        dispatch(disabledEnabledError());
        const { status } = response.payload;

        if (status === true) {
          navigate("/home", { replace: true });
        } else {
          setIsReLoginError("Invalid username or password.");
        }
      } catch (error) {
        console.error("Login error:", error);
      } finally {
        setIsReLoggingIn(false);
      }
    }
  };

  const handleNavigation = (e, path) => {
    if (isReLoggingIn) {
      e.preventDefault();
    } else {
      navigate(path);
    }
  };

  return (
    <>
      {flag === true ? (
        <>
          <div className="flex md:items-center justify-center h-screen">
            <div
              className={`flex flex-col md:flex-row items-center bg-Blue_5 rounded-none md:rounded-[30px] max-w-[1024px] lg:w-[1024px] w-full mx-0 lg:mx-5 md:mx-5 border border-custom-red`}
            >
              {isReLoginError ? (
                <div className="flex flex-col justify-center text-center text-Primary_white lg:w-[552px] md:w-1/2 px-2 md:p-6 md:h-[511px] lg:h-[460px] h-[280px]">
                  <img
                    src={PasswordNotMatched}
                    alt="bg_image"
                    className="lg:w-[290px] lg:h-[210px] md:w-[240px] md:h-[190px] w-[220px] h-[210px] mx-auto mt-12 lg:mt-0 md:mt-0"
                  />

                  <div className="hidden md:block lg:mt-2.5 md:mt-3 mt-10">
                    <h5 className="text-center text-xl font-semibold">
                      Unable to login!
                    </h5>
                    <p className="text-left text-sm leading-[2.5]">
                      You are unable to log in due to one of the following
                      reasons:
                    </p>
                    <ul
                      className="text-sm"
                      style={{
                        textAlign: "left",
                        listStyleType: "disc",
                        paddingLeft: "20px",
                        lineHeight: "2",
                      }}
                    >
                      <li>Incorrect User ID</li>
                      <li>Incorrect Password</li>
                      <li>Both User ID and Password are incorrect</li>
                      <li>Unauthorized User ID</li>
                      <li>Account is blocked</li>
                    </ul>
                    <p className="text-left text-sm mt-2 mb-6 break-words whitespace-normal max-w-[90%] md:max-w-full">
                      Please enter the correct User ID or Password, or contact
                      the administrator to verify your login credentials.
                    </p>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center text-center text-Primary_white lg:w-[552px] md:w-1/2 px-2 md:p-6 md:h-[511px] lg:h-[460px] h-[280px] pb-4 md:pb-1.5">
                  <img
                    src={SessionTimeout}
                    alt="Session Timeout Illustration"
                    className="lg:w-[330px] lg:h-[198px] md:w-[280px] md:h-[168px] w-[183px] h-[130px] py-4"
                  />

                  <div className="pb-[30px]">
                    <h2 className="text-xl font-semibold mb-2">
                      Session timed out!
                    </h2>
                    <p className="text-sm">
                      Your session has been logged out due to inactivity.
                      <br /> Please log in again.
                    </p>
                  </div>
                </div>
              )}

              <div
                className={`bg-Primary_white rounded-t-[20px] md:rounded-[30px] px-4 lg:px-10 lg:py-[30px] md:py-[30px] py-[30px] lg:w-[472px] md:w-1/2 h-[760px] md:h-[530px] w-full flex flex-col md:justify-center`}
              >
                <img
                  src={XpentraLogo}
                  alt="Xpentra Logo"
                  className={`lg:w-[170px] lg:h-[40px] md:w-[120px] md:h-[30px] w-[140px] h-[32px] mx-auto mb-[30px]`}
                />
                <div className="">
                  <TextField
                    id="outlined-basic"
                    label="User Id/ Alias"
                    autoComplete="off"
                    className="w-full"
                    variant="outlined"
                    name="user_Id"
                    size="small"
                    sx={{
                      "& .MuiInputLabel-root": {
                        fontFamily:
                          '-apple-system, "Open Sans", sans-serif !important',
                        color: isReLoginError
                          ? "var(--Failed)"
                          : "var(--Secondary_grey)",
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: isReLoginError
                          ? "var(--Failed)"
                          : "var(--Pending)",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: isReLoginError
                            ? "var(--Failed)"
                            : "var(--Secondary_grey)",
                          borderRadius: "10px",
                        },
                        "& legend": {
                          paddingRight:
                            cred?.user_Id?.length !== 0 ? "8px" : "",
                        },
                        "&:hover fieldset": {
                          borderColor: isReLoginError
                            ? "var(--Failed)"
                            : "var(--Pending)",
                        },
                        "&.Mui-focused": {
                          fieldset: {
                            borderColor: isReLoginError
                              ? "var(--Failed)"
                              : "var(--Pending)",
                          },
                          legend: {
                            paddingRight: "8px",
                          },
                        },
                      },
                      "&:hover .MuiInputLabel-root": {
                        color: isReLoginError
                          ? "var(--Failed)"
                          : "var(--Pending)",
                      },
                    }}
                    inputProps={{
                      style: {
                        color: "var(--Secondary_grey)",
                        fontFamily: '-apple-system, "Open Sans", sans-serif',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <div className="flex items-center justify-center w-5 h-5">
                            {isReLoginError && cred.user_Id.length !== 0 && (
                              <img
                                src={SkippingVerification}
                                alt={"Error Login"}
                                className="w-5 h-5"
                              />
                            )}
                          </div>
                        </InputAdornment>
                      ),
                    }}
                    required
                    value={cred.user_Id}
                    onChange={handleChange}
                    onKeyDown={handleEnterKeyPress}
                    inputRef={reLoginInputRef}
                  />
                </div>
                <div className="mt-5">
                  <TextField
                    id="outlined-password-input"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="current-password"
                    className="w-full"
                    variant="outlined"
                    name="password"
                    size="small"
                    sx={{
                      "& .MuiInputLabel-root": {
                        fontFamily:
                          '-apple-system, "Open Sans", sans-serif !important',
                        color: isReLoginError
                          ? "var(--Failed)"
                          : "var(--Secondary_grey)",
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: isReLoginError
                          ? "var(--Failed)"
                          : "var(--Pending)",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: isReLoginError
                            ? "var(--Failed)"
                            : "var(--Secondary_grey)",
                          borderRadius: "10px",
                          outline: "none !important",
                        },
                        "& legend": {
                          paddingRight:
                            cred?.password?.length !== 0 ? "8px" : "",
                        },
                        "&:hover fieldset": {
                          borderColor: isReLoginError
                            ? "var(--Failed)"
                            : "var(--Pending)",
                        },
                        "&.Mui-focused": {
                          fieldset: {
                            borderColor: isReLoginError
                              ? "var(--Failed)"
                              : "var(--Pending)",
                          },
                          legend: {
                            paddingRight: "8px",
                          },
                        },
                      },
                      "&:hover .MuiInputLabel-root": {
                        color: isReLoginError
                          ? "var(--Failed)"
                          : "var(--Pending)",
                      },
                      "& .MuiOutlinedInput-input::placeholder": {
                        color: "var(--Secondary_grey)",
                        opacity: 1,
                        transition: "color 0.3s ease",
                      },
                      "&:hover .MuiOutlinedInput-input::placeholder": {
                        color: isReLoginError
                          ? "var(--Failed)"
                          : "var(--Pending)",
                      },
                    }}
                    inputProps={{
                      style: {
                        color: "var(--Secondary_grey)",
                        fontSize: "14px",
                        fontFamily: '-apple-system, "Open Sans", sans-serif',
                      },
                    }}
                    InputProps={{
                      sx: {
                        borderRadius: "20px !important",
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <div className="flex items-center justify-center w-5 h-5">
                            {isReLoginError && cred.password.length !== 0 ? (
                              <img
                                src={SkippingVerification}
                                alt="Error Login"
                                className="w-5 h-5"
                              />
                            ) : (
                              <img
                                src={showPassword ? WhiteEyeOn : WhiteEyeOff}
                                alt={
                                  showPassword
                                    ? "Hide password"
                                    : "Show password"
                                }
                                className="w-5 h-5 cursor-pointer"
                                onClick={handleshowhide}
                              />
                            )}
                          </div>
                        </InputAdornment>
                      ),
                    }}
                    required
                    value={cred.password}
                    onChange={handleChange}
                    onKeyDown={handleEnterKeyPress}
                    inputRef={reLoginInputRef}
                  />
                </div>

                <div className="flex justify-between my-5 forgotpass">
                  <span
                    onClick={() => navigate("/contactAdmin")}
                    className="cursor-pointer"
                  >
                    Forgot your password?
                  </span>
                </div>

                {isReLoginError && (
                  <div className="flex flex-col justify-center text-center text-Primary_black px-2 h-[280px] sm:block md:hidden my-[40px]">
                    <h5 className="text-center text-xl font-semibold text-Failed">
                      Unable to login!
                    </h5>
                    <p className="text-left text-sm leading-[2.5]">
                      You are unable to log in due to one of the following
                      reasons:
                    </p>
                    <ul
                      className="text-sm"
                      style={{
                        textAlign: "left",
                        listStyleType: "disc",
                        paddingLeft: "30px",
                        lineHeight: "2",
                      }}
                    >
                      <li>Incorrect User ID</li>
                      <li>Incorrect Password</li>
                      <li>Both User ID and Password are incorrect</li>
                      <li>Unauthorized User ID</li>
                      <li>Account is blocked</li>
                    </ul>
                    <p className="text-left text-sm break-words whitespace-normal max-w-[90%] sm:max-w-full py-5">
                      Please enter the correct User ID or Password, or contact
                      the administrator to verify your login credentials.
                    </p>
                  </div>
                )}

                <button
                  className={`w-full h-[50px] lg:h-[38px] md:h-[38px] py-3 lg:rounded-[75px] md:rounded-[75px] rounded-none z-50 text-Primary_white flex items-center justify-center ${
                    cred.password.length !== 0 &&
                    cred.user_Id.length !== 0 &&
                    !isReLoginError
                      ? "bg-Button_green"
                      : "bg-Secondary_grey cursor-not-allowed"
                  } md:relative md:mt-48 px-6 fixed bottom-0 left-0 md:px-0`}
                  onClick={handleReLogin}
                  disabled={
                    cred.password.length === 0 ||
                    cred.user_Id.length === 0 ||
                    isReLoginError
                  }
                >
                  {isReLoggingIn ? (
                    <img src={Loader} alt="Loading" className="w-6 h-6" />
                  ) : (
                    "Login"
                  )}
                </button>
              </div>
            </div>
            {/* <div className="hidden sm:flex justify-between items-center w-full max-w-5xl fixed bottom-0 text-Primary_white">
              <div className="text-[10px] mb-3 my-auto text-left">
                © Copyright {new Date().getFullYear()} Q2Pay Technologies Pvt
                Ltd. All rights reserved |
                <a
                  href="/aboutus"
                  onClick={(e) => handleNavigation(e, "/aboutus")}
                >
                  About Us
                </a>
                |
                <a
                  href="/privacy_policy"
                  onClick={(e) => handleNavigation(e, "/privacy_policy")}
                >
                  Privacy
                </a>
                |
                <a
                  href="/terms_conditions"
                  onClick={(e) => handleNavigation(e, "/terms_conditions")}
                >
                  Terms
                </a>
              </div>

              <div className="text-[10px] items-center mb-3 my-auto">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.entrust.net/customer/profile?domain=q2pay.in&lang=en"
                  className="block"
                >
                  <img
                    src={TrustIcon}
                    alt="trust_icon"
                    className="img-fluid"
                    style={{ width: "32px" }}
                  />
                </a>
              </div>
            </div> */}
          </div>
        </>
      ) : (
        <div className="log_top_div">
          <LoginTotp
            isFocus={isFocus}
            setIsFocus={setIsFocus}
            isLoading={isLoading}
            data={entityData}
            isError={isError}
            handleLogin={handleReLogin}
            handleKeyPress={handleEnterKeyPress}
            cred={cred}
            setCred={setCred}
            isBackupCodes={isBackupCodes}
            setIsBackupCodes={setIsBackupCodes}
          />
          {/* <div className="flex-container text-center text-md-start justify-between w-[1024px] max-w-[1024px] fixed bottom-0">
            <div className="text-Primary_white text-xs mb-3 items-center my-auto">
              © Copyright {new Date().getFullYear()} Q2Pay Technologies Pvt Ltd.
              All rights reserved |
              <Link className="text-Primary_white" to="/aboutus">
                About Us
              </Link>
              |
              <Link className="text-Primary_white" to="/privacy_policy">
                Privacy
              </Link>
              |
              <Link className="text-Primary_white" to="/terms_conditions">
                Terms
              </Link>
            </div>

            <div className="text-Primary_white text-xs items-center mb-3 my-auto">
              <div
                tag="a"
                color="none"
                className="mx-2"
                style={{ color: "var(--Primary_white)" }}
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.entrust.net/customer/profile?domain=q2pay.in&lang=en"
                >
                  <img
                    src={TrustIcon}
                    alt="trust_icon"
                    className="img-fluid"
                    style={{
                      margin: "auto",
                      display: "block",
                      width: "32px",
                    }}
                  />
                </a>
              </div>
            </div>
          </div> */}
        </div>
      )}
    </>
  );
};

export default Relogin;
