import React, { useEffect, useState } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAllRoleFeatures,
  checkUsertoken,
} from "../../redux/features/login/login";
import Disc from "../../assets/Beneficiary Icons/discEdit.png";
import Loader from "../../components/Loader/Index";
import NotAssigned from "../../assets/HomeDashboard/feature_not_assigned.svg";

const FeatureNotAssigned = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [features, setFeatures] = useState([]);
  const { unavailableService } = location.state || {};
  const [adminLoading, setAdminLoading] = useState(false);
  const { roleFeatures } = useSelector((state) => state.login);
  const refreshData = useSelector((state) => state.refreshToken.data);

  useEffect(() => {
    if (Array.isArray(unavailableService)) {
      setFeatures(unavailableService);
    } else if (typeof unavailableService === "string") {
      setFeatures(unavailableService.split(",").map((item) => item.trim()));
    }
  }, [unavailableService]);

  useEffect(() => {
    setAdminLoading(true);
    dispatch(checkUsertoken());
    dispatch(getAllRoleFeatures()).then(() => setAdminLoading(false));
  }, [dispatch]);

  return (
    <>
      <div id="webviewd">
        <div className="flex w-full h-screen">
          <div className="max-w-[1024px] w-full mx-auto flex">
            <div className="w-2/5 flex flex-col justify-center items-center text-center">
              <div className="mt-20">
                <img src={NotAssigned} alt="" className="h-56 mb-4" />
              </div>
              <h3 className="text-xl text-Primary_white font-semibold mt-8">
                Feature not assigned
              </h3>
              <p className="text-Primary_white text-sm mt-3">
                The services required for this feature <br /> are not assigned
                to your user.
              </p>
              <div className="mt-8" onClick={() => navigate("/home")}>
                <button className="text-Primary_black bg-Primary_white w-auto rounded-[20px] px-10 py-2 text-lg font-semibold">
                  Home
                </button>
              </div>
            </div>

            <div className="w-3/5 flex justify-center items-center mt-28">
              <div className="bg-custom-blue w-full h-auto py-[2rem] px-[2.25rem] rounded-[20px]">
                <div>
                  <h3 className="mb-4 text-xl text-Primary_white font-semibold">
                    To resolve this issue -
                  </h3>
                  <p className="mb-4 text-sm text-Primary_grey font-normal">
                    Contact your admin and request them to assign the feature
                    required to your User ID:
                    <i className="font-bold text-Primary_white">
                      &nbsp;{refreshData?.employees?.user_id}
                    </i>
                  </p>
                  <span className="text-xs text-Primary_grey">
                    Feature must include the following services -
                  </span>
                  <div className="mt-2 w-full mb-4">
                    <ul
                      style={{
                        listStylePosition: "inside",
                        color: "var(--Priamry_grey)",
                        fontSize: "12px",
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        gap: "8px",
                      }}
                    >
                      {features.map((item, index) => (
                        <li
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "8px",
                            fontSize: "16px",
                          }}
                        >
                          <img
                            src={Disc}
                            alt="editCategoryMode"
                            className="w-2 h-2"
                          />
                          <span
                            className="text-Primary_white"
                            style={{ marginLeft: "8px", fontStyle: "italic" }}
                          >
                            {item}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <hr className="text-Priamry_grey" />

                <div className="mt-6">
                  <p className="text-xs text-Primary_grey">
                    Users with admin rights -
                  </p>
                  <div className="mt-3 w-full">
                    {adminLoading ? (
                      <div
                        style={{
                          height: "25px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ transform: "scale(0.6)" }}>
                          <Loader />
                        </div>
                      </div>
                    ) : roleFeatures?.employees?.length ? (
                      <ul
                        style={{
                          listStylePosition: "inside",
                          color: "var(--Priamry_grey)",
                          fontSize: "12px",
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                          gap: "8px",
                        }}
                      >
                        {roleFeatures.employees.map((employee, index) => (
                          <li
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "8px",
                              fontSize: "16px",
                            }}
                          >
                            <img
                              src={Disc}
                              alt="editCategoryMode"
                              className="w-2 h-2"
                            />
                            <span
                              className="text-Primary_white"
                              style={{ marginLeft: "8px", fontStyle: "italic" }}
                            >
                              {employee.first_name} {employee.last_name}
                            </span>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="text-Primary_grey mt-2 text-xs">
                        No data found
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="mobileviewd">
        <div className="flex w-full">
          <div className="w-[100%] mx-auto">
            <div className="w-[100%] justify-center items-center text-center">
              <div className="mt-10">
                <img
                  src={NotAssigned}
                  alt="NotAssigned"
                  className="h-56 mb-4"
                  style={{ width: "80%", margin: "0px auto" }}
                />
              </div>
              <h3 className="text-xl text-Primary_white font-semibold mt-8">
                Feature not assigned
              </h3>
              <p className="text-Primary_white text-sm mt-3">
                The services required for this feature <br /> are not assigned
                to your user.
              </p>
            </div>

            <div className="w-[100%] justify-center items-center mt-8">
              <div className="bg-custom-blue w-full min-h-[50vh] h-auto py-[2rem] px-[2.25rem] rounded-tl-[20px] rounded-tr-[20px] remove-buttom-border-mobile-view">
                <div>
                  <h3 className="mb-4 text-xl text-Primary_white font-semibold">
                    To resolve this issue -
                  </h3>
                  <p className="mb-4 text-sm text-Primary_grey font-normal">
                    Contact your admin and request them to assign the feature
                    required to your User ID:
                    <i className="font-bold text-Primary_white">
                      &nbsp;{refreshData?.employees?.user_id}
                    </i>
                  </p>
                  <span className="text-xs text-Primary_grey">
                    Feature must include the following services -
                  </span>
                  <div className="mt-2 w-full">
                    <ul className="flex flex-col">
                      {features.map((item, index) => (
                        <li
                          key={index}
                          className="flex items-center ml-2 text-base pb-2.5"
                        >
                          <img
                            src={Disc}
                            alt="editCategoryMode"
                            className="w-2 h-2"
                          />
                          <span className="ml-2 italic text-Primary_white">
                            {item}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <hr className="text-Priamry_grey" />

                <div className="mt-6">
                  <p className="text-xs text-Primary_grey">
                    Users with admin rights -
                  </p>
                  <div className="mt-3 w-full">
                    {adminLoading ? (
                      <div
                        style={{
                          height: "25px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ transform: "scale(0.6)" }}>
                          <Loader />
                        </div>
                      </div>
                    ) : roleFeatures?.employees?.length ? (
                      <ul>
                        {roleFeatures.employees.map((employee, index) => (
                          <li
                            key={index}
                            className="flex items-center ml-2 text-base"
                          >
                            <img
                              src={Disc}
                              alt="editCategoryMode"
                              className="w-2 h-2"
                            />
                            <span className="ml-2 italic text-Primary_white">
                              {employee.first_name} {employee.last_name}
                            </span>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="text-Primary_grey mt-2 text-xs">
                        No data found
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="w-full mt-8 justify-center items-center"
              onClick={() => navigate("/home")}
            >
              <button className="text-Primary_black bg-Primary_white w-full px-10 py-2 fixed bottom-0">
                Home
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeatureNotAssigned;
